import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut, Post, Get } from '../../common_var/httpService';

function MilestoneConfiguration() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {

        await Get(`milestone/getpercentage`, "admin")
            .then((res) => {
                console.log(res?.data);


                setData(res?.data);

            })
            .catch((err) => { });
    };

    const formic = useFormik({

        initialValues: {

            milestone1fundper: data[0]?.value ? data[0]?.value : 0, //here we have to prepoulate it
            milestone2fundper: data[1]?.value ? data[1]?.value : 0, //here we have to prepoulate it
            milestone3fundper: data[2]?.value ? data[2]?.value : 0, //here we have to prepoulate it
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            milestone1fundper: Yup.number().required("Milestone 1 Fund % is requried"),
            milestone2fundper: Yup.number().required("Milestone 2 Fund % is requried"),
            milestone3fundper: Yup.number().required("Milestone 3 Fund % is requried"),
        }),
        onSubmit: async (values) => {
            let totalMilestonePercentage =
                (values?.milestone1fundper) +
                (values?.milestone2fundper) +
                (values?.milestone3fundper);

            const bodyData = { "Milestonedata": [{ key: "milestone1fundper", value: values?.milestone1fundper }, { key: "milestone2fundper", value: values?.milestone2fundper }, { key: "milestone3fundper", value: values?.milestone3fundper }] }
            console.log("BodYdata", bodyData)
            if (totalMilestonePercentage == 100) {

                if (data[0]?.value != values?.milestone1fundper || data[1]?.value != values?.milestone2fundper || data[2]?.value != values?.milestone3fundper) {
                    await Post("milestone/savePercentage", bodyData, "admin").then((res) => {
                        console.log("res---->", res);
                        if (res.statusCode == 200) {
                            setData(res?.data);
                            toast.success(res?.message)

                        } else {
                            toast.error('Error')
                        }
                    });
                }


                console.log(bodyData);

            }
            else {
                toast.error('Milestone Percentage should be equal to 100%')
            }
        },
    });



    return (
        <>
            <div className="mb_24">
                <h5 className="heading16 mb_4 fontWeight600 textTransform">
                    MILESTONE FUND(%)
                </h5>

            </div>
            <form onSubmit={formic.handleSubmit}>
                <div className="whiteFrame">
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Milestone</th>
                                    <th>Value(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Milestone 1 Fund %</td>
                                    <td>
                                        <input
                                            type='number'
                                            name="milestone1fundper"
                                            placeholder="%"
                                            className="adminInput"
                                            onChange={formic.handleChange}
                                            onBlur={formic.handleBlur}
                                            value={formic.values.milestone1fundper}
                                        ></input>
                                        {formic.touched.milestone1fundper && formic.errors.milestone1fundper ? (
                                            <div className="error_text text-danger">
                                                {formic.errors.milestone1fundper}
                                            </div>
                                        ) : null}
                                    </td>

                                </tr>
                                <tr>
                                    <td>Milestone 2 Fund %</td>
                                    <td>
                                        <input
                                            type='number'
                                            name="milestone2fundper"
                                            placeholder="%"
                                            className="adminInput"
                                            onChange={formic.handleChange}
                                            onBlur={formic.handleBlur}
                                            value={formic.values.milestone2fundper}
                                        ></input>
                                        {formic.touched.milestone2fundper && formic.errors.milestone2fundper ? (
                                            <div className="error_text text-danger">
                                                {formic.errors.milestone2fundper}
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Milestone 3 Fund %</td>
                                    <td>
                                        <input
                                            type='number'
                                            name="milestone3fundper"
                                            placeholder="%"
                                            className="adminInput"
                                            onChange={formic.handleChange}
                                            onBlur={formic.handleBlur}
                                            value={formic.values.milestone3fundper}
                                        ></input>
                                        {formic.touched.milestone3fundper && formic.errors.milestone3fundper ? (
                                            <div className="error_text text-danger">
                                                {formic.errors.milestone3fundper}
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="p16 alignItemscenter displayFlex justifyConentEnd">
                        <button type="submit" className="mainPrimary_btn greenishTeal_Clr">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default MilestoneConfiguration