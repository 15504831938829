import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

const InstallerCusModal = ({ btnName, view, modalState, preventClosing }) => {
    const handleClose = () => modalState.setShow(false);
    const handleShow = () => modalState.setShow(true);

    return (
        <>
            {btnName ? <button className="btn btn-primary" onClick={handleShow}>
                {btnName}
            </button> : null}
            <Modal backdrop={preventClosing ? 'static' : undefined} show={modalState.show} onHide={handleClose}>
                {view}
            </Modal>
        </>
    );
}

export default InstallerCusModal