import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNumInput = ({
  className,
  defaultCountry,
  international,
  type,
  formControlName,
  id,
  placeholder,
  onChange,
  name,
  onBlur,
  value,
  mask,
  key,
  autoComplete,
  containerComponent,
  countries,
  countryCallingCodeEditable,
  addInternationalOption = false,
  countryOptionsOrder,
  countrySelectComponent,
  countrySelectProps,
  disabled = false,
  flagComponent,
  flagUrl,
  flags,
  focusInputOnCountrySelection = false,
  initialValueFormat,
  inputComponent,
  internationalIcon,
  limitMaxLength = false,
  labels,
  numberInputProps,
  locales,
  onCountryChange,
  onFocus,
  readOnly = false,
  ref,
  smartCaret = false,
  style,
}) => {
  return (
    <PhoneInput
      onFocus={onFocus}
      readOnly={readOnly}
      ref={ref}
      smartCaret={smartCaret}
      style={style}
      locales={locales}
      numberInputProps={numberInputProps}
      onCountryChange={onCountryChange}
      internationalIcon={internationalIcon}
      labels={labels}
      limitMaxLength={limitMaxLength}
      initialValueFormat={initialValueFormat}
      inputComponent={inputComponent}
      flagUrl={flagUrl}
      flags={flags}
      focusInputOnCountrySelection={focusInputOnCountrySelection}
      countrySelectProps={countrySelectProps}
      disabled={disabled}
      flagComponent={flagComponent}
      countryOptionsOrder={countryOptionsOrder}
      countrySelectComponent={countrySelectComponent}
      addInternationalOption={addInternationalOption}
      autoComplete={autoComplete}
      countries={countries}
      countryCallingCodeEditable={countryCallingCodeEditable}
      containerComponent={containerComponent}
      className={className}
      defaultCountry={defaultCountry}
      international={international}
      type={type}
      formControlName={formControlName}
      id={id}
      mask={mask}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      name={name}
      onBlur={onBlur}
      value={value}
      key={key}
    />
  );
};

export default PhoneNumInput;
