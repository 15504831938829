import React, { useEffect } from "react";
import { useState } from "react";
import { AuthFile, AuthGet, AuthPost } from "../../common_var/httpService";
import ClouduploadIcon from "../../assets/images/cloudUpload_icon.svg";
import Modal from "react-bootstrap/Modal";
import DownwardIcon from "../../assets/images/downward_icon.svg";
import SmalleyeIcon from "../../assets/images/smalleye_icon.svg";
import SmalldeleteIcon from "../../assets/images/smallDelete_icon.svg";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";

// import { useStateValue } from "../../Context/StateProvider";
// import FileUploadingIcon from "../../assets/images/fileUploading_icon.svg";
// import Rotateleft_roundedIcon from "../../assets/images/rotateleft_rounded_icon.svg";
// import CantUploadIcon from "../../assets/images/cantUpload_icon.svg";
// import FileuploadDoneIcon from "../../assets/images/fileupload_done.svg";
// import AddIcon from "../../assets/images/add_icon.svg";
// import PdfIcon from "../../assets/images/pdf_icon.svg";
// import TickDoneIcon from "../../assets/images/tickDone_icon.svg";
// import Download_icon from "../../assets/new/Download_icon.svg";
// import Uploadpdf from "../../assets/new/Uploadpdf.svg";
// import upload_type_img from "../../assets/new/upload_type_img.svg";
// import { env_variables } from "../../common_var/env";

function DocumentCenter() {
  let loan_id = sessionStorage.getItem("LoanId");
  const [resfile, setresdoc] = useState([]);
  const [userConsentDoc, setUserConsentDoc] = useState([]);
  //const [initialState, dispatch, authState, setAuthState] = useStateValue();
  const [upfile, setUpfile] = useState([]);
  const [doctype, setDoctype] = useState([]);
  const [filedoc, setFiledoc] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false)
  const [deleteFile, setDeleteFile] = useState({})
  const [dynamicClass, setDynamicClass] = useState(false);
  const handleClose = () => {
    setUpfile([])
    setShow(false)
  };
  const handleShow = () => setShow(true);


  const get = async (loan_id) => {
    await AuthGet(`document-vault/fetch-all-documents/${loan_id}`, "borrower")
      .then((res) => {
        //console.log(res);
        if (res["statusCode"] == 200) {
          setresdoc(res.data.files);
          setUserConsentDoc(res.data.userConsentDoc);
        } else {
          // alert(res["message"]);
          toast.error(res["message"]);
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };

  const documentDelete = async () => {
    await AuthPost(`document-vault/delete-document/${deleteFile.link_id}`, { id: deleteFile.id }, "borrower")
      .then((res) => {
        if (res["statusCode"] == 200) {
          toast.success(res['message']);
          get(deleteFile.link_id);
          setConfirmation(!confirmation)
          setDeleteFile({})
        } else {
          toast.success(res['message']);
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  }

  const removeDocument = (modified, name, index) => {
    // console.log(modified, name, index)
    // console.log(data.files.name.split(".")[0])
    // const name = data.files.name.split(".")[0]
    setFiledoc(filedoc.filter((item, i) => (i != index)));
  }
  const handleimage = (e) => {
    setDynamicClass(false)
    console.log(e.target.files);
    const dimg = [e.target.files[0]]
    setUpfile(e.target.files[0]);
    if (e.target.files[0]) {
      handleShow();
    }
    e.target.value = ''
    console.log(dimg)

  };
  console.log(upfile);

  const dropDoctype = (e) => {
    console.log(e.target.value);
    setDoctype(e.target.value);
  };

  const subDoctype = () => {
    // const fileitem = []
    if (doctype !== "") {

      let data = {
        files: upfile,
        documentsTypes: doctype,
      };
      // fileitem.push(data)

      setFiledoc([...filedoc, data]);
      handleClose();
    } else {
      toast.error("Please Select Docment Type");
    }
  };

  console.log(filedoc);

  const completed = async () => {
    let url = `document-vault/upload-documents`;
    let id = sessionStorage.getItem("LoanId");
    console.log(filedoc);
    const formData = new FormData();
    formData.append("loan_id", id);
    if (filedoc.length > 0) {
      setDynamicClass(false)
      for (var i = 0; i < filedoc.length; i++) {
        if (filedoc[i].documentsTypes === "") {
          console.log("file doc---->", filedoc[i].documentsTypes);
          filedoc[i].documentsTypes = "";
          // alert(
          //   `cannot upload, select document type for` + filedoc[i].documentsTypes
          // );
          toast.error(`cannot upload, select document type for` + filedoc[i].documentsTypes)
          return false;
        }
        formData.append("documentTypes[]", filedoc[i].documentsTypes);
        formData.append("files[]", filedoc[i].files);
      }
      await AuthFile(`${url}`, formData, "borrower")
        .then((res) => {
          console.log(res);
          if (res.statusCode == 200) {
            setFiledoc([])
            get(id);
            toast.success("Document Uploaded Sucessfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Document Uploaded Sucessfully",
            //   toastType: "success",
            // });
          }
          if (res.data != undefined) {
            if (res.data.statusCode >= 400 && res.data.statusCode <= 410) {
              toast.error(res.data.message);
              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res.data.message,
              //   toastType: "error",
              // });

            }
          }
          console.log("res---->", res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      toast.error('Please Upload a file')
      setDynamicClass(true)
    }

  };

  useEffect(() => {

    if (loan_id) {
      get(loan_id);
    }
  }, []);

  const view = (filename, key) => {
    console.log(filename);
    if (filename) {
      filename = filename?.split("/");
      filename = filename[filename.length - 1];

      window.open(key == 'admin' ? process.env.REACT_APP_ADMIN_API_URL + `/files/save/${loan_id}/` + filename : 
      process.env.REACT_APP_BORROWER_API_URL + `/document-vault/view/${loan_id}/` + filename, "_blank");
      // window.open(
      //   key == process.env.REACT_APP_BORROWER_API_URL + `/document-vault/view/${loan_id}/` + filename,
      //   "_blank"
      // );
    }
  };

  const openDeletionModel = ( document ) => {
    setDeleteFile(document)
    setConfirmation(!confirmation)
  }

  return (
    <>
      <Toast />
      <div className="whiteFrame p16 mb_24">
        <h3 className="heading18 fontWeight500 mb_20">
          Loan Documents
        </h3>

        <div className="row mb_24">
          {userConsentDoc?.map((d, i) => {

            return (
              <div className="col-md-4 mb10px" key={d.id}>
                <div className="fileDoc_Item">
                  <div className="fileDoc_left">
                    <div className="greenfolder"></div>
                    <div>
                      <h5>{d.name}</h5>
                      {/* <p>128 Kb</p> */}
                    </div>
                  </div>
                  <div>
                    <button className="docuActionButton docuActionButtonBlack" onClick={() => { view(d.filepath, 'admin') }}>
                      <img src={DownwardIcon} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}



        </div>

      </div>

      <div className="whiteFrame p16 mb_24">
        <h3 className="heading18 fontWeight500 mb_20">Documents Uploaded</h3>

        <div className="row">
          <div className="row mb_24">
            {resfile?.map((d, i) => {

              return (
                <div className="col-md-6" key={i}>
                  <div className="fileDoc_Item">
                    <div className="fileDoc_left">
                      {/* <div>
                        <img src={PdfIcon} />
                      </div> */}
                      <div className="greenfolder"></div>
                      <div>
                        <h5>{d.documenttype}</h5>
                        <small style={{ fontSize: "12px" }}>{d.originalname}</small>
                        {/* <p>128 Kb</p> */}
                      </div>
                    </div>
                    <div className="uploadDocAction">
                      <button className="viewBtn mb_4" onClick={() => { view(d.filename, 'installer') }}>
                        <img src={SmalleyeIcon} /> <span>View</span>
                      </button>
                      <button className="deleteBtn" 
                      onClick={() => openDeletionModel(d)}
                      // onClick={()=>setConfirmation(!confirmation)}
                      >
                        <img src={SmalldeleteIcon} /> <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </div>

              );
            })}
          </div>
        </div>
      </div>
      <div className="whiteFrame mb_24">
        <div className="whiteFrameHeader">
          <h3 className="heading18 fontWeight600">Upload More</h3>
        </div>
        <div className="p16">
          <div className="textAlignCenter mb_24">
            <label htmlFor="document_img" className={dynamicClass ? "label-file-upload_err" : "Chooseuploadbox"}>
              <img src={ClouduploadIcon} className="mb_12" />
              <input
                id="document_img"
                name="image"
                type="file"
                onChange={(e) => handleimage(e)}
                accept=".jpg,.jpeg,.png,application/pdf"
                hidden
              />
              <h2>Click to upload</h2>
            </label>
          </div>
          <div className="spreadsheetWrapper mb_24">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>File Name</th>
                  <th>File Format</th>
                  <th>File size</th>
                  <th>Document Type</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {(filedoc !== null && filedoc.length > 0) ? (
                  filedoc?.map((data, i) => {
                    console.log("data", data)
                    return (

                      <tr key={i}>
                        <td>
                          <div className="greenfolder"></div>
                        </td>
                        <td>{data.files.name.split(".")[0]}</td>

                        <td>{data.files.name.split(".")[1]}</td>

                        <td>{(data.files.size / 1024).toFixed(2)} KB</td>

                        <td>{data.documentsTypes}</td>
                        <td>
                          <div className="uploadDocAction">
                            <button className="deleteBtn" onClick={() => removeDocument(data.files.lastModified, data.files.name, i)}>
                              <img src={SmalldeleteIcon} /> <span>Delete</span>
                            </button>
                          </div>
                        </td>

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="textAlignCenter" colSpan={5}>
                      <h5 className="nodataText">No file selected</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="textAlignCenter">
            <button
              type="button"
              className="mainPrimary_btn redLust_clr"
              onClick={completed}
            >
              Upload
            </button>
          </div>
        </div>
      </div>

      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="addnewpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Select Document Type</Modal.Title> */}
            <h4 className="heading24_bold">Select Document Type</h4>
            {/* <div className="DisplayFlex flexWrap_wrap AlignItem_center mobilegap_12">
              <button className="emptyBtn">
                <img src="/images/modal_closeIcon.svg" />
              </button>
            </div> */}
          </Modal.Header>
          <div className="modelScroll">
            <Modal.Body>
              <select
                name="documentType"
                onChange={dropDoctype}
                className="MainInput"
              >
                <option value="">Select Document Type</option>
                <option value="Government Issued ID">
                  Government Issued ID
                </option>
                <option value="Paystub">Paystub</option>
                <option value="Income Document">Income Document</option>
                <option value="Driver License">Driver License</option>
                <option value="Bank Statement">Bank Statement</option>
                <option value="Proof of Ownership">Proof of Ownership</option>
                <option value="Others">Others</option>
              </select>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <button variant="secondary" onClick={handleClose} className='dangerbutton'>
              Close
            </button>
            <button variant="primary" onClick={subDoctype} className='addbutton' >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal
          show={confirmation}
          onHide={() => setConfirmation(!confirmation)}
          className="addnewpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Select Document Type</Modal.Title> */}
            <h4 className="heading24_bold">Confirmation</h4>
            {/* <div className="DisplayFlex flexWrap_wrap AlignItem_center mobilegap_12">
              <button className="emptyBtn">
                <img src="/images/modal_closeIcon.svg" />
              </button>
            </div> */}
          </Modal.Header>
          <div className="modelScroll">
            <Modal.Body>
              <span>Are You Sure, You Want To Delete This Document ?</span>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <button variant="secondary" onClick={() => documentDelete()} className='dangerbutton'>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
}

export default DocumentCenter;
