import React, { useEffect, useState } from "react";
import { Get, Post } from "../../common_var/httpService";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { useStateValue } from "../../Context/StateProvider";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Modal from "react-bootstrap/Modal";
import Landing from "../../Pages/StartPage/Landing";
import logo from "../../assets/images/brand_logo.svg";
import brandlogo from "../../assets/images/brand_logo.svg";

const SelectDealer = () => {
  
  const [dealer, setDealer] = useState([]);
  const [] = useState("");
  const [{ formData, errors }, dispatch] = useStateValue();
  const queryParams = useLocation();
  const params = queryParams.pathname.split("/");
  const [providername, setProvidername] = useState("");
  const [disabled, setDisabled] = useState(false);
  let url = window.location.href;
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  let splittedURL = url.split("//");
  const token = sessionStorage.getItem("borrower_token");

  const buttonClick = () => {
    setDisabled(true);
  };

  const getDealer = async () => {
    try {
      const resp = await Get("loan/fetch-providers", "admin");
      if (resp.statusCode === 200) {
        let d = resp.data;
        let domain = window.location.href.split("/selectprovider")[0];
        let practiceArr;
        if (params.length === 3) {
          practiceArr = d.filter((el) => el.practiceurl === domain);
          practiceArr[0].key = practiceArr[0].id;
          practiceArr[0].value = practiceArr[0].practicename;

          dispatch({
            type: "SET_VALUES",
            payload: { practice_id: practiceArr[0].key },
          });
          setDealer(practiceArr);
        } else {
          d.map((ele) => {
            ele.key = ele?.id;
            ele.value = `${ele?.practicename}`;
          });
          setDealer(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: "CLEAR_ERRORS",
    });
    getDealer();
    if (params[1] != "selectprovider") {
      setProvidername(params[1]);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    const userId = sessionStorage.getItem("userId");
    const loanAmount = localStorage.getItem("loan_amount");
    const bodyData = {
      ...formData,
      loan_amount: +loanAmount,
      user_id: userId,
    };
    try {
      if (Object.keys(errors).length === 0) {
        const resp = await Post(`loan/commence-loan`, bodyData, "admin");
        if (resp.statusCode === 200) {
          setDisabled(true)
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          // dispatch({
          //   type: "CLEAR_VALUES",
          // });
          dispatch({
            type: "CLEAR_ERRORS",
          });
          sessionStorage.setItem("loan_id", resp.loan_id);

          // Navigating to Onboarding Page.
          !isLocalhost
            ? providername?.length > 0
              ? window.open(
                  `${splittedURL[0]}//app${resp.key}-${
                    splittedURL[1].split(".")[0]
                  }.${
                    process.env.REACT_APP_ONBOARDING_DOMAIN
                  }/${providername}/onboarding/${resp.loan_id}/${token}`,
                  "_self"
                )
              : window.open(
                  `${splittedURL[0]}//app${resp.key}-${
                    splittedURL[1].split(".")[0]
                  }.${process.env.REACT_APP_ONBOARDING_DOMAIN}/onboarding/${
                    resp.loan_id
                  }/${token}`,
                  "_self"
                )
            : providername?.length > 0
            ? window.open(
                `${process.env.REACT_APP_ONBOARDING_URL}/${providername}/onboarding/${resp.loan_id}/${token}`,
                "_self"
              )
            : window.open(
                `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/${resp.loan_id}/${token}`,
                "_self"
              );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  return (
    <>
      <div className="fullScreen_bg">
        <div className="topHeader">
          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" />
              </div>
            </div>
          </div>

          <div></div>
        </div>

        <div className="whiteboxblock">
          <h3 className=" mb_24  sectiontitle2  ">Solar Installer</h3>

          <form onSubmit={handleSubmit}>
            <div className="mb_24 customeColum">
              {params.length === 3 ? (
                <select
                  className="MainInput"
                  name="practice_id"
                  id="practice_id"
                  // onChange={(e) => handleChange(e)}
                  disabled
                  // onBlur={() => setIsTouched(true)}
                >
                  <option value={dealer[0]?.keys}>{dealer[0]?.value}</option>
                </select>
              ) : (
                <Dropdown
                  data={dealer}
                  handleChange={handleChange}
                  labelName={"Your Solar Installer"}
                  name="practice_id"
                  required={true}
                />
              )}
            </div>
            <div className="displayFlex justifycontencenter">
             
              <div>
                <button disabled= {disabled} type="submit" className={
                  disabled ? "bg_teal btn_secondary halfopacity" : "bg_teal btn_secondary"
                }>
                  Submit
                </button>
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SelectDealer;
