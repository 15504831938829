import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "./Context/StateProvider";
import Admin from "./Admin/Admin";
import AdminLogin from "./Admin/Pages/Login";
import Dashboard from "./Admin/Pages/Dashboard";
import Loanlist from "./Admin/Pages/Loanlist";
import "./styles.scss";
import BorrowerLogin from "./Borrower/pages/login";
import Borrower from "./Borrower/Borrower";
import Guard from "./Components/Guard/Guard";
import ForgotPassword from "./Components/forgot-password/forgot-password";
import DocumentCenter from "./Borrower/pages/DocumentCenter";
import FinancingDetails from "./Borrower/pages/FinancingDetails";
import MakePayment from "./Borrower/pages/MakePayment";
import Settings from "./Borrower/pages/Settings";
import Loandetails from "./Admin/Pages/Loandetails";
import UserDetail from "./Admin/Pages/UserDetail";
import TwoFacAuth from "./Templates/twofacauth/twofacauth";
import PracticeAdmin from "./Admin/Pages/PracticeAdmin";
import PracticeManagement from "./Admin/Pages/PracticeManagement";
import PracticeRules from "./Admin/Pages/PracticeRules";
import AllPatients from "./Admin/Pages/AllPatients";
import Users from "./Admin/Pages/Users";
import AdminSecurity from "./Admin/Pages/AdminSecurity";
import Roles from "./Admin/Pages/Roles";
import AddPayment from "./Borrower/pages/addpayment";
import AuditLog from "./Admin/Pages/AuditLog";
import PasswordReset from "./Templates/password-reset/password-reset";
import Toast from "./Components/Toaster/Toast";
import Landing from "./Pages/StartPage/Landing";
import SetBorrowerPassword from "./Pages/SetPassword/SetBorrowerPassword";
import SelectDealer from "./Templates/SelectDealer/SelectDealer";
import { useEffect } from "react";
import LoanProductMapping from "./Admin/Pages/LoanProductMapping";
import BorrowerLandingPage from "./Borrower/pages/BorrowerLandingPage";
import CreateAccount from "./Templates/CreateAccount/CreateAccount";
import SelectLoanAmount from "./Templates/SelectLoanAmount/SelectLoanAmount";
import { VerifyPassword } from "./Templates/VerifyPassword/MailSent";
import AdminSSOLogin from "./Admin/Pages/AdminSSOLogin";
import ProviderAddPayment from "../src/Admin/Pages/ProviderAddPayment";
import { io } from "socket.io-client";
import { Get } from "./common_var/httpService";
import { RedirectAcceptance } from "./Components/Redirect-acceptance/Redirect";
import Payments from "./Admin/Pages/Payments";
import MilestoneConfiguration from "./Admin/Pages/MilestoneConfiguration";
import ApplicationDetails from "./Admin/Pages/ApplicationDetails";
import InstallInfo from "./Admin/Pages/Install-info";

function App() {
  const queryParams = useLocation();
  const a = "users/forgot-password";
  const b = "borrower";
  const [{ currency, socket }, dispatch] = useStateValue();
  const navigate = useNavigate();

  //console.log(value, queryParams.pathname)
  let admin_key = { key: "admin" };
  let borrower_key = { key: "borrower" };

  const connectSocket = () => {
    let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
      reconnection: false,
    });
    dispatch({
      type: "SET_SOCKET",
      payload: socket,
    });
    // console.log("socket...>>>>", socket.id)
    socket?.emit("newUser", sessionStorage.getItem("UserId"));
  };

  const getCurrency = async () => {
    await Get("api/currency", "admin")
      .then((res) => {
        dispatch({
          type: "SET_CURRENCY",
          payload: res?.currency?.symbol,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUpdatedWf = async () => {
    try {
      const res = await Get("api/fetch-newConfig", "admin");
     
      if(res.statusCode == 200){
        dispatch({
          type:"SET_LATEST_WF_CONFIG",
          payload:res.data
        })
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (queryParams?.pathname.includes("onboarding")) {
      document.body.classList.add("landing-background");
    } else {
      document.body.classList.remove("landing-background");
    }

    getCurrency();
    getUpdatedWf();
  }, []);

  const checkExpiry = (token) => {
    const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;
    return expiry > Math.floor(new Date().getTime() / 1000);
  };

  if (
    sessionStorage.getItem("login") == "true" &&
    (socket === null || socket === undefined)
  ) {
    let token;
    if (sessionStorage.getItem("admin_token")) {
      token = sessionStorage.getItem("admin_token");
    } else if (sessionStorage.getItem("borrower_token")) {
      token = sessionStorage.getItem("borrower_token");
    }
    if (checkExpiry(token)) {
      console.log("Reconnecting................. socket!");
      connectSocket();
    }
  }

  const routes = useRoutes([
    { path: "/", element: <Landing /> },
    { path: "/create-account", element: <CreateAccount /> },
    { path: "/create-account/:provider", element: <CreateAccount /> },
    { path: "/select-loan", element: <SelectLoanAmount /> },
    { path: "/select-loan/:provider", element: <SelectLoanAmount /> },
    { path: "/verify-password", element: <VerifyPassword /> },
    {
      path: "/:provider/setpassword/:token/:id",
      element: <SetBorrowerPassword />,
    },
    { path: "/setpassword/:token/:id", element: <SetBorrowerPassword /> },
    {
      path: "/cosignor/setpassword/:token/:id",
      element: <SetBorrowerPassword />,
    },
    { path: "/:token/:id", element: <BorrowerLandingPage /> },
    { path: "/:provider/:token/:id", element: <BorrowerLandingPage /> },

    {
      path: "/borrower/passwordReset",
      element: <PasswordReset portal={"borrower"} />,
    },
    {
      path: "/admin/passwordReset",
      element: <PasswordReset portal={"admin"} />,
    },
    { path: "/admin/login", element: <AdminLogin /> },
    { path: "/admin/:email/:subscription_id", element: <AdminSSOLogin /> },
    { path: "/borrower/login", element: <BorrowerLogin /> },

    { path: "/", element: <Landing /> },
    { path: "/:provider", element: <Landing /> },
    { path: "/admin/login", element: <AdminLogin /> },
    { path: "/borrower/login", element: <BorrowerLogin /> },
    { path: "/verify-bank/:token/:id", element: <BorrowerLandingPage /> },
    // {
    //   element: <OnboardingGuard />,
    //   children: [
    //     { path: "/selectprovider", element: <SelectDealer /> },
    //     { path: "/:provider/selectprovider", element: <SelectDealer /> },
    //     // { path: "/onboarding/:loanid", element: <Sales /> },
    //     // { path: "/thankyou", element: <Thankyou /> },
    //   ],
    // },
    {
      path: "/admin/forgot-password",
      element: <ForgotPassword portal={"admin"} />,
    },
    {
      path: "/borrower/forgot-password",
      element: <ForgotPassword portal={"borrower"} />,
    },
    {
      path: "/borrower/twofactorauth",
      element: <TwoFacAuth portal={"borrower"} />,
    },
    {
      path: "/admin/twofactorauth",
      element: <TwoFacAuth portal={"admin"} />,
    },
    {
      element: <Guard data={admin_key} />,
      children: [
        {
          path: "admin",
          element: <Admin />,
          children: [
            {
              path: "dashboard",
              element: <Dashboard />,
            },
            {
              path: "users/:id",
              element: <UserDetail />,
            },
            {
              path: "waiting",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "waiting/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "applications",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "applications/:stage/:id",
              element: (
                <>
                  <ApplicationDetails />
                </>
              ),
            },
            {
              path: "applications/:stage/:id/i_info",
              element: (
                <>
                  <InstallInfo />
                </>
              ),
            },
            {
              path: "pending",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "pending/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "approved",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "approved/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "milestones",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "milestones/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "fundingcontract",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "fundingcontract/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "archived",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "archived/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "denied",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "denied/:id",
              element: (
                <>
                  <Loandetails />
                </>
              ),
            },
            {
              path: "payments",
              element: (
                <>
                  <Payments />
                </>
              ),
            },
            {
              path: "applications",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "users",
              element: (
                <>
                  <Users />
                </>
              ),
            },
            {
              path: "borrowers",
              element: (
                <>
                  <AllPatients />
                </>
              ),
            },
            {
              path: "installer-management",
              element: (
                <>
                  <PracticeManagement />
                </>
              ),
            },
            {
              path: "loan-product-mapping",
              element: (
                <>
                  <LoanProductMapping />
                </>
              ),
            },
            {
              path: "practice-rules",
              element: (
                <>
                  <PracticeRules />
                </>
              ),
            },
            {
              path: "installer-admin",
              element: (
                <>
                  <PracticeAdmin />
                </>
              ),
            },
            {
              path: "settings",
              element: (
                <>
                  <Loanlist />
                </>
              ),
            },
            {
              path: "admin-security",
              element: (
                <>
                  <AdminSecurity />
                </>
              ),
            },
            {
              path: "milestoneConfiguration",
              element: (
                <>
                  <MilestoneConfiguration />
                </>
              ),
            },
            {
              path: "roles",
              element: (
                <>
                  <Roles />
                </>
              ),
            },
            {
              path: "add-bank",
              element: (
                <>
                  <ProviderAddPayment />
                </>
              ),
            },
            {
              path: "auditlog",
              element: (
                <>
                  <AuditLog />
                </>
              ),
            },
          ],
        },
      ],
    },
    {
      element: <Guard data={borrower_key} />,
      children: [
        { path: "/selectprovider", element: <SelectDealer /> },
        { path: "/:provider/selectprovider", element: <SelectDealer /> },
        {
          path: "/borrower",
          element: <Borrower />,
          children: [
            {
              path: "payment-schedule",
              element: (
                <>
                  <Guard data={borrower_key} />
                  <FinancingDetails />
                </>
              ),
            },
            {
              path: "document-center",
              element: (
                <>
                  <Guard data={borrower_key} />
                  <DocumentCenter />
                </>
              ),
            },

            {
              path: "make-payment",
              element: (
                <>
                  <Guard data={borrower_key} />
                  <MakePayment />
                </>
              ),
            },
            {
              path: "add-payment",
              element: (
                <>
                  {" "}
                  <Guard data={borrower_key} />
                  <AddPayment />
                </>
              ),
            },
            {
              path: "settings",
              element: (
                <>
                  <Guard data={borrower_key} />
                  <Settings />
                </>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/acceptance/:loanid",
      element: <RedirectAcceptance />,
    },
  ]);
  return (
    <>
      {/* <h1>Hi Im Flinks</h1>
      <iframe
        class="flinksconnect"
        height="760"
        src="https://toolbox-iframe.private.fin.ag/?demo=true&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true"
      ></iframe> */}
      <Toast />
      {routes}
    </>
  );
}

export default App;
