import React from 'react';
import { useStateValue } from '../../../Context/StateProvider';

const Verified = ({ data }) => {
  const [initialState, dispatch, authState] = useStateValue();

  return (
    <div className="commondetailTable">
      <table className="table">
        <tr>
          <th>Manufacturer of Modules : </th>
          <td> {data?.systemInfo?.moduleManufacturer} </td>
        </tr>
        <tr>
          <th> Manufacturer of Inverters :</th>
          <td>{data?.systemInfo?.inverterManufacturer}</td>
        </tr>
        <tr>
          <th>Manufacturer of Battery : </th>
          <td>{data?.systemInfo?.batteryManufacturer}</td>
        </tr>
        <tr>
          <th> System Size (kW) :</th>
          <td>{data?.systemInfo?.systemSize}</td>
        </tr>
        <tr>
          <th>Estimated Annual Production :</th>
          <td>{initialState?.currency} {" "} {data?.systemInfo?.estAnnualProduction} </td>
        </tr>
      </table>
    </div>
  );
}

export default Verified