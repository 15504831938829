import Modal from "react-bootstrap/Modal";
import React from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';
import { AuthPost } from "../../../common_var/httpService";

function SelectDocument({ setShow, setFiles, files }) {
    const uploadDocument = useFormik({
        initialValues: {
            documentTypes: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            documentTypes: Yup.string().required("required"),
        }),
        onSubmit: async (values) => {
            setShow(false);
            setFiles({ ...files, documentTypes: values.documentTypes })
        },
    });
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="fontWeight600 fontsize18 fontWeight600">
              Upload Document{" "}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={uploadDocument.handleSubmit}>
            <div className="mb_24">
              <label className="labelText">Document Type</label>
              <select
                className="MainInput"
                id="documentTypes"
                name="documentTypes"
                onChange={uploadDocument.handleChange}
                onBlur={uploadDocument.handleBlur}
                value={uploadDocument.values.documentTypes}
              >
                <option defaultValue value={null}>
                  Select document Type
                </option>
                <option value="Government Issued ID">
                  Government Issued ID
                </option>
                <option value="Paystub">Paystub</option>
                <option value="Income Document">Income Document</option>
                <option value="Driver License">Driver License</option>
                <option value="Bank Statement">Bank Statement</option>
                <option value="Proof of Ownership">Proof of Ownership</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="displayFlex alignItemscenter">
              <div>
                <button
                  className="blueborder_btn  bg_borderred mr_12"
                  type="button"
                  onClick={() => {
                    setShow(false);
                    files.element.current.value = "";
                    setFiles();
                  }}
                >
                  cancel
                </button>
              </div>
              <div>
                <button type="submit" className="blueborder_btn  bg_borderred">
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </>
    );
}

export default SelectDocument

