import React from 'react';

function Stepper({ data }) {
    console.log("Stepper data::", data);
    return (
        <div className="displayFlex justifycontencenter">
            <div class="progressBarcontainer justifycontencenter probar scrool">
                <div class="displayFlex AlignItemCenter justifycontencenter probar">
                    <div class="displayFlex AlignItemCenter">
                        <div
                            className={`progressBarcircle ${data?.applicationDetails[0].milestone_stage === null
                                ? 'circleBorderActive'
                                : ''
                                } 
                                    ${data?.applicationDetails[0].milestone_stage ===
                                    'Documents Uploaded' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Verified And Approved' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Milestone 1 Completed' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Milestone 2 Completed' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Milestone 3 Completed' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Application Completed'
                                    ? 'circleActive'
                                    : ''
                                }`}
                        >
                            1
                        </div>
                        <div
                            class="fs12px fontWeight textAlignCenter steptext"
                        >
                            Application <br />
                            File Upload
                        </div>
                    </div>
                    <div class="displayFlex AlignItemCenter">
                        <div
                            className={`grayLine 
                                    ${data?.applicationDetails[0].milestone_stage ===
                                        'Documents Uploaded' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Verified And Approved' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 1 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 2 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 3 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Application Completed'
                                    ? 'lineActive'
                                    : ''
                                }`}
                        ></div>
                        <div class="displayFlex progress-circle-cntr">
                            <div
                                className={`progressBarcircle ${data?.applicationDetails[0].status === 'documentsUploaded'
                                    ? 'circleBorderActive'
                                    : ''
                                    } 
                                    ${
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Verified And Approved' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 1 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 2 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 3 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Application Completed'
                                        ? 'circleActive'
                                        : ''
                                    }`}
                            >
                                2
                            </div>
                            <div
                                class="fs12px fontWeight textAlignCenter steptext"
                            >
                                Verify and Approve <br />
                                Application
                            </div>
                        </div>
                    </div>
                    <div class="displayFlex AlignItemCenter">
                        <div
                            className={`grayLine
                            ${
                                data?.applicationDetails[0].milestone_stage ===
                                'Verified And Approved' ||
                                data?.applicationDetails[0].milestone_stage ===
                                'Milestone 1 Completed' ||
                                data?.applicationDetails[0].milestone_stage ===
                                'Milestone 2 Completed' ||
                                data?.applicationDetails[0].milestone_stage ===
                                'Milestone 3 Completed' ||
                                data?.applicationDetails[0].milestone_stage ===
                                'Application Completed'
                                    ? 'lineActive'
                                    : ''
                                }`}
                        ></div>
                        <div class="displayFlex progress-circle-cntr">
                            <div
                                className={`progressBarcircle
                                     ${
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Verified And Approved' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 1 Completed' ||
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 2 Completed'
                                        ? 'circleBorderActive'
                                        : ''
                                    }    ${data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 3 Completed' ||
                                        data?.applicationDetails[0].milestone_stage === 'Application Completed'
                                        ? 'circleActive'
                                        : ''
                                    }`}
                            >
                                3
                            </div>
                            <div
                                class="fs12px fontWeight textAlignCenter steptext"
                            >
                                Approve <br />
                                Milestone Funding
                            </div>
                        </div>
                    </div>
                    <div class="displayFlex AlignItemCenter">
                        <div
                            className={`grayLine
                                ${
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Milestone 3 Completed' ||
                                    data?.applicationDetails[0].milestone_stage ===
                                    'Application Completed'
                                    ? 'lineActive'
                                    : ''
                                }   `}
                        ></div>

                        <div class="displayFlex progress-circle-cntr">
                            <div
                                className={`progressBarcircle
                                     ${
                                        data?.applicationDetails[0].milestone_stage ===
                                        'Milestone 3 Completed'
                                        ? 'circleBorderActive'
                                        : ''
                                    } 
                                            ${data?.applicationDetails[0]
                                        .milestone_stage === 'Application Completed'
                                        ? 'circleActive'
                                        : ''
                                    }  `}
                            >
                                <div 
                                    className={`steptick
                                           ${data?.applicationDetails[0]
                                       .milestone_stage === 'Application Completed'
                                       ? 'steptick-completed'
                                       : 'steptick-inactive'}
                                    `}
                                 ></div>
                            </div>
                            <div
                                class="fs12px fontWeight textAlignCenter steptext"
                            >
                                Project <br />
                                Completed
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stepper;
