import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import toast from "react-hot-toast";
import decisionEngineIcon from "../../assets/images/decision-engine-icon.svg";

const DecisionEngine = () => {
  const navigate = useNavigate();
  const [subscriptionId, setSubscriptionId] = useState(null);

  const getSubscriptionId = async () => {
    const user_id = sessionStorage.getItem("UserId");
    let res = await AuthGet(`api/fetchsid/${user_id}`, "admin");
    setSubscriptionId(res?.data[0].subscription_id);
  };

  useEffect(() => {
    getSubscriptionId();
  }, []);

  const redirectDecisionEngine = async () => {
    let admin = sessionStorage.getItem('UserId');
    console.log("Admin: ", admin);

    await AuthGet(`token/${subscriptionId}/${admin}`, "admin")
    .then((res) => {
      console.log("Redirect token: ", res);
      if(res?.status === 200) {
        console.log("DE URL:::", process.env.REACT_APP_DE_URL);
        window.open(`${process.env.REACT_APP_DE_URL}?tid=${admin}&t=${res?.data}`);
      }
      else {
        toast.error(res?.statusText);
      }
    })
    .catch((err) => {
      console.log("Err::", err);
      toast.error(err)
    })
  };

  return (
    <div onClick={() => redirectDecisionEngine()}>
      <li className="menuIcon">
        <div className='menuIcon'>   <img src={decisionEngineIcon} className=" " /></div>
        <div> Decision Engine </div>
      </li>
    </div>
  )
};

export default DecisionEngine;
