import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Toast from "../../Components/Toaster/Toast";
import { toast } from "react-hot-toast";
import EyehideIcon from "../../assets/images/eye_hide.svg";
import EyeshowIcon from "../../assets/images/eye_show.svg";
import { passwordValidation } from "../CommonFunctions/Validations";
const AdminSecurity = () => {
  const navigate = useNavigate();
  const [ruleCheck, setRuleCheck] = useState('');
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")
  const [passwordVisibilty1, setPasswordVisibilty1] = useState("password")
  const [passwordVisibilty2, setPasswordVisibilty2] = useState("password")

  let login_user_id = JSON.parse(sessionStorage.getItem('resuser')).id;

  useEffect(() => {
    getPracticeList();
  }, []);

  const getPracticeList = async () => {
    await AuthGet(`users/fetch-2FA/${login_user_id}`, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          setRuleCheck(res?.data?.twofactorauth);
        }
      })
  }

  console.log('statusCode', ruleCheck);

  let fa2 = ruleCheck === 'N' ? false : true;



  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   const input_name = e.target.name;
  //   const input_value = e.target.value;
  //   setForm({ ...form, [input_name]: input_value });
  // };

  const handleChange = async (e) => {
    console.log("CHECKED", e.target.checked);
    let sendData = {
      value: e.target.checked
    };
    await AuthPost(`users/toggle-2FA/${login_user_id}`, sendData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          if (!e.target.checked) {
            toast.success('Enabled successfully');
          } else if (e.target.checked) {
            toast.success('Disabled successfully');
          }
          getPracticeList();
        }
      })
  }


  const formik = useFormik({
    initialValues: {
      currentpw: '',
      newpw: '',
      cnewpw: '',
    },
    validationSchema: Yup.object({
      currentpw: Yup
        .string()
        .required('Is Required')
        // .min(8, 'Password must be 8 characters long')
        .max(16, 'Password must be less than 16 characters long'),
      newpw: Yup.string()
        .required('Is Required')
        .min(8, 'New Password must be 8 characters long')
        .max(16, 'New Password must be less than 16 characters long')
        .matches(/[0-9]/, 'New Password requires a number')
        .matches(/[a-z]/, 'New Password requires a lowercase letter')
        .matches(/[A-Z]/, 'New Password requires an uppercase letter')
        .matches(/[^\w]/, 'New Password requires a symbol')
      ,
      cnewpw: Yup.string()
        .required('Is Required')
        .oneOf([Yup.ref('newpw'), null], 'Must match "New Password" field value'),
    }),
    onSubmit: async values => {

      await AuthPost(`users/changepassword/${login_user_id}`, values, 'admin')
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success("Password changed successfully")
            sessionStorage.clear();
            navigate(`/admin/login`)

          }
          else if (res.statusCode == 100) {
            toast.error(res['message'])
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });


  return (<>
    <Toast />
    <div>

      <div className="breadcrumbsection">

        <div>
          <div className="titleText "> Security</div>
          {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /  Admin Security */}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="frameshadow mb20px">
              <form onSubmit={formik.handleSubmit}>
                <div className="titleText mb20px">
                  Change password
                </div>
                <label for="currentpw">Current Password</label><br />
                <input
                  type={passwordVisibilty1}
                  id="currentpw"
                  name="currentpw"
                  placeholder="Enter Current Password"
                  className="MainInput mb20px"
                  // maxLength={16}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "currentpw",
                      passwordValidation(e.target.value)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentpw}
                />
               <img className="eye_icon"
                src={passwordVisibilty1 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty1 == "password"
                ? setPasswordVisibilty1("text")
                : setPasswordVisibilty1("password");
                }} 
               />
                {formik.touched.currentpw && formik.errors.currentpw ? (
                  <p style={{ color: 'red' }}>{formik.errors.currentpw}</p>
                ) : null}
                <br />
                <label for="newpw">New Password</label><br />
                <input
                  type={passwordVisibilty}
                  id="newpw"
                  name="newpw"
                  className="MainInput mb20px"
                  placeholder="Enter New Password"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "newpw",
                      passwordValidation(e.target.value)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.newpw}
                />
               <img className="eye_icon"
                src={passwordVisibilty == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty == "password"
                ? setPasswordVisibilty("text")
                : setPasswordVisibilty("password");
                }} 
               />

                {formik.touched.newpw && formik.errors.newpw ? (
                  <p style={{ color: 'red' }}>{formik.errors.newpw}</p>
                ) : null}
                <br />
                <label for="cnewpw">Confirm New Password</label><br />
                <input
                  type={passwordVisibilty2}
                  id="cnewpw"
                  name="cnewpw"
                  className="MainInput mb20px"
                  placeholder="Enter Confirm New Password"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "cnewpw",
                      passwordValidation(e.target.value)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnewpw}

                />
               <img className="eye_icon"
                src={passwordVisibilty2 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty2 == "password"
                ? setPasswordVisibilty2("text")
                : setPasswordVisibilty2("password");
                }} 
               />

                {formik.touched.cnewpw && formik.errors.cnewpw ? (
                  <p style={{ color: 'red' }}>{formik.errors.cnewpw}</p>
                ) : null}

                <br />
                <button type="submit" class="btn btn-outline-primary"
                >Done</button>
              </form>

            </div>
          </div>

          <div className="col-md-6">
            <div className="frameshadow mb20px">
              <div className="titleText mb20px">
                2FA (Two Factor Auth)
              </div>
              <div class="two_factor">
                <div >Enable 2FA</div>
                <input
                  type="checkbox"
                  id="twofactorauth"
                  name="rulecheck"
                  onChange={handleChange}
                  checked={fa2 === true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
};

export default AdminSecurity;
