import { Tab } from 'bootstrap';
import React, { useEffect, useState } from 'react'
import Tabs from 'react-bootstrap/esm/Tabs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthGet } from '../../common_var/httpService';
// import { datePipe } from '../Components/general';
import FilesUpload from '../Components/Install-info/FilesUpload';
import Milestone1 from '../Components/Install-info/Milestone1';
import Milestone2 from '../Components/Install-info/Milestone2';
import MileStone3 from '../Components/Install-info/MileStone3';
import Verified from '../Components/Install-info/Verified';

function InstallInfo() {
  const { stage, id } = useParams();
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7).split("/")[0];
  const loanStatus =
    loanParams.charAt(0).toUpperCase() + loanParams.slice(1).split("/")[0];
  const handleTabClick = (tabId) => {
    console.log("ACTIVE", tabId);
    setActiveTab(tabId);
  };

//   const [showDeny, setShowDeny] = useState(false);
//   const [handleClose, setHandleClose] = useState(false);
//   const [deniedreason, setDeniedReason] = useState("");

  useEffect(() => {
    console.log(`${loanParams}/${id}`);
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await AuthGet(`installer/install-info/${id}`, "admin");

      if (res.statusCode == 200) {
        setData(res.data);
        //   setUserId(res.data.from_details[0].user_id);
        }
      } catch (error) {}
    };
  
    return (
      <>
        <div>
          <div className="mb_24">
            <div className="heading18 fontWeight18">
              Application Details{" "}
              {/* <Link to={id ? `/installer/main/applicationdetails/${id}` : null}>
                {" "}
                APP_{data?.customerDetails?.ref_no}
              </Link> */}
              {/* "/installer/approved/{{ data.customerDetails.loan_id }}" */}
            </div>
            <ul className="custom_breadcrumb">
              {/* <li onClick={() => navigate("/installer/dashboard")}>
                <button>Dashboard</button>
              </li> */}
              <li onClick={() => navigate(`/admin/applications/${stage}/${id}`)}>
                <button>Application</button>
              </li>
              <li>
                <button className="current">Application Details</button>
              </li>
            </ul>
          </div>

          <div className="customTab">
            <Tabs
                transition={true}
                id="noanim-tab-example"
                className="mb-3 tabblock"
                activeKey={activeTab}
                onSelect={handleTabClick}
            >
              <Tab title="Files Uploaded" eventKey={1}>
                <>
                  <FilesUpload data={data} />
                </>
              </Tab>
              <Tab data={data} title="Verfied System info" eventKey={2}>
                <>
                  <Verified data={data} />
                </>
              </Tab>

              {data?.milestone1ReqFiles?.length > 0 && (
                <Tab title="Milestone 1 Request" eventKey={3}>
                  <>
                    <Milestone1 data={data} get={getData} />
                  </>
                </Tab>
              )}

              {data?.milestone2ReqFiles?.length > 0 && (
                <Tab title="Milestone 2 Request" eventKey={4}>
                  <>
                    <Milestone2 data={data} get={getData} />
                  </>
                </Tab>
              )}

              {data?.milestone3ReqFiles?.length > 0 && (
                <Tab title="Milestone 3 Request" eventKey={5}>
                  <>
                    <MileStone3 data={data} get={getData} />
                  </>
                </Tab>
              )}

              {data?.installingInfo?.length > 0 && data?.installingInfo[0].approvedat != null && (
                <Tab title="Project Completed" eventKey={6}>
                  <div className="p16">
                    <div class="fontWeight500 heading18">
                      Application approved at :
                      <span className="fontWeight400">
                        {data?.installingInfo[0].approvedat}
                      </span>
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </>
    );
}

export default InstallInfo