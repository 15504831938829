import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthFile, AuthPost } from '../../../common_var/httpService';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../../../Components/Button/Button';
import ClouduploadIcon from '../../../assets/images/cloudUpload_icon.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateValue } from '../../../Context/StateProvider';
import { toast } from 'react-hot-toast';

function MilestoneTwo({ data }) {
  const [files, setFiles] = useState([]);
  const [signErr, setSignErr] = useState(false);
  const [fileErr, setFileErr] = useState(false);
  const [signature, setSignature] = useState();
  const signRef = useRef(null);
  const documentRef = useRef();
  const { id: loan_id } = useParams();
  const installerId = sessionStorage.getItem('InstallerUserId');
  const [initialState, dispatch, authState] = useStateValue();
  const [{ socket }] = useStateValue();
  const [show, setShow] = useState(data?.show);

  const formik = useFormik({
    initialValues: {
      today: '',
      agree: false,
    },
    validationSchema: Yup.object({
      today: Yup.string()
        .required('Date Is Required'),
      agree: Yup.boolean().oneOf([true], 'Please select the Checkbox.'),
    }),
    onSubmit: async (values) => {
      let errors = false
      if (files.length <= 0) {
        errors = true;
      }

      if (signRef.current._sigPad._isEmpty) {
        errors = true
      }

      if (errors) {
        setSignErr(true);
        setFileErr(true);
        return;
      }

      const formData = new FormData();
      formData.append('loan_id', loan_id);
      formData.append('user_id', installerId);
      formData.append('signature', signRef.current.getTrimmedCanvas().toDataURL());
      formData.append('milestone2ReqAmount', data?.milestone2ReqAmount);
      formData.append('projectCompletedAt', values.today);
      formData.append('verifiedInstAddress', values.agree);
      for (var i = 0; i < files.length; i++) {
        formData.append('files[]', files[i].file);
      }
      if (data?.milestone2ReqAmount && data?.milestone2ReqAmount != "NaN") {
        AuthFile("installer/milestone2Req", formData, 'admin').then((res) => {
          if (res.statusCode === 200) {
            setShow("Milestone 2");
            sendNotification("MilestoneRequest", '')
            // setTimeout(() => {
            //   window.location.reload();
            // }, 50);
          }
        }).catch((err) => console.error(err))
      }
      else {
        toast.error('Milestone req amount is required, for further assistance  contact Lender')
      }
    },
  });

  const handleimage = (e) => {
    setFiles([...files, { file: e.target.files[0], element: documentRef }]);
  };

  const todayDate = () => {
    const date = new Date();
    let dateNum = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (dateNum < 10) {
      dateNum = '0' + dateNum;
    }
    if (month < 10) {
      month = '0' + month;
    }

    const today = dateNum + '-' + month + '-' + year;
    return today;
  };

  const sendNotification = async (stage, body) => {
    let bodyData = {
      ...body, "stage": stage,
      "loan_id": loan_id,
      "user_id": ''
    }
    console.log('bodyData', bodyData)
    await AuthPost(`socket/saveNotification`, bodyData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          if (res["data"].length > 0) {
            for (var user of res["data"]) {
              // console.log('sssssssss.........................', {
              //     receiver_user_id: user.user_id,
              //     notification: { id: user.user_id, 'message': user.content },
              // })
              socket?.emit("sendNotification", {
                receiver_user_id: user.user_id,
                notification: { id: user.user_id, 'message': user.content },
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message)
      })

  }

  return (
    <div class="frameWhite mb20px">
      {data || true ? (
        <>
          <div class="fs20px fontWeight pb20">2/3 milestones</div>
          {show !== "Milestone 2" ? (
            <>
              <form name="form" onSubmit={formik.handleSubmit}>
                <div>
                  <div class="displayFlex AlignItemCenter mb20px">
                    <div class="fs30px greenColor pr20">{data?.milestoneFundPer[0]?.value}%</div>
                    <div class="fs16px">Install Complete</div>
                  </div>
                  <div class="stepProgressBarBlock mb20px">
                    <div class="stepProgressBar">
                      <div
                        class="stepProgressBarIndicator"
                        style={{ width: `${data?.milestoneFundPer[0]?.value}%` }}
                      ></div>
                    </div>
                    <div></div>
                  </div>

                  <div class="pb20">
                    Fully installed is defined as:
                    <ul>
                      <li>All permits and HQA approvals have been granted.</li>
                      <li>
                        Installation of the project, including panels,
                        inverters and all electrical items is fully complete.
                      </li>
                      {/* <li>Fully installed is defined as:</li> */}
                    </ul>
                  </div>

                  <div class="fs20px fontWeight pb20">
                    Please upload the proof of initial funding to get the
                    project started
                  </div>

                  <div className="textAlignCenter mb_24">
                    <label htmlFor="document_img" className="Chooseuploadbox">
                      <img src={ClouduploadIcon} className="mb_12" />
                      <input
                        id="document_img"
                        name="image"
                        ref={documentRef}
                        type="file"
                        onChange={handleimage}
                        accept=".doc,.docx,application/pdf"
                        hidden
                      />
                      <h2>Drop files here or click to upload.</h2>
                    </label>
                    {fileErr && files.length <= 0 ? (
                      <p className='danger'>Kindly Upload Proof For The Initial Funding</p>
                    ) : null}
                  </div>

                  {files.length > 0 ? (
                    <>
                      <div class="fs22px pb20">Uploaded documents</div>

                      <div>
                        {files.map((file, i) => (
                          <div
                            key={i}
                            class="whiteBox displayFlex AlignItemCenter mb20px"
                          >
                            <div class="displayFlex AlignItemCenter w50per">
                              <div class="greenfolder"></div>
                              <div>{file.file.name.split(".")[0]}</div>
                            </div>

                            <div class="w15per">
                              <button class="fileextension">
                                {file.file.name.split(".")[1].toUpperCase()}
                              </button>
                            </div>
                            <div class="w15per">
                              {(file.file.size / 1024).toFixed(2)} KB
                            </div>
                            <div class="w15per">{todayDate()}</div>
                            <div class="displayFlex w10per flexEnd">
                              <div
                                class="delete_icon cursorpointer"
                                onClick={() => {
                                  files[i].element.current.value = "";
                                  setFiles(
                                    files.filter((file, index) => index !== i)
                                  );
                                }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <div class="fs20px fontWeight pb20">
                    Date project was fully installed/completed
                  </div>

                  <div class="w48per pb20">
                    <input
                      type="date"
                      name="today"
                      class="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.today}
                    />
                    {formik.touched.today && formik.errors.today ? (
                      <p className="error_text">{formik.errors.today}</p>
                    ) : null}
                  </div>
                  <div class="fs20px fontWeight pb20">
                    Verify Installation address
                  </div>

                  <div class="pb20">
                    <input
                      class=" "
                      name="agree"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agree}
                      id="flexCheckDefault"
                      style={{ marginRight: "10px" }}
                    />

                    <label class="form-check-label" for="flexCheckDefault">
                      {/* {data?.applicationDetails[0]?.unit} */}
                      {data?.applicationDetails[0]?.street_address}
                      {/* {data?.applicationDetails[0]?.city}, */}
                      {/* {data?.applicationDetails[0]?.zipCode} */}
                    </label>

                    {formik.touched.agree && formik.errors.agree ? (
                      <p className="error_text">{formik.errors.agree}</p>
                    ) : null}
                  </div>

                  <div class="fs20px fontWeight pb20">
                    Request {data?.milestoneFundPer[1]?.value}% of funding
                  </div>

                  <div class="w48per pb20">{initialState?.currency} {" "} {data?.milestone2ReqAmount}</div>

                  <div class="w48per col-6 mb_24">
                    <div className='installer-signature'>
                      <SignatureCanvas
                        ref={signRef}
                        onEnd={() =>
                          setSignature(
                            signRef.current.getTrimmedCanvas().toDataURL()
                          )
                        }
                        canvasProps={{ width: 430, height: 130 }}
                      />
                      
                    </div>
                    {signErr && !signature ? (
                        <p className="requiredText" >Signature Is Pending <span>*</span></p>
                      ) : null}

                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      type={"button"}
                      value="Clear"
                      handleClick={() => {
                        signRef.current.clear();
                        setSignature(null);
                      }}
                    />
                  </div>

                  <div class="textAlignCenter">
                    <button type="submit" className="blueborder_btn bg_borderred">
                      REQUEST
                    </button>
                    {/* </div> */}
                  </div>

                  {/* <div class="pb20 displayFlex AlignItemCenter spaceBetween"> */}
                  {/* <div class="w48per col-6">
                        <button
                          type="submit"
                          className="blueborder_btn bg_borderred"
                        >
                          REQUEST
                        </button>
                      </div> */}
                  {/* </div> */}
                </div>
              </form>


            </>
          ) : null}
        </>
      ) : null}

      {show === "Milestone 2" ? (
        <div>
          <div class="fs20px fontWeight pb20 text-center">
            <div class="card">
              <div class="card-body">Waiting For {show} Approval</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MilestoneTwo;
