import React, { useState } from "react";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const OtherBankConnect = ({
  showBankDetails,
  borrowerbankAcc,
  setShowBankDetail,
  setShowUserDetail,
  showUserDetail,
  handleSendBankEmail,
  showBankDetail,
  activeBar,
  handleCurrentAcc,
  showCurrentAcc,
  showHistoricalAcc,
  currency,
  columnsCurrentChecking,
  defaultCurrentCheckingSorted,
  paginationCurrentChecking,
  handleManualBankData,
  showManualAcc,
  userData,
  columnsManualBank,
  defaultManualBankSorted,
  coborrowerbankAcc,
}) => {
  const { SearchBar, ClearSearchButton } = Search;
  const [show, setShow] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  const handleSelectBank = (el) => {
    setSelectedBank(el);
    setShow((p) => !p);
  };

  return (
    <>
      <div className="container-fluid mb20px frameshadow">
        <h5 className="frameshadow mb20px">Borrower Info</h5>
        {showBankDetails && (
          <div className="frameshadow-payment" style={{ cursor: "pointer" }}>
            <div>
              {borrowerbankAcc?.length > 0 ? (
                <div
                  onClick={() => {
                    setShowBankDetail(true);
                    setShowUserDetail({
                      Borrowerstatus: !showUserDetail.Borrowerstatus,
                    });
                    handleCurrentAcc("B")
                  }}
                >
                  Show Bank Details
                </div>
              ) : (
                <button
                  onClick={() => handleSendBankEmail()}
                  type="button"
                  className="btn btn-primary"
                >
                  Request To Add Bank
                </button>
              )}
            </div>
          </div>
        )}

        {/* Three Heading div inside show bank detail ---- start */}
        <div style={{ marginBottom: "20px" }}>
          {showBankDetail && showUserDetail.Borrowerstatus ? (
            <div>
              <div className="frameshadow-payment-detail">
                <div style={{ display: "flex" }} className="mb20px">
                  <div
                    onClick={() => handleCurrentAcc("B")}
                    className={"innerActive"}
                  >
                    Current Account Information
                  </div>
                </div>
                <div>
                  {showUserDetail.Borrowerstatus &&
                  showCurrentAcc &&
                  !showHistoricalAcc ? (
                    <div>
                      {borrowerbankAcc.map((el) => {
                        return (
                          <>
                            <div className="panel-heading mb20px">
                              <div onClick={() => handleSelectBank(el)}>
                                {el?.headername}
                              </div>
                              <div>
                                {/* to be shown*/}
                                {show && selectedBank === el && (
                                  <div>
                                    <div className="mt20px ">
                                      <table
                                        border={1}
                                        className="userinfotable mb20px"
                                      >
                                        <tbody>
                                          <tr>
                                            <td className="w30percent">
                                              Account Name
                                            </td>
                                            <td>
                                              {el?.name ? el?.name : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Type</td>
                                            <td>
                                              {el?.type ? el?.type : "NA"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td> Account Subtype</td>
                                            <td>
                                              {el?.subtype ? el?.subtype : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Number</td>
                                            <td>
                                              {el?.acno ? el?.acno : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Routing Number</td>
                                            <td>
                                              {el?.routing ? el?.routing : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              {" "}
                                              Account Wire Routing Number
                                            </td>
                                            <td>
                                              {el?.wire_routing
                                                ? el?.wire_routing
                                                : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Institution Type</td>
                                            <td>
                                              {el?.institution_id
                                                ? el?.institution_id
                                                : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Current Balance</td>
                                            <td>
                                              {" "}
                                              {currency}{" "}
                                              {el?.current_bal
                                                ? el?.current_bal
                                                : "NA"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td> Account Available Balance</td>
                                            <td>
                                              {" "}
                                              {currency}{" "}
                                              {el?.available_bal
                                                ? el?.available_bal
                                                : "NA"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    <div>
                                      <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        data={el?.transactions}
                                        columns={columnsCurrentChecking}
                                        search
                                      >
                                        {(props) => (
                                          <div>
                                            <SearchBar {...props.searchProps} />
                                            <ClearSearchButton
                                              {...props.searchProps}
                                            />
                                            <hr />
                                            <BootstrapTable
                                              bootstrap4
                                              key="loan_ref"
                                              defaultSorted={
                                                defaultCurrentCheckingSorted
                                              }
                                              pagination={
                                                paginationCurrentChecking
                                              }
                                              {...props.baseProps}
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* Three Heading div inside show bank detail ---- end */}

        {/* Other bank name div --- start */}
        <div className="frameshadow-payment" style={{ cursor: "pointer" }}>
          <div>
            <div onClick={() => handleManualBankData()}>
              Other Bank Accounts
            </div>
          </div>
        </div>
        {/* Other bank table --- start */}
        <div>
          {showManualAcc ? (
            <div className="frameshadow-payment-detail">
              <div>
                <ToolkitProvider
                  bootstrap4
                  keyField="id"
                  data={userData?.manualBank}
                  columns={columnsManualBank}
                  search
                >
                  {(props) => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      <hr />
                      <BootstrapTable
                        bootstrap4
                        key="id"
                        defaultSorted={defaultManualBankSorted}
                        // pagination={paginationCurrentChecking}
                        {...props.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Other bank table --- end */}
      </div>
      <div>
        {/* CO-Borrower Bank Details */}
        {userData?.coborrower && (
          <div className="container-fluid mb20px frameshadow">
            <h5 className="frameshadow mb20px">Co-Borrower Info</h5>
            {showBankDetails && (
              <div
                className="frameshadow-payment"
                style={{ cursor: "pointer" }}
              >
                <div>
                  {coborrowerbankAcc?.length > 0 ? (
                    <div
                      onClick={() => {
                        setShowBankDetail(true);
                        setShowUserDetail({
                          CoBorrowerstatus: !showUserDetail.CoBorrowerstatus,
                        });
                        handleCurrentAcc("CB")
                      }}
                    >
                      Show Bank Details
                    </div>
                  ) : (
                    <button
                      onClick={() => handleSendBankEmail()}
                      type="button"
                      className="btn btn-primary"
                    >
                      Request To Add Bank
                    </button>
                  )}
                </div>
              </div>
            )}
            {/* Three Heading div inside show bank detail ---- start */}
            <div style={{ marginBottom: "20px" }}>
              {showBankDetail && showUserDetail.CoBorrowerstatus ? (
                <div>
                  <div className="frameshadow-payment-detail">
                    <div style={{ display: "flex" }} className="mb20px">
                      <div
                        onClick={() => handleCurrentAcc("CB")}
                        className={
                          activeBar === "currentAcc"
                            ? "innerActive"
                            : "innertab"
                        }
                      >
                        Current Account Information
                      </div>
                    </div>
                    <div>
                      {
                        // current acc pop up --- start
                        showUserDetail?.CoBorrowerstatus &&
                        showCurrentAcc &&
                        !showHistoricalAcc ? (
                          <div>
                            {/* current acc bank 1 */}
                            {coborrowerbankAcc.map((el) => {
                              return (
                                <>
                                  <div className="panel-heading mb20px">
                                    <div onClick={() => handleSelectBank(el)}>
                                      {el?.headername}
                                    </div>
                                    <div>
                                      {/* to be shown*/}
                                      {show && selectedBank === el && (
                                        <div>
                                          <div className="mt20px ">
                                            <table
                                              border={1}
                                              className="userinfotable mb20px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td className="w30percent">
                                                    Account Name
                                                  </td>
                                                  <td>
                                                    {el?.name ? el?.name : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td> Account Type</td>
                                                  <td>
                                                    {el?.type ? el?.type : "NA"}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td> Account Subtype</td>
                                                  <td>
                                                    {el?.subtype
                                                      ? el?.subtype
                                                      : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td> Account Number</td>
                                                  <td>
                                                    {el?.acno ? el?.acno : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    {" "}
                                                    Account Routing Number
                                                  </td>
                                                  <td>
                                                    {el?.routing
                                                      ? el?.routing
                                                      : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    {" "}
                                                    Account Wire Routing Number
                                                  </td>
                                                  <td>
                                                    {el?.wire_routing
                                                      ? el?.wire_routing
                                                      : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    {" "}
                                                    Account Institution Type
                                                  </td>
                                                  <td>
                                                    {el?.institution_id
                                                      ? el?.institution_id
                                                      : "NA"}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    {" "}
                                                    Account Current Balance
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {currency}{" "}
                                                    {el?.current_bal
                                                      ? el?.current_bal
                                                      : "NA"}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {" "}
                                                    Account Available Balance
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {currency}{" "}
                                                    {el?.available_bal
                                                      ? el?.available_bal
                                                      : "NA"}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>

                                          <div>
                                            <ToolkitProvider
                                              bootstrap4
                                              keyField="id"
                                              data={el?.transactions}
                                              columns={columnsCurrentChecking}
                                              search
                                            >
                                              {(props) => (
                                                <div>
                                                  <SearchBar
                                                    {...props.searchProps}
                                                  />
                                                  <ClearSearchButton
                                                    {...props.searchProps}
                                                  />
                                                  <hr />
                                                  <BootstrapTable
                                                    bootstrap4
                                                    key="loan_ref"
                                                    defaultSorted={
                                                      defaultCurrentCheckingSorted
                                                    }
                                                    pagination={
                                                      paginationCurrentChecking
                                                    }
                                                    {...props.baseProps}
                                                  />
                                                </div>
                                              )}
                                            </ToolkitProvider>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* Three Heading div inside show bank detail ---- end */}

            {/* Other bank name div --- start */}
            <div className="frameshadow-payment" style={{ cursor: "pointer" }}>
              <div>
                <div onClick={() => handleManualBankData()}>
                  Other Bank Accounts
                </div>
              </div>
            </div>
            {/* Other bank name div --- end */}

            {/* Other bank table --- start */}
            <div>
              {showManualAcc ? (
                <div className="frameshadow-payment-detail">
                  <div>
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={userData?.manualBank}
                      columns={columnsManualBank}
                      search
                    >
                      {(props) => (
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton {...props.searchProps} />
                          <hr />
                          <BootstrapTable
                            bootstrap4
                            key="id"
                            defaultSorted={defaultManualBankSorted}
                            // pagination={paginationCurrentChecking}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* Other bank table --- end */}
          </div>
        )}
      </div>
    </>
  );
};

export default OtherBankConnect;
