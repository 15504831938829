import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthPut } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EyehideIcon from "../../assets/images/eye_hide.svg";
import EyeshowIcon from "../../assets/images/eye_show.svg";
import { emailValidation } from "../../Admin/CommonFunctions/Validations";
import { passwordValidation } from "../../Admin/CommonFunctions/Validations";
const ChangePassword = ({ dataurl }) => {
  // const [initialState, dispatch, authState, setAuthState] = useStateValue();
  const navigate = useNavigate()
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")
  const [passwordVisibilty1, setPasswordVisibilty1] = useState("password")
  const [passwordVisibilty2, setPasswordVisibilty2] = useState("password")

  const formik = useFormik({
    initialValues: {
      currentpw: '',
      newpw: '',
      cnewpw: '',
    },
    validationSchema: Yup.object({
      currentpw: Yup
        .string()
        .required('Current Password is Required'),
      newpw: Yup.string()
        .required('New Password is Required')
        .min(8, 'New Password must be 8 characters long')
        .matches(/[0-9]/, 'New Password requires a number')
        .matches(/[a-z]/, 'New Password requires a lowercase letter')
        .matches(/[A-Z]/, 'New Password requires an uppercase letter')
        .matches(/[^\w]/, 'New Password requires a symbol')
      ,
      cnewpw: Yup.string()
        .required('Confirm New Password is Required')
        .oneOf([Yup.ref('newpw'), null], 'Must match "New Password" field value'),
    }),
    onSubmit: async values => {
      await AuthPut(dataurl.url, values, dataurl.key)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success("Password changed successfully")
            sessionStorage.clear();
            navigate(`/${dataurl.key}/login`)
          }
          else if (res.statusCode == 100) {
            toast.error(res['message'])
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <>
      <Toast />
      <div className={dataurl.key == 'borrower' ? "whiteFrame p16 mb20px" : "grayBox mb50px"}>
        <form onSubmit={formik.handleSubmit}>
          <div className="fs22px pb20">Change password</div>

          <label >Current Password</label>
          <br />
          <input
            type={passwordVisibilty1}
            id="currentpw"
            name="currentpw"
            className="MainInput "
            placeholder="Enter Current Password"
            onChange={(e) => {
              formik.setFieldValue(
                "currentpw",
                passwordValidation(e.target.value)
              );
            }}
            onBlur={formik.handleBlur}
            value={formik.values.currentpw}
          />
                <img className="eye_icon"
                src={passwordVisibilty1 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty1 == "password"
                ? setPasswordVisibilty1("text")
                : setPasswordVisibilty1("password");
                }} 
               />
          {formik.touched.currentpw && formik.errors.currentpw ? (
            <p style={{ color: 'red' }}>{formik.errors.currentpw}</p>
          ) : null}
          <br />
          <label >New Password</label>
          <br />
          <input
            type={passwordVisibilty}
            id="newpw"
            placeholder="Enter New Password"
            name="newpw"
            className="MainInput "
            onChange={(e) => {
              formik.setFieldValue(
                "newpw",
                passwordValidation(e.target.value)
              );
            }}
            onBlur={formik.handleBlur}
            value={formik.values.newpw}

          />
               <img className="eye_icon"
                src={passwordVisibilty == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty == "password"
                ? setPasswordVisibilty("text")
                : setPasswordVisibilty("password");
                }} 
               />
          {formik.touched.newpw && formik.errors.newpw ? (
            <p style={{ color: 'red' }}>{formik.errors.newpw}</p>
          ) : null}
          <br />
          <label >Confirm New Password</label>
          <br />
          <input
            type={passwordVisibilty2}
            id="cnewpw"
            placeholder="Enter Confirm New Password"
            name="cnewpw"
            className="MainInput "
            onChange={(e) => {
              formik.setFieldValue(
                "cnewpw",
                passwordValidation(e.target.value)
              );
            }}
            onBlur={formik.handleBlur}
            value={formik.values.cnewpw}

          />
              <img className="eye_icon"
                src={passwordVisibilty2 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty2 == "password"
                ? setPasswordVisibilty2("text")
                : setPasswordVisibilty2("password");
                }} 
               />

          {formik.touched.cnewpw && formik.errors.cnewpw ? (
            <p style={{ color: 'red' }}>{formik.errors.cnewpw}</p>
          ) : null}
          <br />
          <br />
          <button
            className="addbutton mb20px"
            type="submit"

          >
            Save
          </button>
        </form>

      </div>
    </>
  );
};

export default ChangePassword;
