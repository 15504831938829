import React, { useState } from "react";
import Email from "../../Components/Email/Email";
import Phone from "../../Components/Phone/Phone";
import Button from "../../Components/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import { AuthPost, AuthGet } from "../../common_var/httpService";
import Modal from "react-bootstrap/Modal";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";
import logo from "../../assets/images/brand_logo.svg";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { useEffect } from "react";
import brandlogo from "../../assets/images/brand_logo.svg";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import Dropdown from "../../Components/Dropdown/Dropdown";

const CreateAccount = () => {
  const [{ errors, formData, formSubmitted, emailPhonePopup }, dispatch] =
    useStateValue();
  const navigate = useNavigate();
  const queryParams = useLocation();
  const params = queryParams.pathname?.split("/");
  console.log('errors::: ', errors);

  const [products, setProducts] = useState();

  const handleClose = () => {
    dispatch({
      type: "SET_EMAILPHONE_POPUP",
      payload: false,
    });
    dispatch({
      type: "CLEAR_ERRORS",
    });
  };

  useEffect(() => {
    getProducts();

    dispatch({
      type: "CLEAR_ERRORS",
    });

    return () => {
      dispatch({
        type: "CLEAR_ERRORS",
      });
      dispatch({
        type: "CLEAR_VALUES",
      });
      dispatch({
        type: "SET_EMAILPHONE_POPUP",
        payload: false,
      });
    };
  }, []);

  const getProducts = async () => {
    await AuthGet("api/solar-products", "admin")
      .then((res) => {
        setProducts(res?.data);
      })
  }

  console.log("Products::", products);

  const handleChange = (e,newName) => {
    //  debugger
    // const name = e.target.name;
    // let val = e.target.value;
   let name;
    let val;
    if(newName === "phone"){
      name = newName;
      val = e;
    }else{
      name = e.target.name;
      val = e.target.value;
    }
 


    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  useEffect(() => {
    formSubmitted === false && dispatch({
      type: 'CLEAR_ERRORS'
    })
  },[formSubmitted])

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });

    const loanAmount = JSON.parse(localStorage.getItem("loan_amount"));

    try {
      if (Object.keys(errors).length === 0) {
        formData.email = formData?.email?.toLowerCase();
        formData.loanAmount = loanAmount;
        // formData.phone = formData.phone.replace(/[^\d]/g, "");
        formData.provider = params[2]
          ? window.location.href.replace("create-account/", "")
          : window.location.href;
        console.log("Add data:::", formData);
        const resp = await AuthPost(
          "auth/create-account",
          formData,
          "borrower"
        );
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          toast.success(resp.message);
          // handleClose();
          navigate("/verify-password");
        } else if (resp.statusCode === 400) {
          toast.error(resp.message[0]);
          navigate("/borrower/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="fullScreen_bg">
        <div className="topHeader">
          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" />
              </div>
            </div>
          </div>

          <div></div>
        </div>

        <div className="whiteboxblock">
          <h2 className="textAlignCenter">
            Tell us more about borrower
            {/* or Share more about yourself or Provide
            additional information about you */}
          </h2>

          <p className="textAlignCenter mb_24">
            Let us create and verify borrower identity
          </p>
          <form onSubmit={handleSubmit} className="customeColum">
            <Email
              name="email"
              labelName={"Email Address "}
              placeholder={"Email Address"}
              handleChange={handleChange}
              required={true}
            />
            <Phone defaultCountry="US" international={true}
              name="phone"
              labelName={"Cell Phone Number"}
              placeholder={"(000) 000-0000"}
              required={true}
              withCountryCode={true}
           
            /> 

            <Dropdown
              data={products}
              name={"product"}
              labelName={"Product"}
              handleChange={handleChange}
              required={true}
              readOnly={false}
            />

            <div className="displayFlex justifycontencenter">
              <Button
                className="btn_secondary bg_teal"
                type={"submit"}
                value="Continue"
                handleClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>

      <div></div>
      <div></div>
    </>

  );
};

export default CreateAccount;
