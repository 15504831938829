import React, { useState, useEffect } from 'react';
import { AuthPost } from "../../common_var/httpService"
import { useLocation, useNavigate } from 'react-router-dom';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';


const Deliquency = () => {
    const [deliquencyData, setDeliquencyData] = useState([]);
    const [deliquencypage, setDeliquencypage] = useState(1);
    const [page, setPage] = useState(1);
    const [loanData, setLoanData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState(1);
    const [deliquencycurrentPage, setDeliquencycurrentPage] = useState(1);
    const navigate = useNavigate()
    const queryParams = useLocation()
    const loanParams = queryParams.pathname.slice(7)
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        if (tabId == 1) {
            getDeliquency({
                start: 0,
                end: 30
            });
        }
        if (tabId == 2) {
            getDeliquency({
                start: 31,
                end: 60
            });
        }
        if (tabId == 3) {
            getDeliquency({
                start: 61,
                end: 90
            });
        }
        if(tabId==4){
            getDeliquency({
                start: 91,
                end: 0
            });
        }
    };
    useEffect(() => {
        getDeliquency({
            start: 0,
            end: 30
        })
    }, [])

    const getDeliquency = async (body) => {

        await AuthPost(`loanstage/delinquency`, body, "admin")
            .then((res) => {
                if (res["statusCode"] == 200) {
                    // console.log("Res Data: ", res);
                    setDeliquencyData(res.data);
                    setDeliquencypage(Math.ceil(loanData.length / 10));
                    setDeliquencycurrentPage(1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const { SearchBar, ClearSearchButton } = Search;
    const columns1 = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "name", text: 'Name',
            formatter: (cell) => {
                
                return cell;
            },
            events: {
                onClick: (e, column, columnIndex, row,cell, rowIndex) => {
                    
                    sessionStorage.setItem("loan_id", row.loan_id);
                    sessionStorage.setItem("procedure_date", JSON.stringify({}));
                    navigate(`/admin/${loanParams}/${row.loan_id}`);
                },
            },
            style: {
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
                overflow: 'hidden'
            }
        },
        {
            dataField: "unpaidprincipal", text: 'Unpaid Principal Balance',
            formatter: (cell) => {
                return "$" + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "principal", text: 'Principal',
            formatter: (cell) => {
                return "$" + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "interest", text: 'Interest',
            formatter: (cell) => {
                return "$" + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "fees", text: 'Fees',
            formatter: (cell) => {
                if (cell === ".00") {
                    return "$ 0.00";
                }
                return "$" + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "pastDue", text: 'Past due',

            formatter: (cell) => {
                if (cell == ".00") {
                    return "$0" + cell;
                } else if (!cell) {
                    return "$0"
                } else if (cell == '0') {
                    return "$0.0" + cell
                } else {
                    return "$" + cell
                }
            }
        },
        {
            dataField: "amount", text: 'Amount',
            formatter: (cell) => {
                return "$" + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "scheduleDate", text: 'Schedule Date',
            formatter: (cell) => {
                return cell?.split("T")[0];
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "delinquency_count", text: 'Deliquency',
            formatter: (cell) => {
                return cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });
    return (
        <>
         <div className=" ">

<Tabs
    transition={true}
    id="noanim-tab-example"
    className="mb-3 tabblock"
    activeKey={activeTab}
    unmountOnExit={true}
    mountOnEnter={true}
    onSelect={handleTabClick}
>
    <Tab title="0-30" eventKey={1}>
        <div className="frameshadow">
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={deliquencyData}
                columns={columns1}
                search
            >
                {
                    props => (
                        <div>
                            <SearchBar {...props.searchProps} />
                            <ClearSearchButton {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                                bootstrap4
                                key='loan_ref'
                                // defaultSorted={defaultSorted}
                                pagination={pagination}
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>

    </Tab>
    <Tab title="31-60" eventKey={2}>

        <div className="frameshadow">
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={deliquencyData}
                columns={columns1}
                search
            >
                {
                    props => (
                        <div>
                            <SearchBar {...props.searchProps} />
                            <ClearSearchButton {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                                bootstrap4
                                // key='loan_ref'
                                // defaultSorted={defaultSorted}
                                pagination={pagination}
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    </Tab>
    <Tab title="61-90" eventKey={3}>

        <div className="frameshadow">
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={deliquencyData}
                columns={columns1}
                search
            >
                {
                    props => (
                        <div>
                            <SearchBar {...props.searchProps} />
                            <ClearSearchButton {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                                bootstrap4
                                key='loan_ref'
                                // defaultSorted={defaultSorted}
                                pagination={pagination}
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    </Tab>
    <Tab title="91+" eventKey={4}>

        <div className="frameshadow">
            <ToolkitProvider
                bootstrap4
                keyField='id'
                data={deliquencyData}
                columns={columns1}
                search
            >
                {
                    props => (
                        <div>
                            <SearchBar {...props.searchProps} />
                            <ClearSearchButton {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                                bootstrap4
                                key='loan_ref'
                                // defaultSorted={defaultSorted}
                                pagination={pagination}
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    </Tab>
</Tabs>
</div>
        </>
    )
}

export default Deliquency
