import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthGet, AuthFile, AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";

const CommonToggle = ({ Paramsdata, id, fun, title, data, dynamicClass }) => {
  const [initialState, dispatch, authState, setAuthState] = useStateValue();
  const navigate = useNavigate();
  // console.log(data);
  const [facvalue, setFacvalue] = useState(data);

  // console.log(facvalue);

  useEffect(() => {
    setFacvalue(data);
  }, [data]);

  const updateTwoFac = async (e) => {
    let val = e.target.checked;
    let ddata = e.target.checked;
    console.log(ddata)
    if (val == true) {
      setFacvalue(true);
    } else {
      setFacvalue(false);
    }
    await AuthPost(
      Paramsdata.url,
      {
        value: ddata,
      },
      Paramsdata.key
    )
      .then((res) => {
        //  console.log("2 fac console---->", res);
        if (res.statusCode === 200) {
          if(ddata){
            toast.success('Enabled successfully');
          }else
          {
            toast.success('Disabled successfully');
          }
          fun();
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };

  return (
    <>
      <Toast />
      <div className={"whiteFrame p16 autopaymentheight"}>
        <div className="displayFlex spaceBetween">
          <div className="fontWeight">{title}</div>
        </div>
        <div className="displayFlex spaceBetween pb20">
          <div>Enable {title}</div>
          <div className=" " style={{ display: "grid" }}>
            <div className="switch">
              <input
                type="checkbox"
                id="twoFactorAuth"
                checked={facvalue}
                onChange={(e) => {
                  //  console.log("e---->", e.target.checked);
                  updateTwoFac(e);
                }}
              />
              <label htmlFor="twoFactorAuth" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonToggle;
