import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";

const Logs = () => {
  const [userData, setUserData] = useState([]);
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const loanStage = params[params.length - 2];
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    getlist();
  }, [queryParams]);

  const getlist = async () => {
    await AuthGet(`Deals/fetch-logs/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setUserData(res.data);
          setPage(Math.ceil(res.data.paymentScheduleDetails.length / 10));
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        return slNo;
      },
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
        textAlign: "center",
      },
    },
    // {
    //     dataField: "id", text: 'Log Reference',
    //     headerStyle: {
    //         width: "150px"
    //     }
    // },
    {
      dataField: "access_id",
      text: "Access id",
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "ip",
      text: "IP",
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "user_id",
      text: "User id",
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "message",
      text: "Message",
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "createdat",
      text: "Created At",
      style: {
        overflow: "hidden",
      },
    },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
    },
  });

  return (
    <>
      {/* <div>
                <h5>Log Activity Details</h5>
            </div> */}
      <div className="frameshadow">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={userData}
          columns={columns}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default Logs;
