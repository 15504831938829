
import brandlogo from "../../assets/images/brand_logo.svg";
import emailBanner from "../../assets/images/email-banner.png";
export const VerifyPassword = () => {


    return (
        <>


            <div className="fullScreen_bg">
                <div className="topHeader">


                    <div className="logosection">
                        <div className="displayFlex">
                            {/* <img src={menuicon} className="mobileMenuicon" /> */}
                            <div className="brand_logo">
                                <img src={brandlogo} className="whiteFilter" />
                            </div>
                        </div>
                    </div>

                    <div>



                    </div>

                </div>


                <div className="whiteboxblock2 displayFlex justifycontencenter flexDirection AlignItemCenter">
                    <img src={emailBanner} className="w250px" />

                    <div >  <div className="congrattext textAlignCenter">

                        Thank you

                    </div>

                        <div>
                            Set Password link is sent to your email address. Please verify.

                        </div>
                    </div>
                </div>


            </div>



        </>
    )

}