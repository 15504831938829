import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import BorrowerSidebar from "../Components/sidebar/borrowersidebar.jsx";
import { useRoutes, useLocation } from "react-router-dom";

import NotificationIcon from "./../assets/images/notification_icon.svg";
import SettingheaderIcon from "./../assets/images/settingheader_icon.svg";
import UserProfilelogo from "./../assets/images/userProfile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import MenuhorizontalIcon from "./../assets/images/menu_horizontal_icon.svg";
import { useStateValue } from "../Context/StateProvider";
import OverviewIcon from "./../assets/images/overview_icon.svg";
import PaymentscheduleIcon from "./../assets/images/payment_schedule.svg";
import PaymentmethodIcon from "./../assets/images/payment_method.svg";
import DocumentmanagementIon from "./../assets/images/document_management.svg";
import QuickactionIcon from "./../assets/images/quickAction_icon.svg";

import FaceBookIcon from "./../assets/images/fb_icon.svg";
import InstaIcon from "./../assets/images/insta_icon.svg";
import LinkedinIcon from "./../assets/images/linkedin_icon.svg";
import TwitterIcon from "./../assets/images/twitter_icon.svg";
import GreaterthanIcon from "./../assets/images/greaterthan_icon.svg";
import menuicon from "./../assets/images/menu-icon.svg";
import logo from "./../assets/images/brand_logo.svg";
// import footerlogo from "./../assets/images/whitelogo.svg";
import pencilicon from "./../assets/images/pencil-icon.svg";
import profileborder from "./../assets/images/profile-border.svg";
import { AuthGet ,AuthPost} from "../common_var/httpService.js";
import chaticon from "./../assets/images/chat-icon.png";
import closeicon from "./../assets/images/close-icon.svg";
import Brandlogo from "../../src/assets/images/brand_logo.svg";
import markread from "../../src/assets/images/mark_Read.svg";
import threedot from "../../src/assets/images/three_dot.svg";
// import Brandlogo from "../../assets/images/brand_logo.svg";


const Borrower = () => {
  const [{  socket }, dispatch] = useStateValue();
  const queryParams = useLocation();
  const navigate = useNavigate()
  const [selectid, setSelectid] = useState("");
  const [count,setCount]=useState(0)
  const user_id = sessionStorage.getItem("UserId");
  const [toggle,setToggle]=useState(false)
  
  const [notifications, setNotifications] = useState([]);
  // {path:"/borrower/overview", heading:'Overview      ',src:OverviewIcon},
  const [paths] = useState([
    { path: "/borrower/payment-schedule", heading: 'Repayment Calendar', src: PaymentscheduleIcon },
    { path: "/borrower/add-payment", heading: 'Payment Modes', src: PaymentmethodIcon },
    { path: "/borrower/document-center", heading: 'Document Vault', src: DocumentmanagementIon },
  ]);
  let userId = sessionStorage.getItem("UserId");
  const [initialvalues, setValues] = useState({
    monthDue: 0,
    remainingBalance: 0,
    nextDuedate: null
  })
  const [show, setShow] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    getData();
    getNotification()
  }, []);
  useEffect(() => {

    console.log('socket..', socket)
    socket?.on("getNotification", (data) => {
       getNotification();
      // setNotifications((prev) => [...prev, data]);
    });
    socket?.on("refreshPage", () => {

      getNotification();

    });
  }, [socket]);

  const getData = async () => {
    await AuthGet(`users/dashboard/${userId}`, "borrower")
      .then((res) => {
        if (res["statusCode"] == 200) {
          // console.log(res.data);
          setData(res["data"]);
          sessionStorage.setItem(
            "LoanId",
            res.data["user_details"][0]?.loan_id
          );
        }
        if (res.data.next_schedule) {
          setValues({
            monthDue: res.data?.next_schedule.amount,
            remainingBalance: res.data?.next_schedule.unpaidprincipal,
            nextDuedate: res.data?.next_schedule.scheduledate
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  let emitsocket =()=>{
    console.log('working.................!')
    socket?.emit("refreshEvent", {
      user_id:user_id,
      socket_id:socket.id
   });
  }
  const getNotification = async () => {
    try {
      const resp = await AuthGet(`socket/getNotification/${user_id}`, "borrower");
      if (resp.statuscode === 200) {
        console.log(resp);
        setNotifications(resp.data);
        let unread=[]
        resp.data.filter((e)=>{
          if(e.status=='N'){
           unread.push(e)
          }

          })
          setCount(unread)
          
      }
    } catch (error) {
      console.log(error);
    }
  }; 


  let options = async (socket, type) => {
    setSelectid("");
    if(!socket){
      return
    }
    let { id, status } = socket;
    try {
      if (status == "Y" && type == "markasread") {
        return;
      }
      await AuthPost(
        `socket/notificationOption/${id}`,
        {type:type},
        "borrower"
      ).then((res) => {
        console.log("new era", res);
        if (res.statuscode == 200) {
          setSelectid("");
          emitsocket()
          setNotifications(res.data);
          let unread=[]
          res.data.filter((e)=>{
            if(e.status=='N'){
             unread.push(e)
            }
            })
            setCount(unread)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleButtonMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const displayNotification = (n) => {

    return (

      <div className={n.status=="Y"?`notificationItem AlignItemCenter notificationItem_seen`: n.priority=='L'?"notificationItem notificationItem_low AlignItemCenter ":n.priority=='H'?"notificationItem AlignItemCenter  notificationItem_imp":n.priority=='M'?"notificationItem AlignItemCenter notificationItem_med":''}>
        <div className="notificationLeft">
        <div className={n.priority=='L'?'legend_low': n.priority=='M'?'legend_medium':n.priority=='H'?'legend_important':''}></div>
        </div>
        <div className="notification_content">
         <div className="displayFlex  AlignItemCenter "> <div onClick={() => {options(n, "markasread");}} className="displayFlex  AlignItemCenter min_width337px"><h5>{n?.notification_type?n?.notification_type:'Fund disbursed'}</h5>
          <span className="notify_time">{n?.notification_time}</span></div>
          <div   onClick={() => {
              setSelectid(n.id);
            }}
            onBlur={()=>{setSelectid('')}} 
            >
        <button
          
          >
          <img style={{'margin':'10px'}} src={threedot}/>
          </button>

         <div className="threedot_popup">
         {selectid == n.id ? (
            <>
              <div
                onClick={() => {
                  options(n, "clear");
                }}
                onMouseDown={handleButtonMouseDown}
              >
                clear
              </div>
           
             {n.status!="Y"? <div
                onClick={() => {
                  options(n, "markasread");
                }}
                onMouseDown={handleButtonMouseDown}
              >
                mark as read
              </div>:<></>}
            </>
          ) : (
            ""
          )}
         </div>


        </div>
</div>
          <p className="min_width337px"
            onClick={() => {
              options(n, "markasread");
            }}
          >   
          {n.notification}
         
          </p>
          </div>
    
        
      </div>


    );
  };


  let userName = sessionStorage.getItem('UD_firstName')
  userName = userName.charAt(0).toUpperCase() + userName.slice(1) + ' ' + sessionStorage.getItem('UD_lastName')
  return (
    <div className="">
      {queryParams.pathname !== "/borrower/login" &&
        queryParams.pathname !== "/borrower/forgot-password" && (
          <div className="">
            <div className="borrowerHeader">
              <div className="displayFlex">
                <img src={menuicon} className="mobileMenuicon" />
                <div className="brand_logo">
                  <img src={Brandlogo} />
                </div>
              </div>
              <div>
                <ul className="userSetting">
                  <li className="main_modal ">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={NotificationIcon} />

                        <span className="count count_borrower">{count ? count.length : 0}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p0">
                    <div className="notify_modal">
   
                   <div className="displayFlex alignItemscenter spaceBetween mb_12 notify_modalhead borrower_notihead"> 
                    <div className="">
                      <h5 className="titleText  mb_4">
                        Notification{" "}
                        <span className="notify_count notify_count_borrower">
                          {
                           count ? count.length : 0
                          }
                          
                        </span>

                      </h5>
                      <h6 className="fs12">you have <span className='notification_count'>{count.length}</span> unread  messages</h6>
                      </div>
                <div className="displayFlex alignItemscenter">
                        <div className="fs10">
                          {/* <span> Only show unread<input type="checkbox" id="seen/unseentoggle" onClick={(e)=>{setToggle(e.target.checked)}} /></span> */}
                        Only show unread
                        </div>
                       <div>
                       <label className="switch">
                          
                          <input
                            className="switch-input"
                            type="checkbox"
                            
                            onClick={(e) => {
                              setToggle(e.target.checked);
                            }}
                          />
                          <span
                            className="switch-label"
                            // data-on="Enable"
                            // data-off="Disable"
                          >
                            {/* <img src={CloseOffIcon} /> */}
                          </span>
                          <span className="switch-handle">
                            {/* <img src={TickOnIcon} /> */}
                          </span>
                        </label>

                        </div>
                   
                    <div className="markread"  onClick={()=>{options(notifications[0],'markallasread')}}><img className="markreadicon" src={markread} /> <span className="markreadtext">Mark all as Read</span>
                    </div></div>  </div>
                    <div className="displayFlex notify_legend"> <p className="displayFlex alignItemscenter "><div className="legend_important "></div> Important</p>{' '}<p className="displayFlex alignItemscenter"><div className="legend_medium"></div> Medium</p>{' '}<p className="displayFlex alignItemscenter"><div className="legend_low"></div> Low</p></div>
                    {/* <div>
                      <button className="emptyButton">
                        <img src={MenuhorizontalIcon} />
                      </button>
                    </div> */}
                  </div>





                  {toggle ? (
                 notifications.filter((unseensnoti)=>{
                 return unseensnoti.status=="N"
                 }).map((unseen) => displayNotification(unseen))
                 ) : (
                 notifications.map((notification) => displayNotification(notification))
                )}

                         
                    
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <button className="emptyButton" onClick={() => navigate('/borrower/settings')}>
                      <img src={SettingheaderIcon} />
                    </button>
                  </li>
                  <li className="userProfile">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="displayFlex alignItemscenter">
                          <div className="mr_12">
                            <img src={UserProfilelogo} />
                          </div>
                          <div>
                            <h5>Hello {userName}!</h5>
                          </div>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>

                        <Dropdown.Item onClick={() => setShow(true)}>Profile</Dropdown.Item>

                        <Dropdown.Item onClick={() => { navigate('/borrower/settings') }}>
                          Change Password
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                          sessionStorage.clear()
                          navigate('/borrower/login')
                        }}>Logout</Dropdown.Item>
                      </Dropdown.Menu>

                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>

            <div className="borrowerLayout">
              <BorrowerSidebar />

              <main className="borrowerContentWrapper">
                <div className="borrowerMainContent">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="horizontalBorrowermenu mb_24">
                        <ul>
                          {
                            paths?.map((item, i) => {
                              return (
                                <li key={i}>
                                  <button onClick={() => navigate(item.path)}
                                    className={queryParams.pathname == item.path ? 'active' : ""}>
                                    <img src={item.src} /> <span>{item.heading}</span>
                                  </button>
                                </li>
                              )
                            })
                          }

                        </ul>
                      </div>

                      <Outlet />
                    </div>
                    <div className="col-md-2">

                      <div className="w12per positionFixed ">
                        <div className="whiteFrame padding_10 mb20px    ">
                          <div className="quickAction">
                            <h2>
                              <img src={QuickactionIcon} />
                              <span>Quick Actions</span>
                            </h2>

                            <ul>
                              <li>
                                <button onClick={() => {
                                  navigate('/borrower/add-payment')
                                }}>Quick Pay</button>
                              </li>
                              <li>
                                <button onClick={() => {
                                  navigate('/borrower/settings')
                                }}>Change Password</button>
                              </li>
                              <li>
                                <button onClick={() => {
                                  // sessionStorage.clear()
                                  openInNewTab('/')
                                }}>Check Offers</button>
                              </li>
                            </ul>

                            {/* <button className="blackButton">
                              + Add Shortcut
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="whiteFrame padding_10   ">
                          <div className="bluebg">
                            <div className="orangetext">Loan Overview</div>
                            <div className="pb15px">
                              <div className="profiletitle"> Loan amount </div>
                              <div className="profiletext">${data?.user_details[0]?.loanamount}</div>
                            </div>
                            <div className="pb15px">
                              <div className="profiletitle"> loan term </div>
                              <div className="profiletext">
                                Term: {data?.user_details[0]?.loanterm}, APR:
                                {data?.user_details[0]?.apr}%
                              </div>
                            </div>
                            <div className=" pb15px">
                              <div className="profiletitle">
                                credit expiration date
                              </div>
                              <div className="profiletext">- - - -</div>
                            </div>
                            <div className="  ">
                              <div className="profiletitle">Monthly Payment</div>
                              <div className="profiletext">${initialvalues?.monthDue}</div>
                            </div>
                          </div>


                        </div> */}

                      </div>


                    </div>

                    {show ? <div className="col-md-2">
                      <div className="profileBlock w12per">
                        <div className="pencilIcon" onClick={() => setShow(false)}>
                          <img src={closeicon} />
                        </div>
                        <div className="profileborder">
                          <div className="profileimg">

                            <img src={UserProfilelogo} />

                          </div>
                        </div>
                        <div className="clearboth"></div>
                        <div className="profileName"> {userName} </div>

                        <div className="p20px">
                          <div className="orangetext">Personal details</div>
                          <div className="pb15px">
                            <div className="profiletitle"> Email </div>
                            <div className="profiletext">
                              {data?.user_details[0]?.Email}
                            </div>
                          </div>
                          <div className="pb15px">
                            <div className="profiletitle"> Phone </div>
                            <div className="profiletext">{data?.user_details[0]?.phone}</div>
                          </div>
                          <div className=" ">
                            <div className="profiletitle"> Address </div>
                            <div className="profiletext">{data?.user_details[0]?.unit}<br />
                              {data?.user_details[0]?.street_address}<br />
                              {data?.user_details[0]?.state},<br />
                              {data?.user_details[0]?.["zip-code"]}</div>
                          </div>
                        </div>

                      </div>
                    </div> : ""}

                  </div>
                </div>
              </main>
            </div>

            {/* <div className="borrowerFooter">
              <div className="container-fluid">
                <div className="row mb_12">
                  <div className="col-md-4">
                    <div
                      className="borrowerLogo_brand"
                      style={{ marginBottom: "24px" }}
                    >
                      <img src={footerlogo} />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <div className="centerBorder"></div>

                    <ul className="socialmediaMenu">
                      <li>
                        <button>
                          <img src={FaceBookIcon} />
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src={InstaIcon} />
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src={LinkedinIcon} />
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src={TwitterIcon} />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h2>Services</h2>
                    <ul className="footerMenu">
                      <li>
                        <button onClick={() => {
                          navigate('/borrower/payment-schedule')
                        }}>
                          <img src={GreaterthanIcon} />
                          <span>Repayment Calendar</span>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => {
                          navigate('/borrower/add-payment')
                        }}
                        >
                          <img src={GreaterthanIcon} />
                          <span>Payment Modes</span>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => {
                          navigate('/borrower/document-center')
                        }}
                        >
                          <img src={GreaterthanIcon} />
                          <span>Document Vault</span>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => {
                          navigate('/borrower/add-payment')
                        }}
                        >
                          <img src={GreaterthanIcon} />
                          <span>Make a Payment</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h2>Contact Us</h2>
                    <ul className="footerMenu">
                      <li>
                        <button>
                          <img src={GreaterthanIcon} />
                          <span>contact@healththeecentral.com</span>
                        </button>
                      </li>
                      <li>
                        <button>
                          <img src={GreaterthanIcon} />

                          <span>(000) 000-0000 </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bottomFooter">
                  <ul>
                    <li>
                      <button>Terms & Conditions </button>
                    </li>
                    <li>
                      <button>Privacy Policy</button>
                    </li>
                    <li>
                      <button>California Consumer Privacy</button>
                    </li>
                    <li>
                      <button> Act Notice</button>
                    </li>
                  </ul>
                </div>

                <div className="chaticon">
                  <img src={chaticon} />


                </div>

              </div>
            </div> */}
          </div>
        )}
    </div>
  );
};

export default Borrower;
