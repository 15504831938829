import React, { useState, useRef } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";

import OverviewIcon from "../../assets/images/overview_icon.svg";
import PaymentscheduleIcon from "../../assets/images/payment_schedule.svg";
import PaymentmethodIcon from "../../assets/images/payment_method.svg";
import DocumentmanagementIon from "../../assets/images/document_management.svg";
import BorrowersettingIcon from "../../assets/images/borrower_setting.svg";
import DoubleArrowleft_icon from "../../assets/images/doubleArrowleft_icon.svg";

const BorrowerSidebar = () => {
  const navigate = useNavigate();
  const [initialState, dispatch, authState, setAuthState] = useStateValue();
  let location = useLocation()
  location = location.pathname

  // {path:"/borrower/overview", heading:'Overview',src:OverviewIcon},
  const [paths] = useState([
    { path: "/borrower/payment-schedule", heading: 'Repayment Calendar', src: PaymentscheduleIcon },
    { path: "/borrower/add-payment", heading: 'Payment Modes', src: PaymentmethodIcon },
    { path: "/borrower/document-center", heading: 'Document Vault', src: DocumentmanagementIon },
    // { path: "/borrower/settings", heading: 'Settings', src: BorrowersettingIcon },
  ]);



  return (
    <>
      <div className="borrowerSidebar">
        <div className="sidebarTitle"> Customer Portal</div>
        <ul>
          {
            paths?.map((item, i) => {
              return (
                <li key={i}>
                  <button
                    onClick={() =>
                      navigate(item.path)
                    }
                    className={location == item.path ? 'active' : ""}
                  >
                    <img src={item.src} className="mr_12" />
                    <span>{item.heading}</span>
                  </button>
                </li>
              )
            })
          }

          {/* <li>
            <button
              onClick={() => {
                setAuthState(null);
                sessionStorage.clear();
                navigate("/borrower/login");
              }}
            >
              <div className="iconBlock">
                <div className="logoutIcon" />
              </div>
              <span>Logout</span>
            </button>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default BorrowerSidebar;
