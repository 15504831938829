import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import { useStateValue } from "../../Context/StateProvider";
import SelectLoanAmount from "../../Templates/SelectLoanAmount/SelectLoanAmount";
import StartApplication from "../StartApplication/StartApplication";

const StartPage = () => {
  const [{ emailPhonePopup, selectLoanPopup }, dispatch] = useStateValue();

  return (
    <>
      {selectLoanPopup && <SelectLoanAmount />}
      {emailPhonePopup && <StartApplication />}
    </>
  );
};

export default StartPage;
