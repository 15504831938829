import { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { PasswordRegex } from "../Regex/Regex";

const PasswordValidator = ({ name, value, isTouched, labelName }) => {
  const [{ formData, formSubmitted }, dispatch] = useStateValue();

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value) {
        setMessage(`${labelName} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (!PasswordRegex.test(value)) {
        setMessage("Invalid Password");
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: "Invalid" },
        });
      } else if (formData.newpw && formData.confirmpw ? formData.newpw !== formData.confirmpw : null) {
        setMessage("Password Mismatch");
        dispatch({
          type: "SET_ERRORS",
          payload: { newpw: true, confirmpw: "Mismatch" },
        });
      } else if (formData.newpw && formData.confirmpw ? formData.newpw === formData.confirmpw : null) {
        dispatch({
          type: "CLEAR_ERRORS",
        });
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>
};

export default PasswordValidator;
