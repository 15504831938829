import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import PasswordValidator from "./PasswordValidator";
import EyehideIcon from "../../assets/images/eye_hide.svg";
import EyeshowIcon from "../../assets/images/eye_show.svg";

const Password = ({
  name,
  labelName,
  maxLen,
  minLen,
  placeholder,
  handleChange,
  required,
}) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")

  return (
    <div>
      {errors[name] === "is required" || "Mismatch" ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : errors[name] === "Invalid" ? (
        <label htmlFor={name} className="labelText">
          <span className="requiredText">{errors[name]}</span> {labelName}
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <div className="Groupinput">
        <input
          type={passwordVisibilty}
          name={name}
          id={name}
          className="MainInput"
          maxLength={16}
          minLength={minLen}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={() => setIsTouched(true)}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
        />
        <div class="inputgroup_append">
            <span class="Groupinput_text">
              <img
                src={passwordVisibilty == "password" ? EyehideIcon : EyeshowIcon}
                onClick={() => {
                  passwordVisibilty == "password"
                    ? setPasswordVisibilty("text")
                    : setPasswordVisibilty("password");
                }}
              />
            </span>
          </div>
      </div>
      {required ? (
        <div style={{ display: "none" }}>
          <PasswordValidator
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Password;
