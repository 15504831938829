import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Toast from "../../Components/Toaster/Toast";

import toast from "react-hot-toast";
import { useStateValue } from "../../Context/StateProvider";
import PlaidBankConnect from "../../Components/BankLookupTab/PlaidBankConnect";
import OtherBankConnect from "../../Components/BankLookupTab/OtherBankConnect";

const BankAccount = ({ userData }) => {
  const [activeBar, setActiveBar] = useState("userInfo");
  const [borrowerbankAcc, setBorrowerBankAcc] = useState([]);
  const [coborrowerbankAcc, setCoBorrowerBankAcc] = useState([]);
  const [bankAcc, setBankAcc] = useState([]);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [selectedBank, setSelectedBank] = useState([]);
  const [isPlaid, setIsPlaid] = useState(false);
  const [showBankDetail, setShowBankDetail] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState({
    Borrowerstatus: false,
    CoBorrowerstatus: false,
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [showCurrentAcc, setShowCurrentAcc] = useState(false);
  const [showHistoricalAcc, setShowHistoricalAcc] = useState(false);
  const [showCheckingAccData, setShowCheckingAccData] = useState(false);
  const [showSavingsAccData, setShowSavingsAccData] = useState(false);
  const [showHisCheckingAccData, setShowHisCheckingAccData] = useState(false);
  const [showHisSavingsAccData, setShowHisSavingsAccData] = useState(false);
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];

  const [pageChecking, setPageChecking] = useState(1);
  const [currentCheckingPage, setCurrentCheckingPage] = useState(1);

  const [pageSaving, setPageSaving] = useState(1);
  const [currentSavingPage, setCurrentSavingPage] = useState(1);

  const [pageHistoryChecking, setPageHistoryChecking] = useState(1);
  const [currentHistCheckingPage, setCurrentHistCheckingPage] = useState(1);

  const [pageHistorySaving, setPageHistorySaving] = useState(1);
  const [currentHistSavingPage, setCurrentHistSavingPage] = useState(1);

  const [borHistoryAcc, setBorHistoryAcc] = useState([]);
  const [coBorHistoryAcc, setCoBorHistoryAcc] = useState([]);
  const [historyAccNo, setHistoryAccNo] = useState([]);
  const [{ currency }, dispatch] = useStateValue();

  const [toolkitTransactions, setToolkitTransactions] = useState([]);

  const [showManualAcc, setShowManualAcc] = useState(false);

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    await AuthGet(`plaid/fetch-User-Info/${loanId}`, "admin").then((res) => {
      if (res["statusCode"] == 200) {
        setBankAcc(res.data);
      }
    });

    await AuthGet(`plaid/fetch-accounts/${loanId}`, "admin").then((res) => {
      if (res["statusCode"] == 200) {
        if (res.data.length && res.isPlaid) {
          Object.values(res.data).map((el) => {
            if (el.borrower_bankAccounts) {
              setBorrowerBankAcc(el?.borrower_bankAccounts);

            } else {
              setCoBorrowerBankAcc(el?.co_bankAccounts);
            }
          });
          setIsPlaid(res.isPlaid);
        } else {
          Object.values(res.data).map((el) => {
            if (el.borrower_bankAccounts) {
              setBorrowerBankAcc(el?.borrower_bankAccounts);
            } else {
              setCoBorrowerBankAcc(el?.co_bankAccounts);
            }
          });
          setIsPlaid(res.isPlaid);
        }
      }
    });

    await AuthGet(`plaid/fetch-historicalBalance/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setHistoryAccNo(res.acno);
          setBorHistoryAcc(res?.borrower);
          setCoBorHistoryAcc(res?.co_borrower);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setShowBankDetails(true);
  };
  const handleBankData = async (id, name, email, phone, address, role) => {
    if (role === "B") {
      if (borrowerbankAcc.length > 0) {
        let currentBank = borrowerbankAcc?.filter((el) => el?.user_id === id);
        setSelectedBank(currentBank);
        setShowBankDetail(true);
        setShowUserDetail({
          Borrowerstatus: !showUserDetail.Borrowerstatus,
          name,
          email,
          phone,
          address,
        });
      }
    } else {
      if (coborrowerbankAcc.length > 0) {
        let currentBank = coborrowerbankAcc?.filter((el) => el?.user_id === id);
        console.log("Current bank: ", currentBank);
        setSelectedBank(currentBank);
        setShowBankDetail(true);
        setShowUserDetail({
          CoBorrowerstatus: !showUserDetail.CoBorrowerstatus,
          name,
          email,
          phone,
          address,
        });
      }
    }
  };

  const handleManualBankData = async () => {
    setShowManualAcc((p) => !p);
  };

  const handleUserInfo = () => {
    // setShowUserDetail({ ...showUserDetail, status: true });
    setShowCurrentAcc(false);
    setShowHistoricalAcc(false);
    setActiveBar("userInfo");
  };

  const handleCurrentAcc = (role) => {
    // setShowUserDetail({ ...showUserDetail, status: true });
    if (role === "B") {
      setShowCurrentAcc(true);
      setShowHistoricalAcc(false);
      setCurrentCheckingPage(1);
      setCurrentSavingPage(1);
      setCurrentHistCheckingPage(1);
      setCurrentHistSavingPage(1);
      setActiveBar("currentAcc");
    } else {
      setShowCurrentAcc(true);
      setShowHistoricalAcc(false);
      setCurrentCheckingPage(1);
      setCurrentSavingPage(1);
      setCurrentHistCheckingPage(1);
      setCurrentHistSavingPage(1);
      setActiveBar("currentAcc");
    }
  };
  const handleHistoricInfo = () => {
    // setShowUserDetail({ ...showUserDetail, status: true });
    setShowCurrentAcc(false);
    setShowHistoricalAcc(true);
    setCurrentCheckingPage(1);
    setCurrentSavingPage(1);
    setCurrentHistCheckingPage(1);
    setCurrentHistSavingPage(1);
    setActiveBar("historicInfo");
  };
  const handleSavingsData = (el) => {
    if (el.subtype === "saving") {
      setShowSavingsAccData((p) => !p);
      setToolkitTransactions(el.transactions);
    } else if (el.subtype === "checking") {
      setToolkitTransactions(el.transactions);
      setShowCheckingAccData((p) => !p);
    }
  };
  const handleCheckingData = () => setShowCheckingAccData((p) => !p);

  const handleHistoricCheckingData = () => setShowHisCheckingAccData((p) => !p);
  const handleHistoricSavingData = () => setShowHisSavingsAccData((p) => !p);

  const handleSendBankEmail = async () => {
    await AuthGet(`plaid/connect-bank/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          toast.success("Mail sent successfully");
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: "Mail send successfully",
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //current account table
  const columnsCurrentChecking = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentCheckingPage * 10;
        total = total - 10;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "name",
      text: "Transaction Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "category_id",
      text: "Category ID",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount_bal",
      text: "Amount",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cell) => {
        return `${currency} ${cell}`;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "date_format",
      text: "Date",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultCurrentCheckingSorted = [
    // {
    //     dataField: "date_format",
    //     order: "desc",
    // },
    // {
    //     dataField: "name",
    //     order: "desc",
    // },
    // {
    //     dataField: "category_id",
    //     order: "desc",
    // },
    // {
    //     dataField: "category",
    //     order: "desc",
    // },
    // {
    //     dataField: "amount_bal",
    //     order: "desc",
    // },
  ];

  const paginationCurrentChecking = paginationFactory({
    page: pageChecking,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentCheckingPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const columnsCurrentSaving = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentSavingPage * 10;
        total = total - 10;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "name",
      text: "Transaction Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "category_id",
      text: "Category ID",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount_bal",
      text: "Amount",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "date_format",
      text: "Date",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultCurrentSavingSorted = [
    // {
    //     dataField: "date_format",
    //     order: "desc",
    // },
    // {
    //     dataField: "name",
    //     order: "desc",
    // },
    // {
    //     dataField: "category_id",
    //     order: "desc",
    // },
    // {
    //     dataField: "category",
    //     order: "desc",
    // },
    // {
    //     dataField: "amount_bal",
    //     order: "desc",
    // },
  ];

  const paginationCurrentSaving = paginationFactory({
    page: pageSaving,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentSavingPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //history account table
  const columnsHistoryChecking = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentHistCheckingPage * 10;
        total = total - 10;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "acno",
      text: "Account No",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "wire_routing",
      text: "Routing",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultSortedHistoryChecking = [
    // {
    //     dataField: "date",
    //     order: "desc",
    // },
    // {
    //     dataField: "acno",
    //     order: "desc",
    // },
    // {
    //     dataField: "wire_routing",
    //     order: "desc",
    // },
    // {
    //     dataField: "currency",
    //     order: "desc",
    // },
    // {
    //     dataField: "amount",
    //     order: "desc",
    // },
  ];

  const paginationHistoryChecking = paginationFactory({
    page: pageHistoryChecking,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentHistCheckingPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const columnsHistorySaving = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentHistSavingPage * 10;
        total = total - 10;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "acno",
      text: "Account No",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "wire_routing",
      text: "Routing",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultSortedHistorySaving = [
    // {
    //     dataField: "date",
    //     order: "desc",
    // },
    // {
    //     dataField: "acno",
    //     order: "desc",
    // },
    // {
    //     dataField: "wire_routing",
    //     order: "desc",
    // },
    // {
    //     dataField: "currency",
    //     order: "desc",
    // },
    // {
    //     dataField: "amount",
    //     order: "desc",
    // },
  ];

  const paginationHistorySaving = paginationFactory({
    page: pageHistorySaving,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
      setCurrentHistSavingPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //current account table
  const columnsManualBank = [
    {
      dataField: "",
      text: "S.NO",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        // let total = currentCheckingPage * 10;
        // total = total - 10;
        let slNo = columnIndex + 1;
        // console.log("Total: ", total);
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "bankname",
      text: "Bank Name",
      // sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "holdername",
      text: "Bank Holder Name",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "accountnumber",
      text: "Account Number",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "routingnumber",
      text: "Routing Number",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "active_flag",
      text: "Status",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cell) => {
        if (cell === "Y") {
          return `Active`;
        } else {
          return `Inactive`;
        }
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "createdat",
      text: "Created At",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultManualBankSorted = [];

  // const paginationCurrentChecking = paginationFactory({
  //     page: pageChecking,
  //     sizePerPage: 10,
  //     lastPageText: ">>",
  //     firstPageText: "<<",
  //     nextPageText: ">",
  //     prePageText: "<",
  //     showTotal: true,
  //     alwaysShowAllBtns: true,
  //     onPageChange: function (page, sizePerPage) {
  //         console.log("page", page);
  //         console.log("sizePerPage", sizePerPage);
  //         setCurrentCheckingPage(page);
  //     },
  //     onSizePerPageChange: function (page, sizePerPage) {
  //         console.log("page", page);
  //         console.log("sizePerPage", sizePerPage);
  //     },
  // });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <div>
      <Toast />
      {/* Bank component conditional rendering for plaid and other banks third parties */}
      {isPlaid ? (
        <PlaidBankConnect
          toolkitTransactions={toolkitTransactions}
          showBankDetails={showBankDetails}
          bankAcc={bankAcc}
          isPlaid={isPlaid}
          handleBankData={handleBankData}
          borrowerbankAcc={borrowerbankAcc}
          setShowBankDetail={setShowBankDetail}
          setShowUserDetail={setShowUserDetail}
          showUserDetail={showUserDetail}
          handleSendBankEmail={handleSendBankEmail}
          showBankDetail={showBankDetail}
          handleUserInfo={handleUserInfo}
          activeBar={activeBar}
          handleCurrentAcc={handleCurrentAcc}
          handleHistoricInfo={handleHistoricInfo}
          showCurrentAcc={showCurrentAcc}
          showHistoricalAcc={showHistoricalAcc}
          handleCheckingData={handleCheckingData}
          selectedBank={selectedBank}
          showCheckingAccData={showCheckingAccData}
          currency={currency}
          columnsCurrentChecking={columnsCurrentChecking}
          defaultCurrentCheckingSorted={defaultCurrentCheckingSorted}
          paginationCurrentChecking={paginationCurrentChecking}
          handleSavingsData={handleSavingsData}
          showSavingsAccData={showSavingsAccData}
          columnsCurrentSaving={columnsCurrentSaving}
          paginationCurrentSaving={paginationCurrentSaving}
          defaultCurrentSavingSorted={defaultCurrentSavingSorted}
          borHistoryAcc={borHistoryAcc}
          handleHistoricCheckingData={handleHistoricCheckingData}
          showHisCheckingAccData={showHisCheckingAccData}
          columnsHistoryChecking={columnsHistoryChecking}
          defaultSortedHistoryChecking={defaultSortedHistoryChecking}
          paginationHistoryChecking={paginationHistoryChecking}
          handleHistoricSavingData={handleHistoricSavingData}
          showHisSavingsAccData={showHisSavingsAccData}
          columnsHistorySaving={columnsHistorySaving}
          defaultSortedHistorySaving={defaultSortedHistorySaving}
          paginationHistorySaving={paginationHistorySaving}
          handleManualBankData={handleManualBankData}
          showManualAcc={showManualAcc}
          userData={userData}
          columnsManualBank={columnsManualBank}
          defaultManualBankSorted={defaultManualBankSorted}
          coborrowerbankAcc={coborrowerbankAcc}
          coBorHistoryAcc={coBorHistoryAcc}
        />
      ) : (
        <OtherBankConnect
          showBankDetails={showBankDetails}
          borrowerbankAcc={borrowerbankAcc}
          setShowBankDetail={setShowBankDetail}
          setShowUserDetail={setShowUserDetail}
          showUserDetail={showUserDetail}
          handleSendBankEmail={handleSendBankEmail}
          showBankDetail={showBankDetail}
          activeBar={activeBar}
          handleCurrentAcc={handleCurrentAcc}
          showCurrentAcc={showCurrentAcc}
          showHistoricalAcc={showHistoricalAcc}
          currency={currency}
          columnsCurrentChecking={columnsCurrentChecking}
          defaultCurrentCheckingSorted={defaultCurrentCheckingSorted}
          paginationCurrentChecking={paginationCurrentChecking}
          handleManualBankData={handleManualBankData}
          showManualAcc={showManualAcc}
          userData={userData}
          columnsManualBank={columnsManualBank}
          defaultManualBankSorted={defaultManualBankSorted}
          coborrowerbankAcc={coborrowerbankAcc}
        />
      )}
    </div>
  );
};

export default BankAccount;
