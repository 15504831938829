import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Form, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";

const Milestone1 = () => {
    const { id } = useParams();
    const [userid, setUserid] = useState("");
    const [files, setfiles] = useState([]);
    const [data, setdata] = useState([]);
    const [{currency}] = useStateValue();

    // const navigate = useNavigate();
    // const queryParams = useLocation();
    // const loanParams = queryParams.pathname.slice(7).split("/")[0];
    // const loanStatus =
    //     loanParams.charAt(0).toUpperCase() + loanParams.slice(1).split("/")[0];
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData()
        setUserid(sessionStorage.getItem("UserId"))
    }, []);


    const fetchData = async () => {
        let res = await AuthGet(`funded-contracts/getdetails/milestone1/${id}`, "admin");

        if (res.statusCode == 200) {
            setfiles(res?.data?.milestoneReqFiles);
            setdata(res.data)
        }
    }

    const viewDoc = (filename) => {
        console.log(filename);
        // console.log("Admin key: ", key);
        if (filename) {
            filename = filename?.split("/");
            filename = filename[filename?.length - 1];
            filename = `${filename}`;

            window.open(process.env.REACT_APP_ADMIN_API_URL + "/files/save/" + filename, "_blank");
        }
    };

    const columns = [
        {
            dataField: "",
            text: "#",
            sort: true,
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
             style: {
      cursor: 'pointer',
    },
        },
        { dataField: "originalname", text: "Documents" },
        {
            dataField: "filename", text: "PDF,JPG,JPEG,PNG", formatter: (cell) => {
                return "View ";
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    viewDoc(row?.filename);
                },
            },
             style: {
      cursor: 'pointer',
    },
        },
    ];

    const columns2 = [
        {
            dataField: "",
            text: "#",
            sort: true,
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
        },

        { dataField: "comments", text: "Comments", classes :'wordbreak' },
        {
            dataField: "role",
            text: "Created By",
            formatter: (cell, row) => {
                return row.role + " : " + row?.firstname + row?.lastname;
            },
        },
        { dataField: "createdat", text: "Created Date" },
    ];

    const milestone_pay1 = async () => {

        try {
            // let userid = sessionStorage.getItem('UserId');
            let res = await AuthPost(
                `funded-contracts/release/fund/milestone1/${id}`, {},
                "admin"
            );

            if (res.statusCode == 200) {
                toast.success("Approved Milestone 1")
                fetchData()
                // navigate(`/admin/funded-contracts`)
            }
            else if (res.statusCode == 400) {
                toast.error(res.message[0]);
            }

            else {
                toast.error('Error')
            }


        } catch (error) {
            toast.error('Error')
        }

    };

    const addComments = useFormik({
        initialValues: {
            commentType: "Milestone 1",
            comments: "",
            loan_id: id,
            user_id: userid,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            comments: Yup.string().required("required"),
            commentType: Yup.string().required("required"),
            loan_id: Yup.string().required("required"),
            user_id: Yup.string().required("required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                let post = await AuthPost(`funded-contracts/addComment`, values, "admin");
                if (post.statusCode == 200) {
                    toast.success('Comment Added')
                    resetForm();
                    // get();
                    fetchData()

                }
                else {
                    toast.error('Error')
                }
            } catch (error) {
                toast.error('Error')
                console.error("error", error);
            }
        },
    });

    const colTransaction = [
        { dataField: 'id', text: 'TransactionId', sort: true },
        { dataField: 'direction', text: 'Direction', sort: true },
        { dataField: 'typeOfPayment', text: 'Payment type', sort: true },
        { dataField:  `amount`, text: 'Amount', sort: true,formatter: (cell) => {
            return currency  + cell;
        }, },
        { dataField: 'createdat', text: 'Date', sort: true },
    ];

    return (

        data?.installerMilestones?.length > 0 ?
            <div>
                <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
                <div className="frameshadow">
                    <div className="mainTitle mb_15 ">Proof Of Ownership Documents</div>
                    <div className="milestonetable">
                        <BootstrapTable  keyField="id" data={files} columns={columns} />
                    </div>

                    <div className="milestoneBtns_div mb_15 fs18px">


                        Milestone 1 Requested Amount : {`${currency}`}{" "}
                        {data?.installerMilestones?.[0]?.milestoneReqAmount} {" "} {data?.milestoneFundPer?.[0]?.value ? <small> ({data?.milestoneFundPer?.[0]?.value}% of the loan amount)</small> : ''}
                    </div>
                   
                    {(data.MilestoneTransactions?.length == 0
                    ) && (
                            <button className="MainButton aareRiverBrienz_Clr mb_15" onClick={milestone_pay1}>Approve</button>
                        )}
                    <div className="mainTitle mt_10"> Comments Details</div>



                    {data?.MilestoneComments?.length > 0 && (
                        <div className="p10 milestonetable">
                            <BootstrapTable
                                keyField="id"
                                data={data?.MilestoneComments}
                                columns={columns2}
                            />
                        </div>
                    )}

                    <div className="ptb_15">
                        <form onSubmit={addComments.handleSubmit}>
                            <label>Comments</label> <br></br>
                            <textarea
                                type="text"
                                className="input_form width_100per mb_15"
                                placeholder="Comments"
                                id="comments"
                                name="comments"
                                onChange={addComments.handleChange}
                                onBlur={addComments.handleBlur}
                                value={addComments.values.comments}
                            />
                            {addComments.touched.comments && addComments.errors.comments ? (
                                <p className="error_text">{addComments.errors.comments}</p>
                            ) : null}
                            <button className="milestoneBtns" type="submit">Add Comments</button>
                        </form>
                    </div>




                  

                    {data?.MilestoneTransactions?.length > 0 && (
                        <div className="p10">
                            <div className="mainTitle mb_15">Transaction</div>

                            <div className="installerTablewrapper milestonetable">
                                <BootstrapTable
                                    keyField="id"
                                    data={data?.MilestoneTransactions}
                                    columns={colTransaction}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div> :
            <div>
                <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
                <div className="frameshadow">
                    <p>Waiting for installer request</p>
                </div>
            </div>


    );
};

export default Milestone1;
