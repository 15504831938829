import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AuthGet } from "../../common_var/httpService";
import { datePipe } from "../CommonFunctions/Validations";

const Transactions = () => {
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const InstallerUserId = JSON.parse(sessionStorage.getItem("resuser"));
  console.log("InstallerUserId::: ", InstallerUserId);

  useEffect(() => {
    getPracticeList();
  }, []);

  const getPracticeList = async () => {
    await AuthGet(
      `installer/transactionlist/${InstallerUserId?.maininstallerid}`,
      "admin"
    )
      .then((res) => {
        if (res["statusCode"] == 200) {
          // toast.success("Mail sent successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        return slNo;
      },
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "loan_id1",
      text: "Loan",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "payment",
      text: "Payment",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "TransactionId",
      text: "TransactionId",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "accountmethod",
      text: "Method",
      sort: true,
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "Message",
      text: "Message",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "updatedAt",
      text: "Date",
      sort: true,
      formatter: (e) => {
        return datePipe(e);
      },
      // createdAt
      headerStyle: {
        width: "150px",
      },
    },
  ];

  const defaultSorted = [
    { dataField: "loan_id1", order: "desc" },
    { dataField: "payment", order: "desc" },
    { dataField: "TransactionId", order: "desc" },
    { dataField: "accountmethod", order: "desc" },
    { dataField: "Message", order: "desc" },
    { dataField: "amount", order: "desc" },
    { dataField: "updatedAt", order: "desc" },
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <div className="frameWhite">
      <h4 className="fontWeight600 heading18 mb_12">All Transactions</h4>

      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={loanData}
        columns={columns}
        search
      >
        {(props) => (
          <div className="installerTablewrapper p16">
            <SearchBar {...props.searchProps} />
            <ClearSearchButton {...props.searchProps} />
            <hr />
            <BootstrapTable
              bootstrap4
              key="loan_ref"
              defaultSorted={defaultSorted}
              pagination={pagination}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default Transactions;
