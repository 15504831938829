import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AuthGet } from '../../common_var/httpService';
import { Toaster } from 'react-hot-toast';






function ProjectCompleted() {
    const { id } = useParams()
    const [data, setdata] = useState([]);

    useEffect(() => {
        fetchData()

    }, []);

    const fetchData = async () => {
        let res = await AuthGet(`funded-contracts/getinstallinginfodetails/${id}`, "admin");

        if (res.statusCode == 200) {

            setdata(res.data)
        }
    }


    return (


        <div>
            <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
            <div className="frameshadow">
                <div className="p16">
                    <div class="fontWeight500 heading18">
                        Application approved at : {" "}
                        <span className="fontWeight400">
                            {data?.[0]?.approvedAt}
                        </span>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ProjectCompleted