import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import "./Dashboard.scss";
import uparrow from "../../assets/images/uparrow.svg";
import opportunitiesicon from "../../assets/images/opportunities-icon.svg";
import rightarrowicon from "../../assets/images/right-arrow.svg";
import calcicon from "../../assets/images/cal-icon.svg";
import { AuthGet } from "../../common_var/httpService";
import LoanStagetable from "../../Components/LoanTracker/LoanStagetable";
import LoanStageStatus from "../../Components/LoanTracker/LoanStageStatus";
import dropdownicon from "../../assets/images/dropdown_icon.svg";

const Dashboard = () => {
  const queryParams = useLocation();
  const page = queryParams.pathname.split("/");

  const initial = [
    {
      heading: () => "Date",
      cell: (row) => {
        return row["app_date"];
      },
      hidden: false,
      width: "130",
      id: 1,
    },
    {
      heading: () => "Loan Ref",
      cell: (row) => row["ref_no"],
      hidden: false,
      width: "150",
      id: 2,
    },
    {
      heading: () => "Borrower",
      cell: (row) =>
        row["firstname"] === null
          ? "NA"
          : row["firstname"] +
            " " +
            row["lastname"],
            // " ( " +
            // row["vehicle"] +
            // " ) ",
      hidden: false,
      width: "120",
      id: 3,
    },
    {
      heading: () => "Net Check (Monthly Payment)",
      cell: (row) =>
      !row["purchase_price"]
      ? "NA"
      : initialState?.currency
      ? initialState?.currency + row["purchase_price"]
      : row["purchase_price"] +
        ` ( ${
          initialState?.currency
            ? initialState?.currency + (row["monthlyamount"] || "NA") 
            : row["monthlyamount"] || "NA"
        } ) `,
      

      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "Installer",
      cell: (row) => row["practicename"] ?? "",
      hidden: false,
      width: "150",
      id: 5,
    },
    {
      heading: () => "Status",
      cell: (row) => {
        return (
          <>
            <td>
              <div className="status_percent">
                <div
                  className={
                    row?.loan_status === "completed"
                      ? "pendingprogress completeprogress"
                      : "pendingprogress"
                  }
                >
                  {row?.loan_status === "approvedcontract"
                    ? "APPROVED"
                    : row?.loan_status === "fundingcontract"
                    ? "FUND DISPERSED"
                    : row?.loan_status.toUpperCase()}
                </div>
              </div>
            </td>
            <td>
              <div
                onClick={() => handleDropDown(row)}
                className={
                  row?.isDrop
                    ? "cursorpointer active_dropdown"
                    : "cursorpointer"
                }
              >
                <img src={dropdownicon} />
              </div>
            </td>
          </>
        );
      },
      hidden: false,
      width: "150",
      id: 6,
    },
    {
      heading: () => "Address",
      cell: (row) => row["address"],
      hidden: true,
      width: "150",
      id: 7,
    },
    {
      heading: () => "Country",
      cell: (row) => row["country"],
      hidden: true,
      width: "100",
      id: 8,
    },
    {
      heading: () => "State",
      cell: (row) => row["state"],
      hidden: true,
      width: "100",
      id: 9,
    },
    {
      heading: () => "City",
      cell: (row) => row["city"],
      hidden: true,
      width: "100",
      id: 10,
    },
    {
      heading: () => "Zip Code",
      cell: (row) => row["zipcode"],
      hidden: true,
      width: "60",
      id: 11,
    },
  ];
  const navigate = useNavigate();
  const [initialState] = useStateValue();
  const [data, setData] = useState({});
  const [toggle, setToggle] = useState("App");
  const [users, setUsers] = useState();
  const [column, setColumn] = useState(initial);
  const [app, setApp] = useState([]);
  const [fund, setFund] = useState([]);

  const role = JSON.parse(sessionStorage.getItem("resuser")).role;

  const getlist = async () => {
    await AuthGet(`heads-up-display`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          let installer = false;
          if (role === 4 || role === 5 || role === 6) {
            installer = true;
          }
          setData(res?.data);
          if (installer) {
            setUsers(res?.loanStatusdata?.milestones);
            setApp(res?.loanStatusdata?.milestones);
            setFund(res?.loanStatusdata?.funds);
          } else {
            setUsers(res?.loanStatusdata?.pending);
            setApp(res?.loanStatusdata?.pending);
            setFund(res?.loanStatusdata?.deals);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWorkflow = async () => {
    await AuthGet(`heads-up-display/fetch-steps`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          // setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropDown = (data) => {
    const updatedUsers = users?.map((user) => {
      if (user?.loan_id === data?.loan_id) {
        return {
          ...user,
          isDrop: !data?.isDrop, // Toggle the value of isDrop
        };
      } else {
        return {
          ...user,
          isDrop: false, // Set isDrop to false for other objects
        };
      }
    });
    setUsers(updatedUsers);
  };

  const handleDealChange = (type) => {
    if (type === "App") {
      setUsers(app);
      setToggle("App");
    } else if (type === "Fund") {
      setUsers(fund);
      setToggle("Fund");
    }
  };
  useEffect(() => {
    getlist();
    getWorkflow();
    setUsers(app);
  }, []);
  useEffect(() => {
    setColumn(initial);
  }, [users]);

  return (
    <>
      <div className="breadcrumbsection">
        <div>
          <div className="titleText ">Heads Up Display</div>
        </div>
      </div>

      <section className="dashboard">
        <div className="row mb_15">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 w_100tabview">
                <div
                  className="dashboardcard"
                  onClick={() => navigate("/admin/milestones")}
                >
                  <div className="displayFlex   AlignItemCenter">
                    <div className="dashboardcard-icon">
                      <img src={opportunitiesicon} className="w30px" />
                    </div>
                    <div className="cardblock">
                      <div className="displayFlex spaceBetween AlignItemCenter">
                        <div className="cardtitle">Milestone </div>
                        <div className="ribbonlabel ribbonone"></div>
                      </div>
                      <div>
                        <div className="cardtext">
                          <div className="pr10">
                            {initialState.currency}
                            {data?.milestoneSum === ".00"
                              ? "0.00"
                              : data.milestoneSum}
                          </div>
                          <div>
                            <img src={uparrow} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="displayFlex spaceBetween AlignItemCenter viewall">
                    <div className="textwhite">View All</div>
                    <div>
                      <img src={rightarrowicon} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 w_100tabview">
                <div
                  className="dashboardcard"
                  onClick={() => navigate("/admin/approved")}
                >
                  <div className="displayFlex   AlignItemCenter">
                    <div className="dashboardcard-icon">
                      <img src={opportunitiesicon} className="w30px" />
                    </div>
                    <div className="cardblock">
                      <div className="displayFlex spaceBetween AlignItemCenter">
                        <div className="cardtitle">DEALS </div>
                        <div className="ribbonlabel ribbontwo"></div>
                      </div>
                      <div>
                        <div className="cardtext">
                          <div className="pr10">
                            {initialState.currency}
                            {data?.approvedSum === ".00"
                              ? "0.00"
                              : data.approvedSum}
                          </div>
                          <div>
                            <img src={uparrow} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="displayFlex spaceBetween AlignItemCenter viewall">
                    <div className="textwhite">View All</div>
                    <div>
                      <img src={rightarrowicon} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 w_100tabview">
                <div
                  className="dashboardcard"
                  onClick={() => navigate("/admin/fundingcontract")}
                >
                  <div className="displayFlex   AlignItemCenter">
                    <div className="dashboardcard-icon">
                      <img src={opportunitiesicon} className="w30px" />
                    </div>
                    <div className="cardblock">
                      <div className="displayFlex spaceBetween AlignItemCenter">
                        <div className="cardtitle">Disbursed </div>
                        <div className="ribbonlabel ribbonthree"></div>
                      </div>
                      <div>
                        <div className="cardtext">
                          <div className="pr10">
                            {initialState.currency}
                            {data?.fundingContSum === ".00"
                              ? "0.00"
                              : data.fundingContSum}
                          </div>
                          <div>
                            <img src={uparrow} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="displayFlex spaceBetween AlignItemCenter viewall">
                    <div className="textwhite">View All</div>
                    <div>
                      <img src={rightarrowicon} />
                    </div>
                  </div>
                </div>
              </div>
              {/* new loan stage compoenent */}

              {/* table */}
              <LoanStagetable
                row={users}
                column={column}
                handleDealChange={handleDealChange}
                toggle={toggle}
                page={page[2]}
                handleDropDown={handleDropDown}
                // totalCount={totalCount}
                // itemsPerPage={itemsPerPage}
                // setItemsPerPage={setItemsPerPage}
                // currentPage={currentPage}
                // setCurrentPage={setCurrentPage}
                // searchKey={searchKey}
                // setSearchKey={setSearchKey}
                // isFilterHeader={true}
                // filterOptions={filterOptions}
                // handleFilterSelection={handleFilterSelection}
                // filterDefaultValue={filterDefaultValue}
                // handleExport={handleOwnersExport}
                // isExport
              />

              {/* table-full-view-content */}

              {/* <div className="col-md-5">
                <div className="whitebg chartBlock minHeight360 minHeight190">
                  <div className="charttitle"> Statewise </div>
                  <img src={piechart} className="" />
                </div>
              </div>

              <div className="col-md-7">
                <div className="whitebg chartBlock minHeight360 minHeight190">
                  <div className="charttitle"> Monthly Activity Report</div>
                  <img src={chartImage} className="width100per" />
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-md-3">
            <div className="whitebg">
              <div className="recentactivity">
                <div className="recenttitle">Recent Activity</div>
                {data?.recentActivity?.length > 0 ? (
                  <div>
                    {data?.recentActivity?.map((activity, index) => {
                      return (
                        <div className="recentblock">
                          <div className="displayFlex">
                            <div className="calc-icon">
                              <img src={calcicon} />
                            </div>
                            <div key={index} className="recenttext">
                              <div className="fs12px wordbreak font_Weight_bold">
                                {activity?.email}
                              </div>
                              <div className="fs12px">{activity?.message}</div>
                              <div className="fontsize12">
                                {activity?.createdat}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>No recent activities</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
