import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { EmailRegex } from "../Regex/Regex";

const EmailValidator = ({ name, value, isTouched, labelName, required }) => {
  const [{ formData, formSubmitted }, dispatch] = useStateValue();

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value?.trim() && required) {
        setMessage(`${labelName} is required`);
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (value?.length > 0) {
        if (!EmailRegex.test(String(value).toLowerCase()) && value) {
          setMessage("Invalid Email");
          dispatch({
            type: "SET_ERRORS",
            payload: { [name]: "Invalid" },
          });
        }
        else {
          setMessage("");
          dispatch({
            type: "REMOVE_ERROR",
            payload: name,
          });
        }
      } else {
        setMessage("");
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);
  return <>{message ? <p className="requiredText">{message}</p> : <></>}</>;
};

export default EmailValidator;
