import React from "react";

function MakePayment() {
  return (
    <>
      <div className="body_content">
        <h4 className="SemiBold_14 mb_16">To Make a Payment</h4>

        <a
          className="btn btnGreen mb_30"
          target="_blank"
          href="https://mhv2.theecentral.com/"
        >
          Click here to manage Your account
        </a>
        <p className="para_text pb_15">
          You can also{" "}
          <a href="https://mhv2.theecentral.com/" target="_blank">
            click here{" "}
          </a>
          to have your payment instructions.
        </p>
        <p className="para_text">
          You can also contact customer service to make your payments, Customer
          Service: 888-585-8319
        </p>
      </div>
    </>
  );
}

export default MakePayment;
