import Login from "../../Templates/login/login.jsx";

const BorrowerLogin = () => {
  const data = { url: "auth/login", key: "borrower" };
  return (
    <>
      <Login data={data} />
    </>
  );
};

export default BorrowerLogin;
