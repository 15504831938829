import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AuthGet } from "../../common_var/httpService";
import FileUpload from "../../Admin/Components/ApplicationDetails/file-upload";
import MilestoneThree from "../../Admin/Components/ApplicationDetails/milestone-three";
import MilestoneTwo from "../../Admin/Components/ApplicationDetails/milestone-two";
import Stepper from "../../Admin/Components/ApplicationDetails/stepper";
import InstallerCusModal from "../../Components/Modal/InstallerModal";
import SystemInfoVerification from "../../Admin/Components/ApplicationDetails/system-info-verification";
import SelectDocument from "../../Admin/Components/ApplicationDetails/select-document";
import MilestoneOne from "../../Admin/Components/ApplicationDetails/milestone_one";
import ProjectCompleted from "../../Admin/Components/ApplicationDetails/ProjectCompleted";
import Email_installer from "../../assets/images/email_installer.svg";
import Address_installer from "../../assets/images/address_installer.svg";
import PhoneIcon_installer from "../../assets/images/phoneIcon_installer.svg";
import Personal_installer from "../../assets/images/personal_installer.svg";
import { useStateValue } from "../../Context/StateProvider";
import { toast } from "react-hot-toast";

function ApplicationDetails() {
  const [data, setData] = useState();
  const [projectCompleted, setProjectCompleted] = useState();
  const { stage, id } = useParams();
  const [showDocumentCentre, setShowDocumentCentre] = useState(false);
  const [files, setFiles] = useState();
  const navigate = useNavigate();
  const [initialState, dispatch, authState] = useStateValue();

  useEffect(() => {
    getApplicationDetails();
  }, []);

  const getApplicationDetails = () => {
    AuthGet("installer/getapplicationdetails/" + id, "admin")
      .then((res) => {
        if (res.statusCode === 200) {
          const applicationStatus = res.data.applicationDetails[0].milestone_stage;
          const applicationDetails = { ...res.data };

          if (applicationDetails?.milestoneFundPer?.length < 3) {
            toast.error('Please contact Lender, Milestone configuartion is not done yet.')
          }
          if (applicationStatus == "Verified And Approved") {
            applicationDetails.milestone1ReqAmount = `${(
              (res.data.applicationDetails[0].loanamount / 100) *
              applicationDetails?.milestoneFundPer[0]?.value
            ).toFixed(2)}`;
          }

          if (applicationStatus === "Milestone 1 Completed") {
            if (res?.data?.milestoneDt[0]) {
              if (!res?.data?.milestoneDt[0]?.milestoneTransactionId) {
                applicationDetails.applicationDetails[0].milestone_stage =
                  "Verified And Approved";
                applicationDetails.show = "Milestone 1";
              }
            }
            // if (!res.data.applicationDetails[0].milestone1TransactionId) {
            //   applicationDetails.applicationDetails[0].status =
            //     "verifiedAndApproved";
            //   applicationDetails.show = "Milestone 1";
            // }
            applicationDetails.milestone2ReqAmount = `${(
              (res.data.applicationDetails[0].loanamount / 100) *
              applicationDetails?.milestoneFundPer[1]?.value
            ).toFixed(2)}`;
          }

          if (applicationStatus === "Milestone 2 Completed") {
            if (res?.data?.milestoneDt[1]) {
              if (!res?.data?.milestoneDt[1]?.milestoneTransactionId) {
                applicationDetails.applicationDetails[0].milestone_stage =
                  "Milestone 1 Completed";
                applicationDetails.show = "Milestone 2";
              }
            }
            // if (!res.data.applicationDetails[0].milestone2TransactionId) {
            //   applicationDetails.applicationDetails[0].status =
            //     "milestone1Completed";
            //   applicationDetails.show = "Milestone 2";
            // }

            applicationDetails.milestone3ReqAmount = `${(
              (res.data.applicationDetails[0].loanamount / 100) *
              applicationDetails?.milestoneFundPer[2]?.value
            ).toFixed(2)}`;

            // applicationDetails.milestone3ReqAmount = `$ ${(
            //   res.data.applicationDetails[0].loanAmount -
            //   ((res.data.applicationDetails[0].loanAmount / 100) * 20).toFixed(
            //     2
            //   ) *
            //     2
            // ).toFixed(2)}`;
          }

          if (applicationStatus === "Milestone 3 Completed") {
            if (res?.data?.milestoneDt[2]) {
              if (!res?.data?.milestoneDt[2]?.milestoneTransactionId) {
                applicationDetails.applicationDetails[0].milestone_stage =
                  "Milestone 2 Completed";
                applicationDetails.show = "Milestone 3";
              }
            }

            // if (!res.data.applicationDetails[0].milestone3TransactionId) {
            //   applicationDetails.applicationDetails[0].status =
            //     "Milestone 2 Completed";
            //   applicationDetails.show = "Milestone 3";
            // }
            projectdetails();
          }
          setData(applicationDetails);
        }
      })
      .catch((err) => console.log(err));
  };

  const projectdetails = () => {
    AuthGet("installer/projectdetails/" + id, "admin")
      .then((res) => {
        setProjectCompleted(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <section className="application_detail">
      <div className="heading20 fontWeight600 mb_24">Application Details</div>
      <div className="row">
        <div className="col-md-10">
          <div class="row mb_34">
            {/* customer div */}
            <div className=" col-md-6">
              <div class=" frameWhite height100per">
                <h2 className="heading18 fontWight600 mb_24">Customer</h2>

                <div>
                  <div class="wordbreak mb_24">
                    <div class="displayFlex ptb20 AlignItemCenter pt0">
                      {/* <div class="iconBlock">
                        <div class="overview_profile_icon"></div>
                      </div> */}

                      <div className="mr_12">
                        <img src={Personal_installer} className="width20" />
                      </div>
                      {data ? (
                        <div>
                          {data.applicationDetails[0].firstname}{" "}
                          {data.applicationDetails[0].lastname}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {data && data.applicationDetails[0].phone ? (
                      <div class="displayFlex ptb20 AlignItemCenter">
                        {/* <div class="iconBlock">
                          <div class="overview_contact_icon"></div>
                        </div> */}

                        <div className="mr_12">
                          <img src={PhoneIcon_installer} className="width20" />
                        </div>
                        <div>{data.applicationDetails[0].phone}</div>
                      </div>
                    ) : null}

                    <div class="displayFlex ptb20 AlignItemCenter">
                      {/* <div class="iconBlock">
                        <div
                          class="overview_mail_icon"
                          style={{ marginRight: "15px" }}
                        ></div>
                      </div> */}
                      <div className="mr_12">
                        <img src={Email_installer} className="width20" />
                      </div>

                      <div>{data ? data.applicationDetails[0].email : ""}</div>
                    </div>

                    <div class="displayFlex ptb20 AlignItemCenter pb0">
                      {/* <div class="iconBlock">
                        <div class="overview_home_icon"></div>
                      </div> */}
                      <div className="mr_12">
                        <img src={Address_installer} className="width20" />
                      </div>

                      {data ? (
                        <div>
                          {/* {data.applicationDetails[0].unit}{" "} */}
                          {data.applicationDetails[0].street_address}
                          {/* {data.applicationDetails[0].city}{" "} */}
                          {/* {data.applicationDetails[0].state}{" "} */}
                          {/* {data.applicationDetails[0].zipCode} */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <h2 className="heading18 fontWight600 mb_24">
                    Application Info
                  </h2>

                  <div class="AlignItemCenter displayFlex">
                    <div class="fontWeight">Application ID: </div>
                    <div>
                      &nbsp;
                      APP_{data ? data.applicationDetails[0].ref_no : ""}
                    </div>
                  </div>
                  <div class="AlignItemCenter displayFlex ptb20">
                    <div class="fontWeight">Application Status: </div>
                    <div>
                      &nbsp;
                      {data ?
                        data?.applicationDetails[0]?.milestone_stage !== null ?
                          data?.applicationDetails[0]?.milestone_stage :
                          "Milestones"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* loan info div */}
            <div className="col-md-6">
              <div class="frameWhite height100per">
                <h2 className="heading18 fontWight600 mb_24">Loan Info</h2>

                <div>
                  <div class="AlignItemCenter displayFlex ptb20 gap20">
                    <div class="fontWeight">System Price:</div>
                    <div>
                      {initialState?.currency} {" "} {data ? data.applicationDetails[0].loanamount : ""}
                    </div>
                  </div>
                  <div class="AlignItemCenter displayFlex gap20">
                    <div class="fontWeight">Loan Selected:</div>
                    <div>
                      {data ? data.applicationDetails[0].loanterm : ""} months
                    </div>
                  </div>
                  <div class="AlignItemCenter displayFlex ptb20 gap20">
                    <div class="fontWeight">APR: </div>
                    <div>{data ? data.applicationDetails[0].apr : ""} %</div>
                  </div>
                </div>

                <div>
                  {data?.applicationDetails[0]?.milestone_stage === null ? "" :
                    <a
                      style={{ cursor: "pointer" }}
                      className="greenColor"
                      onClick={() => {
                        navigate(
                          `/admin/applications/${stage}/${id}/i_info`
                        );
                      }}
                    >
                      View Application Details
                    </a>
                  }
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12   ">
              {
                data?.applicationDetails[0]?.milestone_stage === null
                  ?
                  (
                    <FileUpload
                      files={files}
                      data={data}
                      setFiles={setFiles}
                      setShow={setShowDocumentCentre}
                    />
                  ) : null}
              {
                data?.applicationDetails[0].milestone_stage === "Documents Uploaded"
                  ? (
                    <SystemInfoVerification />
                  ) : null}
              {
                data?.applicationDetails[0].milestone_stage === "Verified And Approved"
                  ? (
                    <MilestoneOne data={data} />
                  ) : null}
              {
                data?.applicationDetails[0].milestone_stage === "Milestone 1 Completed"
                  ? (
                    <MilestoneTwo data={data} />
                  ) : null}
              {
                data?.applicationDetails[0].milestone_stage === "Milestone 2 Completed"
                  ? (
                    <MilestoneThree data={data} />
                  ) : null}
              {
                data?.applicationDetails[0].milestone_stage === "Milestone 3 Completed"
                  ? (
                    <ProjectCompleted data={projectCompleted} />
                  ) : null}
            </div>
          </div>
        </div>
        <div className=" col-md-2">
          <Stepper data={data} />
        </div>
      </div>

      <InstallerCusModal
        view={
          <SelectDocument
            setShow={setShowDocumentCentre}
            setFiles={setFiles}
            files={files}
          />
        }
        modalState={{
          show: showDocumentCentre,
          setShow: setShowDocumentCentre,
        }}
        preventClosing={true}
      />
    </section>
  );
}

export default ApplicationDetails;
