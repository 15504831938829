import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthGet } from '../../common_var/httpService';


const Verified = () => {
    const { id } = useParams();
    const [system, setSystem] = useState([]);

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        let res = await AuthGet(`funded-contracts/verifiedinfo/${id}`, "admin");

        if (res.statusCode == 200) {
            setSystem(res.data.systemInfo);
        }
    }
    return (
        <>
            {
                system ? <div className="commondetailTable">
                    <tr>
                        <th> Manufacturer of Modules : </th>
                        <td>
                            {system?.moduleManufacturer} </td>
                    </tr>

                    <tr>
                        <th> Manufacturer of Inverters : </th>

                        <td>  {system?.inverterManufacturer}</td>
                    </tr>
                    <tr>
                        <th> Manufacturer of Battery : </th>
                        <td>
                            {system?.batteryManufacturer} </td>
                    </tr>
                    <tr>
                        <th> System Size (kW) : </th>
                        <td>
                            {system?.systemSize} </td>
                    </tr>
                    <tr>
                        <th> Estimated Annual Production : </th>

                        <td>

                            {system?.estAnnualProduction} </td>
                    </tr>
                </div> :
                    <div>

                        <div className="frameshadow">
                            <p> No Records</p>
                        </div>
                    </div>

            }

        </>

    );
}

export default Verified