import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import ChangePassword from "../../Templates/changepassword/changepassword";
import CommonToggle from "../../Templates/toggle/toggle";

function Settings() {
  let id = sessionStorage.getItem("UserId");
  const [initialState, dispatch, authState, setAuthState] = useStateValue();
  const [facvalue, setFacvalue] = useState(false);
  const navigate = useNavigate();
  const Dataurl = { url: `auth/update-password/${id}`, key: "borrower" };
  const getfacurl = `auth/get-2FA/${id}`;
  const Paramsdata = {
    url: `auth/enable-2FA/${id}`,
    key: "borrower",
  };
  const getTwofac = async () => {
    await AuthGet(`${getfacurl}`, "borrower")
      .then((res) => {
        if (res.data.twofactorauth == "N") {
          setFacvalue(false);
        } else {
          setFacvalue(true);
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };

  useEffect(() => {
    if (id) {
      getTwofac();
    }
  }, []);


  const logout = () => {
    setAuthState(null);
    sessionStorage.clear();
    navigate("/borrower/login");
  };
  return (
    <div>
      {/* <div>
      <button
          className="greenbutton"
          onClick={logout}
          
        >
          Logout
        </button>
      </div> */}
      <ChangePassword dataurl={Dataurl} />
      <CommonToggle
        title="Two factor Authentication"
        Paramsdata={Paramsdata}
        fun={getTwofac}
        data={facvalue}
        id={id}
      />


    </div>
  );
}

export default Settings;
