import React, { useState, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider"
import { Post } from "../../common_var/httpService";
import { toast } from 'react-hot-toast';
import Toast from '../../Components/Toaster/Toast';
import Brandlogo from "../../assets/images/brand_logo.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EyehideIcon from "../../assets/images/eye_hide.svg";
import EyeshowIcon from "../../assets/images/eye_show.svg";
import { emailValidation, passwordValidation } from '../../Admin/CommonFunctions/Validations';
const PasswordReset = ({ portal }) => {
  const [passwordVisibilty1, setPasswordVisibilty1] = useState("password")
  const [passwordVisibilty2, setPasswordVisibilty2] = useState("password")

  const formik=useFormik({
    initialValues:{
      newpw:'',
      cnewpw:''
    },enableReinitialize:true,
    validationSchema:Yup.object({
      newpw:Yup.string()
      .required('Is Required')
      .min(8, 'New Password must be 8 characters long')
      .matches(/[0-9]/, 'New Password requires a number')
      .matches(/[a-z]/, 'New Password requires a lowercase letter')
      .matches(/[A-Z]/, 'New Password requires an uppercase letter')
      .matches(/[^\w]/, 'New Password requires a symbol'),
      cnewpw:Yup.string()
      .required('Is Required')
      .oneOf([Yup.ref('newpw'), null], 'Must match "New Password" field value'),
    }),
    onSubmit:async(values)=>{
    console.log(values)

    let data =
    {
      newpw: values.newpw,
      id: searchParams.get("id"),
      token: searchParams.get("token")
    }

    let url = 'users/password-reset';

    if(portal === "borrower") {
      url = 'auth/reset-password';
    }

    await Post(url, data, portal) 
      .then((res) => {
        let url=`/${portal}`+'/login'
        console.log("test", res);
        if (res.statusCode == 200) {
          toast.success(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))
        }
        if (res.statusCode == 100) {
          toast.error(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "error",
          // });
          // setTimeout(()=>{
          //   navigate('/borrower/login')
          // },(1000))

        }

        //  res.data.length > 0 ? navigate(`/tenant-dashboard/${tenantId}`) : navigate('/vertical')
      })
      .catch((err) => {
        console.log(err);
      });
    
    }
})        

 
 
 
 
 
  let req = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // console.log(searchParams.get("token"))
  const [initialState, dispatch, authState, setAuthState] = useStateValue();

  const newpassref = useRef("");
  const cnewpassref = useRef("");
  const navigate = useNavigate();
  console.log(req)

  const [nwpassword, setNwpassword] = useState("");
  const [password, setPassword] = useState("");
  const [passerr, setPasserr] = useState(false);
  const [passerr1, setPasserr1] = useState(false);
  const [errorval1, setErrorVal1] = useState(false);
  const [errorval, setErrorVal] = useState(false);
  const [matcherr, setMatcherr] = useState(false);

  const forgotPass = async (e) => {
    e.preventDefault()
    let data =
    {
      newpw: password,
      id: searchParams.get("id"),
      token: searchParams.get("token")
    }

    let url = 'users/password-reset';

    if(portal === "borrower") {
      url = 'auth/reset-password';
    }

    await Post(url, data, portal) 
    // let url = '/' + portal + '/login'
      .then((res) => {
        let url=`/${portal}`+'/login'
        console.log("test", res);
        if (res.statusCode == 200) {
          toast.success(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))


        }
        if (res.statusCode == 100) {
          toast.error(res.message[0])
          setTimeout(() => {
            navigate(url)
          }, (1000))
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "error",
          // });
          // setTimeout(()=>{
          //   navigate('/borrower/login')
          // },(1000))

        }

        //  res.data.length > 0 ? navigate(`/tenant-dashboard/${tenantId}`) : navigate('/vertical')
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <Toast />
      <div className="logincomponent">
        <div className="loginSection">
          <div className="login-form" style={{ textAlign: "left" }}>
            <div className="main-div">
              <div className="panel">
              <div className='textAlignCenter brand_logo'>
                  <img src={Brandlogo} alt='' style={{ marginBottom: "20px" }} />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>

                <label htmlFor="newpw">New Password</label>
                <br />
                <input
                  placeholder='Enter new password'
                   type={passwordVisibilty2}
                  name='newpw'
                  id="newpw"
                  className="MainInput mb20px"
                  value={formik.values.newpw}
                  ref={newpassref}
                onChange={(e) => {
                  formik.setFieldValue(
                    "newpw",
                    passwordValidation(e.target.value)
                  );
                }}
                  onBlur={formik.handleBlur
                    // () => {
                  //   let val = newpassref.current.value.length
                  //   console.log(newpassref.current.value.length)
                  //   if (newpassref.current.value.length < 1) {
                  //     setErrorVal(true)
                  //   } else {
                  //     setErrorVal(false)
                  //   }
                  // }
                }
                />
               <img className="eye_icon"
                src={passwordVisibilty2 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty2 == "password"
                ? setPasswordVisibilty2("text")
                : setPasswordVisibilty2("password");
                }} 
               />
                  {formik.touched.newpw && formik.errors.newpw ? (
                                    <p className="error_text text-danger">{formik.errors.newpw}</p>
                                ) : null}
                {/* {errorval === true && (
                  <small className="text-danger">New Password is required</small>
                )}
                {passerr === true && (
                  <small className="text-danger">
                    Password should be at least 8 characters long and should contain
                    one uppercase, one lowercase, one number and one special character
                  </small>
                )}
                {
                  matcherr === true && (
                    <small className="text-danger">
                      New password should not be similar to current password
                    </small>
                  )
                } */}
                <br />
                <label htmlFor="cnewpw">Confirm New Password</label>
                <br />
                <input
                  placeholder='Enter confirm password'
                  name='cnewpw'
                  type={passwordVisibilty1}
                  id="cnewpw"
                  className="MainInput mb20px"
                  value={formik.values.cnewpw}
                  ref={cnewpassref}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "cnewpw",
                      passwordValidation(e.target.value)
                    );
                  }}
                  // onChange={formik.handleChange
                  //   (e) => {
                  //   setNwpassword(e.target.value);
                  //   let cval = cnewpassref.current.value
                  //   let nval = newpassref.current.value
                  //   if (nval !== cval) {
                  //     setPasserr1(true);
                  //   } else {
                  //     setPasserr1(false);
                  //   }
                  // }
                // }
                  onBlur={formik.handleBlur
                  //   () => {

                  //   let val = cnewpassref.current.value.length
                  //   if (cnewpassref.current.value.length < 1) {
                  //     setErrorVal1(true)
                  //   } else {
                  //     setErrorVal1(false);

                  //   }
                  // }
                }
                />
                <img className="eye_icon"
                src={passwordVisibilty1 == "password" ? EyeshowIcon : EyehideIcon}
                onClick={() => {
                passwordVisibilty1 == "password"
                ? setPasswordVisibilty1("text")
                : setPasswordVisibilty1("password");
                }} 
               />
                  {formik.touched.cnewpw && formik.errors.cnewpw ? (
                                    <p className="error_text text-danger">{formik.errors.cnewpw}</p>
                                ) : null}
                {/* {errorval1 === true && (
                  <>
                    <small className="text-danger">New Password is required</small>
                    <br />
                  </>
                )}
                {passerr1 === true && (
                  <small className="text-danger">
                    Password Does Not Matches the New Password
                  </small>
                )} */}
                <br />
                <br />
                <button type="submit" className="button mb20px"
                >Submit</button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}




export default PasswordReset