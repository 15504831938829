
export let zerocheck=(cell,currency)=>{
    if(cell){
    let splitcell=cell?.toString()?.slice(0, 1)
    if(splitcell=="."){
      
    return `${currency}0`+cell;
    }else if(!cell){
    return `${currency}0`
    }else{
    return `${currency}`+cell
    }
    }else{
    return `${currency}0.00`
    }
    }