import React, { useEffect, useState } from "react";
import { GooglePlaces } from "../../common_var/httpService";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useStateValue } from "../../Context/StateProvider";
import DropdownValidator from "../Dropdown/DropdownValidator";

const AddressAutocomplete = ({
  value,
  handleAddress,
  required,
  labelName,
  name,
}) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);

  return (
    <>
      <div className="autocomplete-wrapper col-md-6 mb_24">
        {errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <GooglePlacesAutocomplete
          apiOptions={{ region: "us" }}
          selectProps={{
            // styles: {
            //   input: (provided) => ({
            //     ...provided,
            //     color: "yellow",
            //   }),
            //   option: (provided) => ({
            //     ...provided,
            //     color: "green",
            //   }),
            //   singleValue: (provided) => ({
            //     ...provided,
            //     color: "red",
            //   }),
            // },
            // value,
            value: value ? { value: value, label: value } : null,
            onChange: handleAddress,
            onBlur: () => setIsTouched(true),
          }}
        />
      </div>
    </>
  );
};

export default AddressAutocomplete;
