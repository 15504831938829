const Roles = {
  super_admin: 'Super Admin',
  loan_admin: 'Loan Admin',
  loan_underwriter: 'Loan Underwriter',
  dealer_admin: 'Dealer Admin',
  dealer_practitioner: 'Dealer Practitioner',
  dealer_member: 'Dealer Member',
};

const PageTabs = {
  contact: 'Contact',
  credit_check: 'Credit Check',
  bank_lookup: 'Bank Lookup',
  work: 'Work',
  repayment_calender: 'Repayment Calendar',
  doc_vault: 'Doc Vault',
  // deal_info:'Deal Info',
  modify_deal: 'Modify Deal',
  ask_customer: 'Ask Customer',
  trace_actions: 'Trace Actions',
  verfied_system_info : 'Verfied System Info',
  milestone_one : 'Milestone 1 Request',
  milestone_two : 'Milestone 2 Request',
  milestone_three : 'Milestone 3 Request',
  project_completed : 'Project Completed',
  view_more: 'View More',

}

export { Roles, PageTabs };
