import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthGet } from "../../common_var/httpService"
import { useStateValue } from '../../Context/StateProvider';

const Employment = () => {
    const [userData, setUserData] = useState([]);
    const [{currency},dispatch]= useStateValue();
    const queryParams = useLocation()
    const navigate = useNavigate()
    let params = queryParams.pathname.split('/');
    const loanId = params[params.length - 1];
    const loanStage = params[params.length - 2];

    useEffect(() => {
        getlist();
    }, [queryParams]);

    const getlist = async () => {
        console.log("params Id: ", loanId);
        console.log("stage: ", loanStage);

        await AuthGet(`loan/fetch-employee-particulars/${loanId}`, "admin")
        .then((res) => {
            if (res["statusCode"] == 200) {
                console.log("Res Data: ", res.data);
                setUserData({
                    borrower: res.data.borrower[0],
                    coborrower: res.data.coborrower[0]
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    console.log("User data: ", userData);

    return (
        <>
            {/* <div>
                <h5>Employment</h5>
            </div> */}

            <div>
                <div className='container-fluid mb20px frameshadow' >
                    <h5 className='frameshadow mb20px'>Borrower Info</h5>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='frameshadow'>
                                <h5 className='mb20px'>Current Employer Info</h5>
                                <div>
                                    <div>
                                        <div className='employeblock'>
                                            <div>Employer Name </div>
                                            <div> {userData?.borrower?.employer_name}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>Employer Phone </div>
                                            <div> {userData?.borrower?.phone}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>Job Title </div>
                                            <div> {userData?.borrower?.jobtitle}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>  Employment Status </div>
                                            <div> {userData?.borrower?.employment_status}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div> Job Start Date </div>
                                            <div> {userData?.borrower?.date_of_hire}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='frameshadow'>
                                <h5 className='mb20px'>Income Information</h5>
                                <div>
                                    <div className='employeblock'>
                                        <div>   Current Annual Income </div>
                                        <div> {currency} {
                                            userData?.borrower?.annual_income === ".00" ? "0.00" :
                                            userData?.borrower?.annual_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Current Monthly Income </div>
                                        <div> {currency} {
                                            userData?.borrower?.monthly_income === ".00" ? "0.00" :
                                            userData?.borrower?.monthly_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Current Additional Income </div>
                                        <div> {currency} {
                                            userData?.borrower?.additional_income === ".00" ? "0.00" :
                                            userData?.borrower?.additional_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Pay Frequency </div>
                                        <div> {userData?.borrower?.payroll_frequency}</div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Monthly Mortgage </div>
                                        <div> {currency} {
                                            userData?.borrower?.monthly_mortgage === ".00" ? "0.00" :
                                            userData?.borrower?.monthly_mortgage
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {userData?.coborrower !== undefined ? 
                    <div className='container-fluid frameshadow' >
                    <h5 className='frameshadow mb20px'>Co-Borrower Info</h5>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='frameshadow'>
                                <h5 className='mb20px'>Current Employer Info</h5>
                                <div>
                                    <div>
                                        <div className='employeblock'>
                                            <div>Employer Name </div>
                                            <div> {userData?.coborrower?.employer_name}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>Employer Phone </div>
                                            <div> {userData?.coborrower?.phone}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>Job Title </div>
                                            <div> {userData?.coborrower?.jobtitle}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div>  Employment Status </div>
                                            <div> {userData?.coborrower?.employment_status}</div>
                                        </div>

                                        <div className='employeblock'>
                                            <div> Job Start Date </div>
                                            <div> {userData?.coborrower?.date_of_hire}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='frameshadow'>
                                <h5 className='mb20px'>Income Information</h5>
                                <div>
                                    <div className='employeblock'>
                                        <div>   Current Annual Income </div>
                                        <div> {currency} {
                                            userData?.coborrower?.annual_income === ".00" ? "0.00" :
                                            userData?.coborrower?.annual_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Current Manual Income </div>
                                        <div> {currency} {
                                            userData?.coborrower?.monthly_income === ".00" ? "0.00" :
                                            userData?.coborrower?.monthly_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Current Additional Income </div>
                                        <div> {currency} {
                                            userData?.coborrower?.additional_income === ".00" ? "0.00" :
                                            userData?.coborrower?.additional_income
                                            }
                                        </div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Pay Frequency </div>
                                        <div> {userData?.coborrower?.payroll_frequency}</div>
                                    </div>

                                    <div className='employeblock'>
                                        <div>    Monthly Mortgage </div>
                                        <div> {currency} {
                                            userData?.coborrower?.monthly_mortgage === ".00" ? "0.00" :
                                            userData?.coborrower?.monthly_mortgage
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    ""
                }
            </div>
        </>
    )
}

export default Employment
