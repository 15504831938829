import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Post } from "../../common_var/httpService";

const AdminSSOLogin = () => {
  const { email, subscription_id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    autoLogin();
  }, []);

  const gopage = (list, tabs) => {
    switch (list.name) {
      case "Dashboard":
        navigate("/admin/dashboard");
        break;
      case "Approved Application":
        navigate("/admin/approved");
        break;
      case "Pending Application":
        navigate("/admin/pendings");
        break;
      case "Incomplete Application":
        navigate("/admin/incomplete");
        break;
      case "Denied Application":
        navigate("/admin/dashboard");
        break;
      case "Funded Contracts":
        navigate("/admin/funded-contracts");
        break;
      case "Settings":
        gosetting(tabs[list.id]);
        break;
      case "Installer Management":
        navigate("/admin/installer");
        break;
      case "Users":
        navigate("/admin/users");
        break;
      case "Start Application":
        navigate("/admin/start-application");
        break;
      case "Funding Contracts":
        navigate("/admin/funding-contracts");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  const gosetting = (list) => {
    switch (list.name) {
      case "Audit Log":
        navigate("/admin/settings/auditlog");
        break;
      case "Questions":
        navigate("/admin/settings/questions");
        break;
      case "Admin Security":
        navigate("/admin/settings/admin-security");
        break;
      case "Roles":
        navigate("/admin/settings/roles");
        break;
      case "DecisionEngine":
        navigate("/admin/settings/decisionengine");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  const autoLogin = async () => {
    let body = {
      email: decodeURIComponent(email),
      s_id: decodeURIComponent(subscription_id),
    };
    console.log(body);
    let res = await Post("users/auth/login", body, "admin");
    if (res.statusCode == 200) {
      sessionStorage.setItem("resuser", JSON.stringify(res["resuser"]));
      sessionStorage.setItem("pages", JSON.stringify(res["pages"]));
      sessionStorage.setItem("tabs", JSON.stringify(res["tabs"]));
      sessionStorage.setItem("UserId", res["resuser"]["id"]);
      if (res["resuser"]["twofactorauth"] === "N") {
        sessionStorage.setItem("admin_token", res["jwtAccessToken"]);
        // gopage(res["pages"][0], res["tabs"]);
        navigate("/admin/dashboard");
      }
    }
  };
  return <div></div>;
};

export default AdminSSOLogin;
