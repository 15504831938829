import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AuthPost,
  AuthDownload,
  AuthGet,
} from "../../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Form, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import { datePipe } from "../general";
import { useStateValue } from "../../../Context/StateProvider";

const MileStone3 = ({ data, get }) => {
  const { id } = useParams();
  const [userid, setUserid] = useState("");
  const [files, setfiles] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const navigate = useNavigate();
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7).split("/")[0];
  const loanStatus =
    loanParams.charAt(0).toUpperCase() + loanParams.slice(1).split("/")[0];
  const [currentPage, setCurrentPage] = useState(1);
  const [initialState, dispatch, authState] = useStateValue();

  useEffect(() => {
    if (Object.keys(data).length) {
      console.log("dataaa", data);
      setUserid(sessionStorage.getItem("InstallerUserId"));
      setfiles(data.milestone3ReqFiles);

      //Need to handle once transaction dev is completed from Admin side
      // data.Milestone3Transactions?.map((transaction, i) => {
      //   data.Milestone3Transactions[i].createdAt = datePipe(transaction.createdAt)
      // })
      setTransaction(data.Milestone3Transactions);
    }
  }, [data]);

  const viewDoc = (filename) => {
    console.log(filename);
    // console.log("Admin key: ", key);
    if (filename) {
        filename = filename?.split("/");
        filename = filename[filename.length - 1];
        filename = `${filename}`;

        window.open(process.env.REACT_APP_ADMIN_API_URL + "/files/save/" + filename, "_blank");
    }
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
    },
    { dataField: "originalname", text: "Documents" },
    {
      dataField: "filename", text: "PDF,JPG,JPEG,PNG", formatter: (cell) => {
        return "View ";
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          viewDoc(row.filename);
        },
      },
    },
  ];

  const columns2 = [
    {
      dataField: "",
      text: "#",
      sort: true,
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        console.log("Total: ", total);
        return slNo;
      },
    },

    { dataField: "comments", text: "Comments" },
    {
      dataField: "role",
      text: "Created By",
      formatter: (cell, row) => {
        return row.role + " : " + row.firstName + row.lastName;
      },
    },
    { dataField: "createdAt", text: "Created Date" },
  ];

  // const colTransaction = [
  //   { dataField: 'TransactionId', text: 'TransactionId', sort: true },
  //   { dataField: 'accountmethod', text: 'Method', sort: true },
  //   { dataField: 'Message', text: 'Message', sort: true },
  //   { dataField: 'Status', text: 'Status', sort: true },
  //   { dataField: 'amount', text: 'Amount', sort: true },
  //   { dataField: 'createdAt', text: 'Date', sort: true },
  // ];

  const colTransaction = [
      { dataField: 'id', text: 'TransactionId', sort: true },
      { dataField: 'direction', text: 'Direction', sort: true },
      { dataField: 'typeOfPayment', text: 'Payment type', sort: true },
      { dataField: 'amount', text: 'Amount', sort: true },
      { dataField: 'createdat', text: 'Date', sort: true },
  ];

  const milestone_pay1 = async () => {
    let userid = JSON.parse(sessionStorage.getItem("userData")).user.id;
    let res = await AuthGet(
      `funded-contracts/milestone_pay1/${id}/${userid}`,
      "installer"
    );

    if (res.statusCode == 200) {
    }
  };

  const addComments = useFormik({
    initialValues: {
      commentType: "Milestone3CommentByinstaller",
      comments: "",
      loan_id: id,
      user_id: userid,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      comments: Yup.string().required("required"),
      commentType: Yup.string().required("required"),
      loan_id: Yup.string().required("required"),
      user_id: Yup.string().required("required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        let post = await AuthPost(`funded-contracts/addComment`, values, "installer");



        resetForm();
        get();

      } catch (error) {
        console.error("error", error);
      }
    },
  });  

  return (
    <div>
      <div className="frameshadow">
        <div>
          <strong>Milestone 3 Requested Amount :</strong>
          {initialState?.currency}
          {" "}
          {data?.milestones[2]?.milestoneReqAmount}
        </div>
        <br />
        <div className="mainTitle">Proof Of Ownership Documents</div>
        <div>
          <BootstrapTable keyField="id" data={files} columns={columns} />


          {/* <div className="p10">
          <form onSubmit={addComments.handleSubmit}>
            <label>Comments</label> <br></br>
            <textarea
              type="text"
              className="input_form width_100per"
              placeholder="comments"
              id="comments"
              name="comments"
              onChange={addComments.handleChange}
              onBlur={addComments.handleBlur}
              value={addComments.values.comments}
            />
            <button type="submit">Add Comments</button>
          </form>
        </div>
        <div className="mainTitle">
          {" "}
          Comments Details
          </div>
          {data?.Milestone3Comments?.length > 0 &&
            <div className="p10">
            <BootstrapTable keyField="id" data={data?.Milestone3Comments} columns={columns2} />
          </div>
           } */}
          <div className="installerTablewrapper">
            <div className="mainTitle">Transaction</div>

            <BootstrapTable
              keyField="id"
              data={transaction}
              columns={colTransaction}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MileStone3