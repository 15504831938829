import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import brandlogo from "../../assets/images/brand_logo.svg";
import { Get } from "../../common_var/httpService";

const SelectLoanAmount = () => {
  const queryParams = useLocation();
  let providername = queryParams.pathname?.split("/");
  const [{ currency }, dispatch] = useStateValue();
  const [sliderAmount, setSliderAmount] = useState([]);
  const [loanAmount, setLoanAmount] = useState(
    !sliderAmount?.min_amount ? 1000 : sliderAmount?.min_amount
  );
  const navigate = useNavigate();

  const userDt = JSON.parse(sessionStorage.getItem("resuser"));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loanAmount >= 1000) {
      localStorage.setItem("loan_amount", loanAmount);
      dispatch({
        type: "SET_LOANAMOUNT_POPUP",
        payload: false,
      });
      dispatch({
        type: "SET_EMAILPHONE_POPUP",
        payload: true,
      });

      if (providername[2]) {
        navigate(`/create-account/${providername[2]}`);
      } else {
        navigate("/create-account");
      }
    } else {
      toast.error("Select loan amount alteast 1000");
    }
  };

  const handleClose = () => {
    dispatch({
      type: "SET_LOANAMOUNT_POPUP",
      payload: false,
    });
  };

  const getLoanSliderAmount = async () => {
    try {
      let resp = await Get("api/sliderAmount", "admin");
      if (resp.statusCode == 200) {
        setSliderAmount(resp?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLoanSliderAmount();
  }, []);

  return (
    <>
      <div className="fullScreen_bg">
        <Toast />
        <div className="topHeader">
          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" />
              </div>
            </div>
          </div>

          <div></div>
        </div>

        <div className="whiteboxblock">
          <h2 className="textAlignCenter">Start Now</h2>
          <p className="textAlignCenter mb_24">
            We will offer ideal financing option that fits your needs{" "}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="howMuch_progress">
              <h3 className="textAlignCenter mb_24">
                Let us know your loan expectations
              </h3>
              <div className="loanAmountText mb_24">
                {currency}
                {Intl.NumberFormat("en-US").format(loanAmount)}
              </div>
              <div className="displayFlex mb_24 AlignItemCenter">
                <div className="pr20">
                  {currency}
                  {sliderAmount?.min_amount}
                </div>
                <div className="slidecontainer">
                  <input
                    clasName="slider"
                    style={{ width: "100%" }}
                    type="range"
                    defaultValue={sliderAmount?.min_amount}
                    step={500}
                    min={sliderAmount?.min_amount}
                    max={sliderAmount?.max_amount}
                    onChange={(e) => setLoanAmount(+e.target.value)}
                  />
                </div>

                <div>
                  {currency}
                  {sliderAmount?.max_amount}
                </div>
              </div>
            </div>
            <div className="mb24">
              <small>
                &nbsp;&nbsp;&nbsp;<span style={{ color: "red" }}>*</span> This
                amount is indicative. The final loan amount will vary based on
                your eligiblity and submitted documents{" "}
              </small>
            </div>
            <div className="textAlignCenter">
              <button
                type="submit"
                className=" btn_secondary displayunset bg_teal"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SelectLoanAmount;
