import React from 'react';
import { useRef } from 'react';
import ClouduploadIcon from '../../../assets/images/cloudUpload_icon.svg'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthFile } from '../../../common_var/httpService';

function FileUpload({ files, data, setFiles, setShow }) {
    const installerId = sessionStorage.getItem('InstallerUserId')
    const documentRef = useRef()
    const { id: loan_id } = useParams();

    const handleimage = (e) => {
     
      setFiles({ file: e.target.files[0], element: documentRef });
    if (e.target.files[0]) {
      handleShow();
    }
    e.target.value = "";
  };

    const handleShow = () => setShow(true);

    const todayDate = () => {
        const date = new Date()
        let dateNum = date.getDate();
        let month = date.getMonth() + 1
        const year = date.getFullYear()
        if (dateNum < 10) {
            dateNum = '0' + dateNum;
        }
        if (month < 10) {
            month = '0' + month;
        }

        const today = dateNum + '-' + month + '-' + year;
        return today;
    }

    const uploadFiles = () => {
        const formData = new FormData();
        formData.append('loan_id', loan_id);
        formData.append('user_id', installerId);
        formData.append("documentTypes[]", files.documentTypes);
        formData.append("files[]", files.file);

        AuthFile('installer/fileupload', formData, 'admin').then((res) => {
            if (res.statusCode === 200) {
                console.log(res)
                setTimeout(() => {
                    window.location.reload();
                }, 50);
            }
        }).catch((err) => console.log(err))
    }

    return (
      <div>
        <div class="whiteFrame mb_24 p25">
          <div class="fs20px fontWeight600 mb_24">
            Please upload your proof of ownership
          </div>
          <p class="paragraphText mb_24">
            (The Land Use Right Certificate, Real Property Certificate,
            Other Right Certificate and any other agreement or document relating
            to the Buildings or the land under the Buildings)
          </p>

          <div className="textAlignCenter mb_24">
            <label htmlFor="document_img" className="Chooseuploadbox">
              <img src={ClouduploadIcon} className="mb_12" />
              <input
                id="document_img"
                name="image"
                ref={documentRef}
                type="file"
                onChange={(e) => handleimage(e)}
                accept=".doc,.docx,application/pdf"
                hidden
              />
              <h2>Drop files here or click to upload.</h2>
            </label>
          </div>

          {files ? (
            <div>
              <div class="fs22px pb20">Uploaded documents</div>
              <div class="whiteBox displayFlex AlignItemCenter mb20px">
                <div class="displayFlex AlignItemCenter w30per">
                  <div class="greenfolder"></div>
                  <div>{files?.file?.name?.split(".")[0]}</div>
                </div>
                <div class="w15per">
                  <button class="fileextension">
                    {files?.file?.name?.split(".")[1].toUpperCase()}
                  </button>
                </div>
                <div class="w15per">
                  {(files?.file?.size / 1024).toFixed(2)} KB
                </div>
                <div class="w15per">{todayDate()}</div>
                <div>{files?.documentTypes}</div>
                <div class="displayFlex w10per flexEnd">
                  <div
                    class="delete_icon cursorpointer"
                    onClick={() => {
                      files.element.current.value = "";
                      setFiles();
                    }}
                  ></div>
                </div>
              </div>

              <div class="textAlignCenter">
                <button
                  type="button"
                  className="blueborder_btn  bg_borderred"
                  onClick={() => uploadFiles()}
                >
                  Upload
                </button>
              </div>
            </div>
          ) : null}

          {data?.files?.length > 0 ? (
            <div class="p10px">
              <div class="frameshadow mb20px">
                <div>
                  <div class="mainTitle">Proof Of Ownership Documents</div>
                </div>
                <div class="installerTablewrapper">
                  <table>
                    <tr>
                      <th style={{ width: "50px" }}>#</th>
                      <th>Documents</th>
                      <th>PDF,JPG,JPEG,PNG</th>
                      <th>Verify</th>
                    </tr>
                    <tbody>
                      {data.files.map((file, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{file.originalname}</td>
                          <td /* (click)="view(d.filename)" */>
                            <a class="cursorpointer">View Document</a>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="blueborder_btn  bg_borderred"
                              disabled={file.verify_flag === "Y"}
                              /* (click)="Verify_files(d.id, i)"*/
                            >
                              Verify
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="p-3 mt-3 text-center">
                <button
                  type="button"
                  className="blueborder_btn  bg_borderred"
                  /* (click)="Nextstep()" */
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
}

export default FileUpload;
