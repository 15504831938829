import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserInformation from "../Tabs/UserInformation";
import CounterOffer from "../Tabs/CounterOffer";
import Employment from "../Tabs/Employment";
import CreditReport from "../Tabs/CreditReport";
import UploadDoc from "../Tabs/UploadDoc";
import PaymentSchedule from "../Tabs/PaymentSchedule";
import BankAccount from "../Tabs/BankAccount";
import Comments from "../Tabs/Comments";
import Logs from "../Tabs/Logs";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import CustomModal from "../../Components/Modal/Modal";
import dealIcon2 from "../../assets/images/closebutton.svg";
import pauseicon from "../../assets/images/pause-icon.svg";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import { PageTabs } from "../../data_store";
import ViewMore from "../Tabs/ViewMore";
import DealInfo from "../Tabs/DealInfo";
import Verified from "../Tabs/Verified";
import Milestone3 from "../Tabs/Milestone3";
import Milestone2 from "../Tabs/Milestone2";
import Milestone1 from "../Tabs/Milestone1";
import ProjectCompleted from "../Tabs/ProjectCompleted";

const Loandetails = () => {
  const navigate = useNavigate();
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7);
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const loanStatus = loanParams.split("/")[0];
  const tabs = JSON.parse(sessionStorage.getItem("tabs"));
  const [activeTab, setActiveTab] = useState(tabs[loanStatus][0].id);
  const loan_id = sessionStorage.getItem("loan_id");
  const [swaped,setSwaped]=useState()
  const [userData, setUserData] = useState([]);
  console.log('userData::: ', userData);
  const [creditreport, setCreditReport] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    getUserDetails();
    getCreditReport();
    getData();
  }, []);



  const getData = async () => {
    try {
      let res = await AuthGet(`funded-contracts/${loan_id}`, "admin");

      if (res.statusCode == 200) {
        setData(res.data);
        // if(res.data.from_details.length > 0){
        //   setUserId(res.data.from_details[0].user_id);
        // }

      }
    } catch (error) { }
  };


  const renderTab = (tab) => {
    switch (tab) {
      case 'contact':
        return <UserInformation userData={userData} />

      case 'credit_check':
        return <CreditReport userData={userData} creditData={creditreport} />

      case 'bank_lookup':
        return <BankAccount userData={userData} />

      case 'work':
        return <Employment />

      case 'repayment_calender':
        return <PaymentSchedule
          userData={userData}
          getUserDetails={getUserDetails}
        />

      case 'doc_vault':
        return <UploadDoc />

      case 'deal_info':
        return <DealInfo />

      case 'verfied_system_info':
        return <Verified data={data} />

      case 'milestone_one':
        return <Milestone1 />

      case 'milestone_two':
        return <Milestone2 />

      case 'milestone_three':
        return <Milestone3 />

      case 'modify_deal':
        return <CounterOffer />

      case 'ask_customer':
        return <Comments />

      case 'trace_actions':
        return <Logs />

      case 'view_more':
        return <ViewMore />

      case 'project_completed':
        return <ProjectCompleted />
    }
  }

  const getCreditReport = async () => {
    await AuthGet(`loan/credit-report/${loanId}`, "admin")
      .then((res) => {
        let data = JSON.parse(res[0].report);
        if (data.status === 200) {
          setCreditReport(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getUserDetails = async () => {
    console.log("Loan details Loan Id: ", loan_id);
    let loanStage = `${loanParams.charAt(0).toLowerCase() + loanParams.slice(1)
      } `;
    loanStage = loanStage.split("/")[0];
    console.log("Loan details Loan Status: ", loanStage);
    await AuthGet(`loanstage/${loan_id}/${loanStage}`, "admin")
      .then((res) => {
        console.log("Loan details data: ", res);
        if (res["statusCode"] == 200) {
          let procedureData = {
            procedure_startdate: res.data.from_details[0].procedure_startdate,
            payment_duedate: res.data.from_details[0].payment_duedate,
            loanamount: res.data.from_details[0].loanamount,
          };
          console.log("Session procedure data: ", procedureData);
          sessionStorage.setItem(
            "procedure_date",
            JSON.stringify(procedureData)
          );
          setUserData({
            data: res.data.from_details[0],
            coborrower: res.data.CoApplicant[0],
            stage: res.data.stage[0].status_flag,
            paymentScheduleDetails: res.data.paymentScheduleDetails,
            transactionInformation: res.data.transactionInformation,
            manualBank: res.data.manualBankAccount,
          });
          setSwaped(res.data.from_details[0].is_swapped)
        } else if (res["statusCode"] == 500) {
          toast.error(res["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoanStageGet = async (URL, navigateTo) => {
    console.log("Loan Id: ", loan_id);
    await AuthGet(`${URL}/${loan_id}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          navigate(`/admin/${navigateTo}`);
          toast.success(`Application successfully moved to ${navigateTo}`);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: `Application successfully moved to ${navigateTo}`,
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const handleLoanStagePut = async (URL, navigateTo) => {
    console.log("Loan Id: ", loan_id);
    await AuthPost(`${URL}/${loan_id}`, {}, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          navigate(`/admin/${navigateTo}`);
          toast.success(`Application successfully moved to ${navigateTo}`);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: `Application successfully moved to ${navigateTo}`,
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log("Put error: ", err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const handleSendOffers = async () => {
    await AuthPost(`loan/send-acceptance/${loan_id}`, {}, "admin")
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success("Offers sent successfully");
        }else{
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log("Put error: ", err);
        toast.error(err.message);
      })
  }

  return (
    <div>
      <Toast />
      {/* <section>
        <ol className="breadcrumb">
          <li onClick={() => navigate("/admin/dashboard")}>
            <a>Dashboard</a>
          </li>
          <li className="active">Application Details</li>
          <li className="active">View Application Details</li>
        </ol>
      </section> */}

      <div className="breadcrumbsection">
        <div>
          {' '}
          <div className="titleText ">Loan Information</div>
          {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  / {loanStatus === "Waiting " ? "Incomplete " :
                        loanStatus === "Fundingcontract " ? "Funding Contract " : loanStatus}
                    Application Details */}
        </div>
        <div>
          <div>
            {loanStatus === 'waiting' ? (
              <div className="displayFlex mr30px">
                {tabs[loanStatus].map((tab) => {
                  if (tab.name === 'deny') {
                    return (
                      <button
                        className="btn rejectButton buttontooltip"
                        onClick={() =>
                          handleLoanStagePut('loanstage/deny-loan', 'denied')
                        }
                      >
                        <img src={dealIcon2} />
                        <div className="top">
                          <p>Deny</p>
                          <i></i>
                        </div>
                      </button>
                    );
                  } else if (tab.name === 'archive') {
                    return (<button
                      className="btn pauseButton buttontooltip"
                      onClick={() =>
                        handleLoanStagePut('loanstage/archive-loan', 'archived')
                      }
                    >
                      <img src={pauseicon} />

                      <div className="top">
                        <p>Archive</p>
                        <i></i>
                      </div>
                    </button>)
                  } else return null
                })}
              </div>
            ) : loanStatus === 'pending' ? (
              <div className="displayFlex mr30px gap12">
                {/* <button
                  type="button"
                  className="btn btn-primary mb_15 mr_14"
                >Select Borrower</button> */}

{ userData?.data?.coborrower_lastscreen === 'onboarding Completed' 
                &&  userData?.data?.lastscreen === 'onboarding Completed' &&
                !swaped ? (
                  <div className="opp_btn">
                    <CustomModal
                      btnName={"Select a Borrower"}
                      data={userData}
                      getUserDetails= {getUserDetails}
                    />
                  </div>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn btn-primary mb_15 mr_14 ml_14"
                  onClick={handleSendOffers}
                >Send Offers
                </button>
                {tabs[loanStatus].map((tab) => {
                  if (tab.name === 'approve') {
                    // return <CustomModal btnName={'Approve'} />;
                  } else if (tab.name === 'archive') {
                    return (<button
                      className="btn pauseButton buttontooltip"
                      onClick={() =>
                        handleLoanStagePut('loanstage/archive-loan', 'archived')
                      }
                    >
                      <img src={pauseicon} />

                      <div className="top">
                        <p>Archive</p>
                        <i></i>
                      </div>
                    </button>)
                  } else if (tab.name === 'deny') {
                    return (<button
                      className="btn rejectButton buttontooltip"
                      onClick={() =>
                        handleLoanStagePut('loanstage/deny-loan', 'denied')
                      }
                    >
                      <img src={dealIcon2} />
                      <div className="top">
                        <p>Deny</p>
                        <i></i>
                      </div>
                    </button>)
                  } else return null
                })}
              </div>
            ) : loanStatus === 'approved' ? (
              <div className="displayFlex mr30px">
                {tabs[loanStatus].map((tab) => {
                  // if (tab.name === 'update_procedure_date') {
                  //   return <CustomModal btnName={'Update Procedure Date'} />;
                  // } 
                  // if (tab.name === 'opportunities') {
                  //   return (<button
                  //     className="btn pendingicon buttontooltip"
                  //     onClick={() =>
                  //       handleLoanStageGet('dismissed/awaiting', 'pending')
                  //     }
                  //   >
                  //     <img src={pendingicon} />

                  //     <div className="top">
                  //       <p>Opportunities</p>
                  //       <i></i>
                  //     </div>
                  //   </button>)
                  // } 
                  if (tab.name === 'archive') {
                    return (<button
                      className="btn pauseButton  buttontooltip"
                      onClick={() =>
                        handleLoanStagePut('loanstage/archive-loan', 'archived')
                      }
                    >
                      <img src={pauseicon} />

                      <div className="top">
                        <p>Archive</p>
                        <i></i>
                      </div>
                    </button>)
                  } else if (tab.name === 'confirm_procedure') {
                    return <CustomModal btnName={'Approve'} />;
                  } else return null
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className=" ">
        <Tabs
          transition={true}
          id="noanim-tab-example"
          className="mb-3 tabblock"
          activeKey={activeTab}
          unmountOnExit={true}
          mountOnEnter={true}
          onSelect={handleTabClick}
        >
          {Object.keys(PageTabs).map((tab, i) => (
            <Tab
              tabClassName={`${tabs[loanStatus].some((t) => t.name === tab)
                ? ''
                : 'disabled_tab'
                }`}
              disabled={!tabs[loanStatus].some((t) => t.name === tab)}
              title={PageTabs[tab]}
              eventKey={i + 1}
            >
              {tabs[loanStatus].map((t) => {
                if (tab === t.name) {
                  return renderTab(t.name);
                }
              })}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Loandetails;
