import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import Button from '../../../Components/Button/Button'
import { AuthPost } from '../../../common_var/httpService';
import { useParams } from 'react-router-dom';

function SystemInfoVerification() {
    const signRef = useRef(null);
    const { id: loan_id } = useParams();
    const installerId = sessionStorage.getItem('InstallerUserId')
    const [signErr, setSignErr] = useState(false)

    const formik = useFormik({
        initialValues: {
            moduleManufacturer: '',
            inverterManufacturer: '',
            batteryManufacturer: '',
            systemSize: '',
            estAnnualProduction: '',
            agree: false,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            moduleManufacturer: Yup.string().required(
                '*Manufacturer Of Modules is required'
            ),
            inverterManufacturer: Yup.string().required(
                '*Manufacturer Of Inverters is required'
            ),
            batteryManufacturer: Yup.string().required(
                '*Manufacturer Of Battery is required'
            ),
            systemSize: Yup.string().required('*System size is required'),
            estAnnualProduction: Yup.string().required(
                '*Estimated annual production is required'
            ),
            agree: Yup.boolean().oneOf([true], '*Please select the checkbox'),
        }),
        onSubmit: async (values) => {
            values.systemSize = parseInt(values.systemSize)
            values.estAnnualProduction = parseInt(values.estAnnualProduction)
            delete values.agree
            values.loan_id = loan_id
            values.user_id = installerId

            if (!signRef.current._sigPad._isEmpty) {
                values.signature = signRef.current.getTrimmedCanvas().toDataURL();
            } else {
                setSignErr(true)
                return
            }

            AuthPost('installer/systeminfo', values, 'admin').then((res) => {
                console.log(res)
                setTimeout(() => {
                    window.location.reload();
                }, 50);
            }).catch((err) => console.log(err))
        },
    });

    return (
      <div className="frameWhite mb20px">
        <div className="fs20px fontWeight600 pb20">Verify System Info</div>
        <form name="form" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="mb_24 col-md-4">
              <label className="labelText">Manufacturer of Modules </label>
              <select
                className="MainInput"
                aria-label="Default select example"
                name="moduleManufacturer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.moduleManufacturer}
              >
                <option defaultValue value={null}>
                  Select Manufacturer of Modules
                </option>
                <option value="REC">REC</option>
              </select>
              {formik.touched.moduleManufacturer &&
              formik.errors.moduleManufacturer ? (
                <p className="error_text text-danger">
                  {formik.errors.moduleManufacturer}
                </p>
              ) : null}
            </div>
            <div className="mb_24 col-md-4">
              <label className="labelText">Manufacturer of Inverters </label>
              <select
                className="MainInput"
                aria-label="Default select example"
                name="inverterManufacturer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.inverterManufacturer}
              >
                <option defaultValue value={null}>
                  Select Manufacturer of Inverters
                </option>
                <option value="Enphase">Enphase</option>
              </select>
              {formik.touched.inverterManufacturer &&
              formik.errors.inverterManufacturer ? (
                <p className="error_text text-danger">
                  {formik.errors.inverterManufacturer}
                </p>
              ) : null}
            </div>

            <div className="mb_24 col-md-4">
              <label className="labelText">Manufacturer of Battery </label>
              <select
                className="MainInput"
                aria-label="Default select example"
                name="batteryManufacturer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.batteryManufacturer}
              >
                <option defaultValue value={null}>
                  Select Manufacturer of Battery
                </option>
                <option value="No Battery">No Battery</option>
              </select>
              {formik.touched.batteryManufacturer &&
              formik.errors.batteryManufacturer ? (
                <p className="error_text text-danger">
                  {formik.errors.batteryManufacturer}
                </p>
              ) : null}
            </div>
            <div className="mb_24 col-md-4">
              <label className="labelText">System Size (kW) </label>
              <input
                type="text"
                name="systemSize"
                className="MainInput"
                placeholder="Enter system size"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.systemSize}
              />
              {formik.touched.systemSize && formik.errors.systemSize ? (
                <p className="error_text text-danger">
                  {formik.errors.systemSize}
                </p>
              ) : null}
            </div>
            <div className="mb_24 col-md-4">
              <label className="labelText">Estimated Annual Production</label>
              <input
                type="text"
                name="estAnnualProduction"
                className="MainInput"
                placeholder="Enter system size"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.estAnnualProduction}
              />
              {formik.touched.estAnnualProduction &&
              formik.errors.estAnnualProduction ? (
                <p className="error_text text-danger">
                  {formik.errors.estAnnualProduction}
                </p>
              ) : null}
            </div>
          </div>
          <div className="customCheckbox mb_12">
            <input
              name="agree"
              type="checkbox"
              id="flexCheckDefault"
              style={{ marginRight: "10px" }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.agree}
            />
            <label className="form-check-label" for="flexCheckDefault">
              Please check to confirm System Information is correct
            </label>
            
          </div>
          {formik.touched.agree && formik.errors.agree ? (
              <p className="error_text text-danger pb8 plr_16">{formik.errors.agree}</p>
            ) : null}

          <div className="w48per mb_24">
            <h2 className="heading18 fontWeight600 mb_24">Signature </h2>
            <div className=" mb_12 installer-signature">
              <SignatureCanvas
                ref={signRef}
                canvasProps={{ width: 500, height: 150 }}
              />
             
            </div>
            {signErr ? <p className="requiredText">Signature Is Pending<span>*</span></p> : null}
            <div className="mb_24 margin_auto">
              <Button
                type={"button"}
                value="Clear"
                handleClick={() => signRef.current.clear()}
              />
            </div>
          </div>

          <div className="margin_auto textAlignCenter">
            <button
              type="submit"
              className="blueborder_btn  bg_borderred"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    );
}

export default SystemInfoVerification;
