import { useFormik } from "formik";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AuthPut } from "../../../common_var/httpService";
import * as Yup from "yup";
// import { datePipe } from "../general";

const ProjectCompleted = ({data}) => {
  const navigate = useNavigate();
//   const installerId = sessionStorage.getItem("InstallerUserId");
  const { stage, id: loan_id } = useParams();

  const formik = useFormik({
    initialValues: {
      agree: false,
    },
    validationSchema: Yup.object({
      agree: Yup.boolean().oneOf([true], "Please select the Checkbox."),
    }),
    onSubmit: async (values) => {
      AuthPut("installer/approveApplication/" + loan_id, {}, "admin").then(
        (res) => {
          if (res.statusCode === 200) {
            setTimeout(() => { 
              navigate(`/admin/applications/${stage}/${loan_id}/i_info`);
            }, 50);
          }
        }
      );
    },
  });

  return (
    <div class="stepthree">
      <div class="mb50px">
        <div class="grayBox displayFlex AlignItemCenter mb20px">
          <div class="displayFlex AlignItemCenter w50per">
            <div class=" ">
              <label class="optionButton">
                System info Verified
                <input type="checkbox" checked="checked" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div></div>
          </div>

          <div class="w15per"></div>
          <div class="w15per"></div>
          <div class="w15per">
            {(data?.systemInfoVerified[0]?.createdat)}
          </div>
        </div>

        {data?.projectDocuments.map((docs) => (
          <div class="grayBox displayFlex AlignItemCenter mb20px">
            <div class="displayFlex AlignItemCenter w50per">
              <div class="greenfolder"></div>
              <div>{docs.originalname.split(".")[0]}</div>
            </div>

            <div class="w15per">
              <button class="fileextension whiteBg">
                {docs.originalname.split(".")[1].toUpperCase()}
              </button>
            </div>
            <div class="w15per"></div>
            <div class="w15per">{(docs.createdat)}</div>
          </div>
        ))}
      </div>
      <div class="grayBox p20 mb20px w50per m0auto">
        <div class="displayFlex AlignItemCenter justifycontencenter flexDirection">
          <div class="tickmark"></div>
          <div class="textAlignCenter pb20">
            System Info is verified <br />
            Documents are uploaded
          </div>

          <div class="fs20px textAlignCenter">
            Please, approve the application
          </div>

          <form id="agreeForm" name="agreeForm" onSubmit={formik.handleSubmit}>
            <div class="pb20">
              <input
                class=" "
                name="agree"
                type="checkbox"
                id="flexCheckDefault"
                style={{ marginLeft: "10px" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.agree}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Please check to confirm System Information is correct
              </label>
              {formik.touched.agree && formik.errors.agree ? (
                <p className="error_text">{formik.errors.agree}</p>
              ) : null}
            </div>

            <div class="pb20 displayFlex AlignItemCenter spaceBetween">
              <div>
                <button type="submit" class="greenbutton width100per upperCase">
                  APPROVE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectCompleted;
