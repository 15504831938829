import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
// import { useStateValue } from "../../Context/StateProvider";
import CusModal from '../../Components/modal copy/modal'
import CommonToggle from '../../Templates/toggle/toggle'
import InfoIcon from "../../assets/images/info_icon.svg";
import ActiveBankIcon from "../../assets/images/active_bank.svg";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
// import CustomModal from "../../Components/Modal/Modal";
;

const AddPayment = () => {

  // const navigate = useNavigate();
  const [bank, setBank] = useState([])
  const [facvalue, setFacvalue] = useState(false)




  var user_id = sessionStorage.getItem('UserId')
  let loan_id = sessionStorage.getItem('LoanId')

  let addbank = "Add bank"
  let changeAcc = "change"
  let addcerdit = "Add Debit Card"
  let addbankurl = { url: `payment/addBank`, key: "borrower" }
  let getpaymethod = { url: `payment/list/${user_id}/${loan_id}`, key: "borrower" }
  let changeactive = { url: `payment/setPrimary/${user_id}`, key: "borrower" }
  let changecardactive = { url: `payment-method/cardchoose/${user_id}`, key: "borrower" }
  let addcrediturl = { url: `payment-method/debitcardadd`, key: "borrower" }
  const paramsdata = { url: `payment/enableAutoPayment/${user_id}`, key: "borrower" };


  const getpayment = async () => {

    await AuthGet(getpaymethod.url, "borrower").then((res) => {
      console.log("get pay---->", res)
      if (res.data.statusCode == 403 || res.data.statusCode == 401) {
        toast.error(res.data.message);

      }
      let data = res.data.bankDetails;
      if(data.length==1){
        if(data[0].active_flag=='N'){
          changeBankAcct(data[0].id)
        }
        }
     
      data.map((ele) => {
        ele.maskedAccountNumber = showAccountNumber(ele.accountnumber)
      });
      console.log(data);
      setBank(data)
      // setCard(res.data.cardDetails)
      if (res.data.user_details?.autopayment == 'N') {
        setFacvalue(false)
      } else {
        setFacvalue(true)
      }

    }).catch((err) => {
      console.log("get pay ------>", err)
    })

  }

  const showAccountNumber = (number) => {
    let result = '';
    for (let i = 0; i < number?.length - 4; i++) {
      result = result + "X"
    }
    console.log(result + number?.substring(number?.length - 4))
    return result + number?.substring(number?.length - 4)

  }

  const changeBankAcct = async (e) => {
    console.log("Bank.url->>>>>", changeactive.url)
    if (changeactive.url === `payment/setPrimary/${user_id}`) {
      let data = {
        bank_id: e,
        loan_id
      }
      console.log(data)
      await AuthPost(changeactive.url, data, changeactive.key).then((res) => {
        console.log("bank update", res)
        if (res.statusCode === 200) {
          toast.success(res.message[0]);
          getpayment()
        }
      }).catch((err) => {
        console.log("err--->", err)
      })
    }
  }

  // const changeCard = async (e) => {
  //   console.log("Card.url->>>>>", changecardactive.url, e)
  // if (changecardactive.url === `payment-method/cardchoose/${user_id}`) {
  //   let data = {
  //     card_id: e
  //   }
  //   console.log(data)
  //   await AuthPut(changecardactive.url,data,changecardactive.key).then((res)=>{
  //     console.log("add bank",res)
  //    if(res.statusCode === 200){
  //     dispatch({
  //       type: "TOAST",
  //       toastMessage: "Bank changed successfully",
  //       toastType: "success",
  //     });
  //     getpayment();
  //    }

  //   }).catch((err) => {
  //     console.log("err--->", err)
  //   })
  // }
  // }

  // const activeCard = card.filter((e) => e.active_flag == "Y")

  // const makePayment = () => {
  //   alert('make payment')
  // }

  useEffect(() => {

    getpayment()

  }, [])


  return (
    <>
      <Toast />


      <div className="row mb_24">
        {/* <div className="col-md-6">
                <div className="CardFrame">
                  <div className="cardHeader">
                    <div>
                      <h5>{ activeCard[0]?.fullName }</h5>
                    </div>
                    <div>
                      <CusModal
                        btname={"Add +"}
                        Paramsdata={addcrediturl}
                        fun={getpayment}
                      />
                    </div>
                  </div>
                  { activeCard != null && activeCard.length > 0 ? (
                  <div className="cardbody">
                    <h2>xxxx xxxx xxxx { activeCard[0]?.cardNumber.substring( activeCard[0]?.cardNumber.length - 4 )}</h2>
                    <p>Valid Thru { activeCard[0]?.expires }</p>
                  </div>
                  ) : ( 
                  <div className="cardbody">
                    <h4 style={{ color: "white"}}>Add Card Added</h4>
                  </div>  
                  )
                  }
                  <div className="cardFooter">
                    <h5 className="displayFlex alignItemscenter">
                      <img src={InfoIcon} />
                      <span>Change card</span>
                    </h5>

                    <div>
                      <h5>
                        <i>Primary Card </i>
                      </h5>
                    </div>
                  </div>
                </div>
              </div> */}
        <div className="col-md-12">

          <div className="whiteFrame p16 hidden-scroller mb20px">

            <CusModal
              btname={addbank}
              Paramsdata={addbankurl}
              fun={getpayment}
            />

            <div className="infotable_wrapper">
              <table>
                <tbody>
                  {bank != null && bank.length > 0 ? (
                    bank.map((e, i) => {
                      return <tr key={i}>
                        <th className="fontWight500 washedBlack_textClr">
                          {e.maskedAccountNumber + "-" + e.bankname}
                        </th>
                        {e.active_flag == "N" ?
                          <td style={{ cursor: "pointer" }} onClick={() => changeBankAcct(e.id)}>Set as primary</td> :
                          <td className="">
                            <div className="displayFlex alignItemscenter gap6 justifyConentEnd">
                              <img src={ActiveBankIcon} />
                              <span className="spandexGreen_textClr">Active</span>
                            </div>
                          </td>
                        }
                      </tr>
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <h5 className="nodataText">
                          No Bank Account Added
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>



        </div>
        <div className="col-md-12">

          <CommonToggle
            title="Automatic Payment"
            Paramsdata={paramsdata}
            fun={getpayment}
            data={facvalue}
            id={user_id}

          />

        </div>
      </div>

      {/* TO MAINTAIN DESIGN ITS BEEN NOT DISPLAYED */}
      {/* <div className="whiteFrame mb_24">
              <div className="p16">
                {bank != undefined && bank.length > 0 && (
                  <div className="greenColor cursorpointer">
                    <CusModal
                      btname={changeAcc}
                      Paramsdata={changeactive}
                      data={bank}
                      fun={getpayment}
                    />
                  </div>
                )}

                {bank != undefined && bank.length > 0 ? (
                  bank.map((e, i) => {
                    return (
                      <>
                        <div key={e.id}>
                          <div className="displayFlex spaceBetween pb20">
                            <div>
                              {e.bankName} (******
                              {e.accountNumber.substring(
                                e.accountNumber.length - 4
                              )}
                              )
                            </div>
                          </div>
                          {e.active_flag == "Y" && (
                            <div className="fontWeight pb20 activeTxtColor">
                              Active
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <h5 className="nodataText">No Bank has Been Linked </h5>
                  </>
                )}
              </div>
            </div> */}

      {/* <div className="whiteFrame mb_24">
              <CusModal
                btname={addcerdit}
                Paramsdata={addcrediturl}
                fun={getpayment}
              />
              <div className="p16">
           
                {card != undefined && card.length > 0 && (
                  <div className="greenColor cursorpointer">
                   
                  </div>
                )}
    
                {card != undefined && card.length > 0 ? (
                  card.map((e, i) => {
                    return (
                      <>
                        <tr>
                        <th className="fontWight500 washedBlack_textClr">
                          <div className="cardbody">
                            <h5>xxxx xxxx xxxx { e.cardNumber.substring( e.cardNumber.length - 4 )}</h5>
                          </div>
                        </th>
                        <th className="fontWight500 washedBlack_textClr">
                            <p> ( Valid Thru { e.expires } )</p>
                        </th>
                        { e.active_flag == "N" ?
                        <td style={{ cursor: "pointer", paddingLeft: "350px" }} onClick={()=> changeCard(e.id)}>Set as primary</td> :
                        <td className="">
                          <div className="displayFlex alignItemscenter gap6 justifyConentEnd">
                            <img src={ActiveBankIcon} />
                            <span className="spandexGreen_textClr">Active</span>
                          </div>
                        </td>
                        }
                        </tr>
                    
                      </>
                    );
                  })
                ) : (
                  <>
                    <h5 className="nodataText">No Card has Been Linked </h5>
                  </>
                )}
                <div className="infoBlock mb20px">
                  <div className="info_icon"></div>
                  <div>
                    You can use your debit card in order to make single payments
                    outside of your scheduled payments
                  </div>
                </div>
              </div>
            </div> */}

    </>
  );

}

export default AddPayment