import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthPost,AuthDownload,AuthGet } from "../../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const FilesUpload = ({data}) => {
  const [userConsent, setUserConsent] = useState([]);
  const [files, setfiles] = useState([]);
  const navigate = useNavigate();
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7).split("/")[0];
  const loanStatus =
    loanParams.charAt(0).toUpperCase() + loanParams.slice(1).split("/")[0];
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      if (Object.keys(data).length) {
       console.log("dataaa",data)
       setfiles(data.ownershipFiles)
      }
    }, [data]);

    const viewDoc = (filename) => {
      console.log(filename);
      // console.log("Admin key: ", key);
      if (filename) {
          filename = filename?.split("/");
          filename = filename[filename.length - 1];
          filename = `${filename}`;
  
          window.open(process.env.REACT_APP_ADMIN_API_URL + "/files/save/" + filename, "_blank");
      }
    };
  
    const columns = [
      {
        dataField: "",
        text: "#",
        sort: true,
        formatter: (e, column, columnIndex, row, rowIndex) => {
          let total = currentPage * 5;
          total = total - 5;
          let slNo = total + columnIndex + 1;
          console.log("Total: ", total);
          return slNo;
        },

        // class="cursorpointer"
      },
      { dataField: "originalname", text: "Documents" },
      { dataField: "documentType", text: "Document Type" },
      { dataField: "filename", text: "PDF,JPG,JPEG,PNG", formatter: (cell) => {
        return "View ";
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          viewDoc(row.filename);
        },
      }, },
    ];    

  return (
    <div>
        <div className="mainTitle">Proof Of Ownership Documents</div>
        <div className="p16">
        <div className="installerTablewrapper p16">
          <BootstrapTable keyField="id" data={files} columns={columns} />
        </div>
</div>

        {/* new file upload */}
    </div>
  );
}

export default FilesUpload