import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import Toast from "../Toaster/Toast";

const Guard = ({ data }) => {
  let key = data.key;
  let pages = JSON.parse(sessionStorage.getItem("pages"));
  const [previousRoute, setPreviousRoute] = useState(
    key === "borrower" ? null : `${data?.key}/${pages[0]?.name}`
  );
  const queryParams = useLocation();
  let pathUrl = queryParams.pathname.split("/");
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  }
  const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;

  const checkRoles = () => {
    let IsRoleAvailable = pages?.some((page) => pathUrl?.includes(page?.name));
    return IsRoleAvailable;
  };

  useEffect(() => {
    if (checkRoles()) {
      setPreviousRoute(queryParams?.pathname);
    } else {
      <Navigate to={previousRoute} />;
    }
  }, [queryParams]);
  return (
    <>
      <Toast />
      <div>
        {expiry > Math.floor(new Date().getTime() / 1000) &&
        key == "borrower" ? (
          <Outlet />
        ) : expiry > Math.floor(new Date().getTime() / 1000) &&
          checkRoles() &&
          key == "admin" ? (
          <Outlet />
        ) : expiry > Math.floor(new Date().getTime() / 1000) &&
          !checkRoles() &&
          key == "admin" ? (
          <Navigate to={previousRoute} />
        ) : key == "admin" ? (
          (toast("Session Expired", {
            icon: "⌛",
          }),
          (<Navigate to="/admin/login" />))
        ) : // clear(key)
        key == "borrower" ? (
          (toast("Session Expired", {
            icon: "⌛",
          }),
          (<Navigate to="/borrower/login" />))
        ) : (
          // clear(key)
          ""
        )}
      </div>
    </>
  );
};

export default Guard;
