import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";

export const RedirectAcceptance = () => {
  const navigate = useNavigate();
  const { loanid } = useParams();

  useEffect(() => {
    checkLinkValidation();
    console.log("Check success!!");
  }, []);

  const checkLinkValidation = async () => {
    try {
      const res = await AuthGet(`loan/verify-loan-status/${loanid}`, "admin");
      console.log("Res::", res);
      if (res.statusCode === 200) {
        sessionStorage.setItem("acceptance_form", true);
        sessionStorage.setItem("acceptance_loan_id", loanid);
      }
      navigate("/borrower/login");
    } catch (err) {}
  };
};
